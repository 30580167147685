import React from 'react';

import EditComponent from 'components/Forms/Edit';
import Container from 'components/Forms/Container';

import Form from './Form';

export default class Edit extends EditComponent {

  static key = 'playlist';
  static url = '/playlists';

  constructor(props) {
    super(props);

    this.redirect = this.redirect.bind(this);
    this.load = this.load.bind(this);
  }

  redirect() {
    this.props.history.push('/films/' + this.state.playlist.film_id + '/edit');
  }

  render() {
    let filmId = this.state.playlist.film_id;
    if(filmId && filmId != this.props.filmId) {
      alert('Playlist not found');
      return ;
    }

    return (
      <Container title={
        'Edit Asset #' + this.props.id + ' ' + (
          this.state.playlist.playlist_title ?
            this.state.playlist.playlist_title :
            ''
        )
      }>
        <Form
          {...this.props}
          {...this.state}
          save={this.save}
          load={this.load}
        />
      </Container>
    );
  }

}
