import moment from 'moment/moment';
import React from 'react';

import Films from 'services/Films';

export default class ListItem extends React.Component {

  render() {
    let film = this.props.film;
    let filmImage = Films.getImage(film);

    return (
      <div key={film.id + '-' + film.locale} className="movielist-item">
        <a
          href={(this.props.url || '/films') + '/' + film.id}
          className="card movie-card films__link"
          title={Films.getFullTitle(film)}
          data-state={JSON.stringify({film: film})}
        >
          <div className="movielist-poster movielist-poster-empty">
            <img
              className="card-img-top img-fluid coverImage"
              src="/static/images/cover.png"
            />

            {this.getImageElement(film, filmImage)}

            {!filmImage && (
              <div className="movielist-poster-content">
                {film.organization && film.organization.logo.url &&
                  <div className="movielist-poster-logo">
                    <img
                      src={film.organization.logo.url}
                      alt={film.organization.name}
                    />
                  </div>
                }

                <div className="movielist-poster-title">
                  {Films.getLocalTitle(film)}
                </div>
              </div>
            )}
          </div>

          <div className="card-block">
            {film.organization &&
              <div
                className={
                  'card-text ' +
                    (film.playlists_count && 'card-text-playlists')
                }
              >
                {film.organization.name}
              </div>
            }

            <div
              className={
                'card-text card-text-sm ' +
                  (film.playlists_count && 'card-text-sm-playlists')
              }
            >
              {this.props.transfersCount}
              {film.trailer_downloads !== undefined &&
                film.playlists_count !== undefined &&
                '/'}
              {film.playlists_count}
            </div>

            {film.mastering_date && (
              <div className="card-text card-text--updated">
                Updated
                {' '}
                {moment(film.mastering_date).format('D.M.YYYY')}
              </div>
            )}
          </div>
        </a>
      </div>
    );
  }

  getImageElement(film, filmImage) {
    let element = (
      <img
        className="card-img-top img-fluid coverImage films__image"
        src={filmImage || '/static/images/cover.png'}
        alt={Films.getLocalTitle(film)}
      />
    );

    return element;
  }

}
