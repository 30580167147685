import React from 'react';
import Waypoint from 'react-waypoint';

import Form from 'services/Form';
import Query from 'services/Query';

import Container from 'components/Forms/List';
import Organization from 'components/Disks/Organization';
import SortLink from 'components/Forms/SortLink';

export default class List extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      page: 0,
      query: Query.get('query'),
      order: Query.get('order'),
      direction: Query.get('direction'),
      organization_id: Query.get('organization_id'),
      total: 0,
    };

    this.loading = false;
    this.form = new Form(this);
    this.more = this.more.bind(this);
    this.search = this.search.bind(this);
    this.setQuery = this.setQuery.bind(this);
    this.sort = this.sort.bind(this);

    this.showDisks = this.showDisks.bind(this);
    this.hideDisks = this.hideDisks.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  load(append = false) {
    if(this.loading) {
      return ;
    }

    this.loading = true;
    Query.set('query', this.state.query);
    Query.set('order', this.state.order);
    Query.set('direction', this.state.direction);

    let previousOrganizations = this.state.organizations;

    this
      .form
      .api
      .get('/organizations', {
        page: this.state.page,
        query: this.state.query,
        order: this.state.order || 'name',
        direction: this.state.direction || 'asc',
      })
      .then((response) => {
        this.loading = false;

        let organizations = response.data.organizations;
        if(append) {
          organizations = previousOrganizations.concat(organizations);
        }

        this.setState({
          ...response.data,
          organizations: organizations,
        });
      });
  }

  more() {
    if(this.state.page >= this.state.total - 1) {
      return ;
    }

    this.setState({page: this.state.page + 1});
    this.load(true);
  }

  search(event) {
    event.preventDefault();
    this.setState({page: 0}, () => {
      this.load();
    });
  }

  setQuery(event) {
    this.setState({query: event.target.value});
  }

  delete(organizationId) {
    if(!(this.props.confirm || confirm)('Are you sure?')) {
      return ;
    }

    this
      .props
      .api
      .delete('/organizations/' + organizationId)
      .then(() => {
        this.setState({
          organizations: this.state.organizations.filter((organization) => {
            return organization.id != organizationId;
          }),
        });
      });
  }

  showDisks(event) {
    Query.set('organization_id', event.target.dataset.id);
    this.setState({organization_id: event.target.dataset.id});
  }

  hideDisks() {
    Query.set('organization_id', null);
    this.setState({organization_id: null, organization: null});
  }

  sort(order, direction) {
    this.setState({order: order, direction: direction}, this.load);
  }

  render() {
    return (
      <Container>
        {this.renderHeader()}
        <table className="table">
          <thead>
            <tr>
              <th>
                <SortLink
                  order="logo"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >Logo</SortLink>
              </th>

              <th>
                <SortLink
                  order="name"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >Company</SortLink>
              </th>

              <th>
                <SortLink
                  order="eden_site_id"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >OFE Site ID</SortLink>
              </th>

              <th>
                <SortLink
                  order="deluxe_site_id"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >Deluxe Site ID</SortLink>
              </th>

              <th>
                <SortLink
                  order="users"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >Users</SortLink>
              </th>

              <th>
                <SortLink
                  order="screens"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >Screens</SortLink>
              </th>

              <th>
                <SortLink
                  order="transfers"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >Transfers</SortLink>
              </th>

              <th>
                <SortLink
                  order="disks"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >Disks</SortLink>
              </th>

              <th>
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            {this.renderOrganizations(this.state.organizations)}

            <tr>
              <td>
                <Waypoint
                  scrollableAncestor={
                    document.getElementsByClassName('content')[0]
                  }
                  onEnter={this.more}
                />
              </td>
            </tr>
          </tbody>
        </table>

        <Organization
          api={this.props.api}
          organization_id={this.state.organization_id}
          onHide={this.hideDisks}
        />
      </Container>
    );
  }

  renderHeader() {
    return (
      <div className="row">
        <div className="col-sm-6">
          <h1>
            Organizations
            &nbsp;
            <a
              href="/organizations/create"
              className="btn btn-primary pull-right"
            >
              Create
            </a>
          </h1>
        </div>

        <div className="col-sm-6">
          <form onSubmit={this.search}>
            <div className="input-group">
              <input
                type="search"
                className="form-control organizations__query"
                value={this.state.query || ''}
                onChange={this.setQuery}
                placeholder="Search..."
              />

              <span className="input-group-btn">
                <button type="submit" className="btn btn-primary">
                  <span className="fa fa-search"></span>
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    );
  }

  renderOrganizations(organizations) {
    return organizations.map((organization) => {
      return (
        <tr key={organization.id} className="organizations__item">
          <td className="organizations__logo">
            <a
              href={'/organizations/' + organization.id}
              data-state={JSON.stringify({organization: organization})}
            >
              {
                organization.logo &&
                  organization.logo.url &&
                  <div className="organizations__logo-container">
                    <img
                      className="organizations__logo-image"
                      src={organization.logo.url}
                    />
                  </div>
              }
            </a>
          </td>

          <td className="organizations__name">
            <strong>
              <a
                href={
                  '/organizations/' +
                    organization.id +
                    '?redirect=' +
                    Query.getEncodedUrl()
                }
                data-state={JSON.stringify({organization: organization})}
              >
                {organization.name}
              </a>
            </strong>
            <div className="organizations__address">
              {organization.address}
            </div>
          </td>

          <td className="organizations__eden-site-id">
            {organization.eden_site_id}
          </td>

          <td className="organizations__deluxe-site-id">
            {organization.deluxe_site_id}
          </td>

          <td className="organizations__users">
            {this.renderUsers(organization)}
          </td>

          <td className="organizations__screens-count">
            <a href={'/organizations/' + organization.id + '/screens'}>
              {organization.screens_count}
            </a>
          </td>

          <td className="organizations__transfers-count">
            <a href={'/transfers#?organization_id=' + organization.id}>
              {organization.transfers_count}
            </a>
          </td>

          <td className="organizations__transfers-count">
            <a
              href="#"
              title="Disks"
              onClick={this.showDisks}
              data-id={organization.id}
            >
              {organization.disks_count}
            </a>
          </td>

          <td className="organizations__actions">
            <a
              href={'/organizations/' + organization.id + '/targets'}
              className="organizations__targets btn btn-sm btn-primary"
              title="Targets"
            >
              <span className="fa fa-link"></span>
            </a>
            &nbsp;
            <button
              className="organizations__delete btn btn-sm btn-danger"
              title="Delete organization"
              onClick={() => this.delete(organization.id)}
            >
              <span className="fa fa-trash"></span>
            </button>
          </td>
        </tr>
      );
    });
  }

  renderUsers(organization) {
    if(!organization.users) {
      return ;
    }

    var usersLastIndex = organization.users.length - 1;
    return organization.users.map((user, index) => {
      return (
        <span
          key={user.id}
          className="organizations__user-item"
        >
          <a
            href={
              '/users/' +
                user.id +
                '?redirect=' +
                Query.getEncodedUrl()
            }
            className="organizations__user-email"
          >{user.email}</a>{
            index != usersLastIndex && ', '
          }
        </span>
      );
    });
  }

}
