import React from 'react';

import EditComponent from 'components/Forms/Edit';

import Query from 'services/Query';

import Form from './Form';

export default class Edit extends EditComponent {

  static key = 'screen';
  static url = '/screens';

  constructor(props) {
    super(props);
    this.redirect =
      Query
        .getRedirectCallback(
          '/organizations/' + props.organizationId + '/screens',
        )
        .bind(this);
  }

  renderBody() {
    return (
      <Form
        {...this.state}
        save={this.save}
      />
    );
  }

}
