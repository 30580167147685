import React from 'react';

import CreateComponent from 'components/Forms/Create';

import Form from './Form';

export default class Create extends CreateComponent {

  static key = 'archive_disk';
  static url = '/archive_disks';

  renderBody() {
    return (
      <Form
        {...this.state}
        save={this.save}
      />
    );
  }
}
