import React from 'react';
import Modal from 'react-modal';
import moment from 'moment/moment';

import Form from 'services/Form';
import ModalService from 'services/Modal';

export default class Locations extends React.Component {

  constructor(props) {
    super(props);

    this.state = {diskId: props.diskId};
    this.form = new Form(this);
    this.hide = this.hide.bind(this);
  }

  componentWillReceiveProps(props) {
    if(this.state.diskId == props.diskId) {
      return ;
    }

    this.setState({diskId: props.diskId}, () => {
      this.load()
    });
  }

  componentDidMount() {
    this.load();
  }

  load() {
    if(!this.state.diskId) {
      return ;
    }

    this
      .props
      .api
      .get('/disks/' + this.state.diskId + '/locations')
      .then((response) => {
        this.setState({
          ...response.data,
          ready: true,
        });
      });
  }

  hide() {
    if(this.props.onHide) {
      this.setState({ diskId: null, disks: [] });
      this.props.onHide();
    }
  }

  render() {
    return (
      <Modal
        appElement={document.getElementById('app')}
        isOpen={!!this.state.diskId}
        style={{
          ...ModalService.STYLE,
          content: {
            ...ModalService.STYLE.content,
            maxWidth: '1200px',
          },
        }}
        contentLabel="Disk history"
        onRequestClose={this.hide}
      >
        <button
          className="close"
          onClick={this.hide}
        >
          <span aria-hidden="true">×</span>
        </button>

        {
          this.state.locations ?
            (
              <div>
                <p>
                  Location history for disk "{this.state.disk.name}"
                </p>

                {
                  this.state.locations.length == 0 ?
                    (
                      <p>
                        There are no locations.
                      </p>
                    ) :
                    (
                      <table style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <th>Organization</th>
                            <th>Date</th>
                            <th>Transfer</th>
                          </tr>
                        </thead>

                        <tbody>
                          {this.state.locations.map((location) => {
                            return (
                              <tr key={location.id}>
                                <td>{location.organization.name}</td>
                                <td>{moment(location.created_at).toString()}</td>
                                <td style={{ fontSize: '10px' }}>
                                  {
                                    location.transfer ?
                                      '#' + location.transfer.id :
                                      <React.Fragment>&mdash;</React.Fragment>
                                  }
                                  {' '}
                                  {
                                    location.transfer &&
                                      location.transfer.playlists &&
                                      location.transfer.playlists.map(
                                        (playlist) => playlist.title
                                      )
                                      .join(' ')
                                  }
                                </td>
                              </tr>
                            );
                          })}
                          </tbody>
                      </table>
                    )
                }
              </div>
            ) :
            'Loading...'
        }
      </Modal>
    );
  }

}
