window.Tether = require('tether/dist/js/tether.js');
require('bootstrap/dist/js/bootstrap.js');
const $ = require('jquery');

$(document).ready(function() {
  // adds ios class to html tag
  var deviceAgent = navigator.userAgent.toLowerCase();
  var agentID = deviceAgent.match(/(iphone|ipod|ipad)/);
  if(agentID) {
    $('html').addClass('ios');
  }

  // force parallax to render again
  $(window).trigger('resize');

  $('.section-moviedetail-background').css({
    height: $('.section-moviedetail-content').outerHeight(),
  });
});

$(window).resize(function() {
  $('.section-moviedetail-background').css({
    height: $('.section-moviedetail-content').outerHeight(),
  });
});