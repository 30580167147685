import React from 'react';
import Modal from 'react-modal';

import Form from 'services/Form';
import ModalService from 'services/Modal';
import I18n from 'services/I18n';

export default class Organization extends React.Component {

  constructor(props) {
    super(props);

    this.state = {organization_id: props.organization_id};
    this.form = new Form(this);
    this.hide = this.hide.bind(this);
  }

  componentWillReceiveProps(props) {
    if(this.state.organization_id == props.organization_id) {
      return ;
    }

    this.setState({organization_id: props.organization_id}, () => {
      this.load()
    });
  }

  componentDidMount() {
    this.load();
  }

  load() {
    if(!this.state.organization_id) {
      return ;
    }

    this.form.api.receive(
      '/organizations/' + this.state.organization_id + '/disks',
    );
  }

  hide() {
    if(this.props.onHide) {
      this.setState({organization: null, disks: []});
      this.props.onHide();
    }
  }

  render() {
    return (
      <Modal
        appElement={document.getElementById('app')}
        isOpen={!!this.state.organization_id}
        style={ModalService.STYLE}
        contentLabel="Organization Disks"
        onRequestClose={this.hide}
      >
        <button
          className="close"
          onClick={this.hide}
        >
          <span aria-hidden="true">×</span>
        </button>

        {
          this.state.organization ?
            (
              <div>
                <p>
                  Disks in organization "{this.state.organization.name}":
                </p>

                {
                  this.state.disks.length == 0 ?
                    (
                      <p>
                        There are no disks.
                      </p>
                    ) :
                    (
                      <ul>
                        {this.state.disks.map((disk) => {
                          return (
                            <li key={disk.id}>
                              #{disk.name} &ndash; {I18n.date(disk.date)}
                            </li>
                          );
                        })}
                      </ul>
                    )
                }
              </div>
            ) :
            'Loading...'
        }
      </Modal>
    );
  }

}
