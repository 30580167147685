import React from 'react';
import Waypoint from 'react-waypoint';
import moment from 'moment/moment';

import Cache from 'services/Cache';
import FormApi from 'services/FormApi';
import Image from 'services/Image';
import Query from 'services/Query';
import I18n from 'services/I18n';
import Form from 'services/Form';

import App from 'components/App';

import FilmListItem from './ListItem';
import Utilities from './Utilities';

const $ = require('jquery');
const debounce = require('lodash/debounce')
const t = I18n.namespace('films');

const REGIONS_TITLES = {
  de: 'Deutschschweiz',
  fr: 'Romandie',
  it: 'Ticino',
}

export default class List extends React.Component {

  static url = '/films';

  constructor(props) {
    super(props);

    this.form = new Form(this);

    this.loading = false;
    this.state = {
      distributors: this.getCache('distributors', []),
      films: this.getCache('films', []),
      transfersCount: this.getCache('transfersCount', {}),
      genres: this.getCache('genres', []),
      more: false,
      query: {
        page: this.getCache('page', 0),
        limit:
          this.getCache('page', null) !== null ?
            this.getCache('page') * 50 + 50 :
            0,
      },
      weeks: this.getCache('weeks', null),
    };

    this.lastLocation = window.location.href;
    this.more = this.more.bind(this);
    this.scroll = debounce(this.scroll.bind(this), 250);
  }

  getCache(key, defaultValue) {
    return Cache.get(this.constructor.url + '.' + key, defaultValue);
  }

  setCache(key, value) {
    return Cache.set(this.constructor.url + '.' + key, value);
  }

  componentDidMount() {
    Image.load('/static/images/cover.png'); // required by film list items

    let query = Query.get();
    if(!query.order) {
      query.order = 'trailer_release_at';
    }

    this
      .form
      .api
      .receive(this.constructor.url, {}, {noFooter: true})
      .then((response) => {
        this.setCache('distributors', response.data.distributors);
        this.setCache('genres', response.data.genres);
      });

    this.setState({query: {...this.state.query, ...query}}, () => {
      this.load();
    });

    this.unlisten = this.props.history.listen(this.loadQuery.bind(this));
    $('main.content').on('scroll', this.scroll);

    if(this.getCache('height')) {
      $('.section-movielist-grid').css({minHeight: this.getCache('height')});
    }

    if(this.getCache('scroll')) {
      $('main.content').get(0).scrollTop = this.getCache('scroll');
    }
  }

  scroll() {
    if($('.films__list').length == 0) {
      return ;
    }

    this.setCache('scroll', $('main.content').get(0).scrollTop);
    this.setCache('height', $('.section-movielist-grid').height());
  }

  componentWillUnmount() {
    this.unlisten();
    $('main.content').off('scroll', this.scroll);
  }

  loadQuery() {
    if(this.lastLocation == window.location.href) {
      return ;
    }

    this.lastLocation = window.location.href;

    let query = Query.get();

    if(!query.order) {
      query.order = 'trailer_release_at';
    }

    this.setState({query: query}, () => {
      this.load();
    });
  }

  load(append = false) {
    if(this.loading) {
      return ;
    }

    this.loading = true;
    let query = {...this.state.query};
    if(query.images === 'true') {
      query.images = true;
    } else if(query.images === 'false') {
      query.images = false;
    }

    if(query.kinds) {
      query.kinds = query.kinds.split(',');
    }

    if(query.limit) {
      this.setState({
        query: {
          ...this.state.query,
          limit: undefined,
        },
      });

      query.page = 0;
    } else {
      delete query.limit;
    }

    this
      .form
      .api
      .get(this.constructor.url + '/list', {query: query}, {
        noLoading: true,
        noFooter: true,
      })
      .then((response) => {
        this.loading = false;
        let films = response.data.films;
        this.loadTransfersCounts(films);

        if(append) {
          films = this.state.films.concat(films);
        }

        let weeks = null;
        if(this.state.query.order != 'trailer_release_at') {
          weeks = {}
          if(append) {
            weeks = {...this.state.weeks};
          }

          this.addFilmsToWeeks(response.data.films, weeks);
        }

        let state = {...response.data, films: films, weeks: weeks};
        this.setState(state);
        this.setCache('films', films)
        this.setCache('weeks', weeks)

        let scroll = this.getCache('scroll');
        if(scroll) {
          $('main.content').get(0).scrollTop = scroll;
        }

        $('.section-movielist-grid').css({minHeight: 'auto'});
        App.instance.setState({footer: true});
      })
      .catch((error) => {
        if(error.cancel) {
          return ;
        }

        FormApi.processError(error);
        throw error;
      });
  }

  loadTransfersCounts(films) {
    if(this.constructor.url == '/screeners') {
      return ;
    }

    let filmsIds = films.map((film) => {
      return film.id;
    });

    // separate request because transfers count calculation is slow
    this
      .props
      .api
      .get('/films/transfers_count', {ids: filmsIds})
      .then((response) => {
        let transfersCount = {...this.state.transfersCount, ...response.data};
        this.setState({transfersCount: transfersCount});
        this.setCache('transfersCount', transfersCount);
      });
  }

  addFilmsToWeeks(films, weeks) {
    films.forEach((film) => {
      let date = moment(film.release_at);

      let week = 'TBA';
      if(date.isValid()) {
        week = date.format('D.M.Y');
      }

      if(film.is_no_release) {
        week = 'No Release';
      }

      if(I18n.getLocale() == 'en') {
        week += ' | ' + REGIONS_TITLES[film.locale];
      }

      weeks[week] = [...(weeks[week] || [])];
      weeks[week].date = date.unix() || 'TBA';

      let exists = weeks[week].find((current) => {
        return current.id == film.id;
      });

      if(exists) {
        return ;
      }

      weeks[week].push(film);
    });

    return weeks;
  }

  more() {
    if(!this.state.more) {
      return ;
    }

    this.setState(
      {query: {...this.state.query, page: (this.state.query.page || 0) + 1}},
      () => {
        this.load(true)
        this.setCache('page', this.state.query.page);
      },
    );
  }

  render() {
    return (
      <div>
        {this.renderFilter()}

        <section className="
          section-movielist-grid
          container-fluid
          films__list
        ">
          {this.renderList()}
        </section>
      </div>
    );
  }

  setQueryValue(key, value) {
    Query.set(key, value || undefined);
    this.setCache('query', Query.get());
    this.setCache('films', undefined);
    this.setCache('height', undefined);
    this.setCache('page', undefined);
    this.setCache('scroll', undefined);
    this.setCache('weeks', undefined);

    if(['distributor_id', 'kinds'].includes(key)) {
      Cache.set('/films.query', {
        ...Cache.get('/films.query', {}),
        [key]: value || null,
      });
    }
  }

  renderFilter() {
    return (
      <section className="
        section-filterbar
        filterbar
        container-fluid
        films__search
      ">
        <form className="form-container">
          <div className="form-inline films__filter">
            {this.renderFilterDistributors()}

            {this.props.currentUser.role != 'cinema_manager' && (
              Utilities.contentKindCheckboxes(t)
            )}

            {this.renderFilterGenres()}
            {this.renderFilterImages()}
          </div>

          {this.renderFilterSortBy()}
        </form>
      </section>
    );
  }

  renderFilterDistributors() {
    return (
      <div className="filterbar-dropdown form-group">
        <select
          className="custom-select filterbar-select films__distributors"
          value={this.state.query.distributor_id || ''}
          onChange={(event) => {
            this.setQueryValue('distributor_id', event.target.value);
          }}
        >
          <option value="">{t('all_distributors')}</option>
          {this.state.distributors.map((distributor) => {
            return (
              <option key={distributor.id} value={distributor.id}>
                {distributor.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  renderFilterGenres() {
    return (
      <div className="filterbar-dropdown form-group">
        <select
          className="custom-select filterbar-select"
          value={this.state.query.genre || ''}
          onChange={(event) => {
            this.setQueryValue('genre', event.target.value);
          }}
        >
          <option value="">{t('all_genres')}</option>
          {this.state.genres.map((genre) => {
            return (
              <option key={genre} value={genre}>{genre}</option>
            );
          })}
        </select>
      </div>
    );
  }

  renderFilterImages() {
    if(this.props.currentUser.role !== 'admin') {
      return null;
    }

    return (
      <div className="filterbar-dropdown form-group">
        <select
          className="custom-select filterbar-select"
          value={this.state.query.images}
          onChange={(event) => {
            this.setQueryValue('images', event.target.value);
          }}
        >
          <option value="">All Films</option>
          <option value="true">With Posters</option>
          <option value="false">Without Posters</option>
        </select>
      </div>
    );
  }

  renderFilterSortBy() {
    let releaseHomevideoGroups = [
      'admin',
      'distributor_supervisor',
      'distributor_homevideo_responsible',
    ];

    return (
      <div className="
        form-inline
        pull-xxl-right
        text-xxl-right
        films__sort-by
      ">
        <div className="filterbar-dropdown form-group">
          <label className="select-label">{t('sort_by')}</label>
          <select
            className="custom-select filterbar-select"
            value={this.state.query.order || ''}
            onChange={(event) => {
              this.setQueryValue('order', event.target.value);
            }}
          >
            <option value="trailer_release_at">{t('latest_update')}</option>
            <option value="release_at">{t('release_cinema')}</option>

            {releaseHomevideoGroups.includes(this.props.currentUser.role) && (
              <option value="release_vod_at">{t('release_homevideo')}</option>
            )}
          </select>
        </div>
      </div>
    );
  }

  renderList() {
    if(this.state.weeks !== null) {
      return (
        <div>
          {Object.keys(this.state.weeks).map((week) => {
            return (
              <div key={week} className="movielist-grid row">
                <div className="movielist-title col-xs-12">
                  <h4 className="movielist-title-heading">{week}</h4>
                </div>

                {this.state.weeks[week].map((film) => {
                  return <FilmListItem key={film.id} film={film} />;
                })}
              </div>
            );
          })}

          <div className="movielist-grid row">
            {this.renderWaypoint()}
          </div>
        </div>
      );
    }

    return (
      <div className="movielist-grid row">
        {this.state.films.map((film) => {
          return (
            <FilmListItem
              key={film.id}
              url={this.constructor.url}
              film={film}
              transfersCount={this.state.transfersCount[film.id]}
            />
          );
        })}

        {this.renderWaypoint()}
      </div>
    );
  }

  renderWaypoint() {
    if(this.state.films.length === 0 || !this.state.more) {
      return ;
    }

    return (
      <div className="movielist-item" style={{height: '350px'}}>
        <Waypoint
          scrollableAncestor={document.getElementById('content')}
          onEnter={this.more}
          bottomOffset="-512px"
        />
      </div>
    );
  }

}
