import React from 'react';

import EditComponent from 'components/Forms/Edit';

import Form from './Form';

export default class Edit extends EditComponent {

  static key = 'organization';
  static url = '/organizations';

  renderBody() {
    return (
      <Form
        {...this.state}
        save={this.save}
      />
    );
  }

}
