import React from 'react';
import _ from 'underscore';

import Container from 'components/Forms/List';
import Spinner from 'components/Spinner';
import Alert from 'components/Storage/Alert';
import Table from 'components/Storage/Table';
import ConfirmModal from 'components/Storage/ConfirmModal';

import Form from 'services/Form';
import I18n from 'services/I18n';
import Query from 'services/Query';

import 'styles/Storage/List.scss'

export default class List extends React.Component {
  constructor(props) {
    super(props)

    this.form = new Form(this);

    this.state = {
      page: 0,
      total: 0,
      query: Query.get('query'),
      dcps: [],
      checkedDCPs: [],
      lastCheckedRow: null,
      order: 'title',
      direction: 'asc',

      spinnerVisible: true,

      deletionScheduledSuccessfully: false,
      deletionConfirmedSuccessfully: false,
      deletionCanceledSuccessfully: false,

      confirmCheckboxSelectionModalOpen: false,
      confirmScheduleDeletionModalOpen: false,
      confirmFinalDeletionModalOpen: false,

      confirmModalList: [],
    };

    this.fetchMoreDCPs = this.fetchMoreDCPs.bind(this);

    this.onSearchChanged = this.onSearchChanged.bind(this);
    this.onSortChanged = this.onSortChanged.bind(this);
    this.onRowSelectChange = this.onRowSelectChange.bind(this);

    this.onConfirmCheckboxSelectionModal = this.onConfirmCheckboxSelectionModal.bind(this);
    this.onConfirmScheduleDeletionModal = this.onConfirmScheduleDeletionModal.bind(this);
    this.onConfirmFinalDeletionModal = this.onConfirmFinalDeletionModal.bind(this);

    this.onCancelModal = this.onCancelModal.bind(this);

    this.handleDelete = this.handleDelete.bind(this);
  }

  async componentDidMount() {
    const playlistIds = Query.get('playlist_ids');
    const confirmation = Query.get('code');

    if (playlistIds && confirmation) {
      const data = (await this.form.api.get('/storage', { playlist_ids: playlistIds })).data

      this.setState({ confirmModalList: data.dcps, confirmFinalDeletionModalOpen: true })
    }

    this.load();
  }

  async fetchMoreDCPs() {
    if (this.state.page < this.state.total - 1) {
      this.setState({ page: this.state.page + 1, spinnerVisible: true });

      await this.load(true);
    }
  }

  async handleDelete() {
    const dcps = (await this.form.api.get('/storage', { filenames: this.state.checkedDCPs })).data.dcps

    this.setState({ confirmScheduleDeletionModalOpen: true, confirmModalList: dcps })
  }

  filters() {
    return {
      page: this.state.page,
      query: this.state.query,
      order: this.state.order,
      direction: this.state.direction,
    }
  }

  async load(append = false) {
    let previousPlaylists = this.state.dcps;

    Query.set('query', this.state.query);
    Query.set('order', this.state.order);
    Query.set('direction', this.state.direction);

    const response = await this.form.api.get('/storage', this.filters())

    if (append) {
      response.data.dcps = previousPlaylists.concat(response.data.dcps);
    }

    this.setState({ ...response.data, spinnerVisible: false });
  }

  onSearchChanged(text) {
    this.setState({ query: text, spinnerVisible: true })
  }

  onSortChanged(order, direction) {
    this.setState({ page: 0, order: order, direction: direction, spinnerVisible: true }, this.load);
  }

  async onRowSelectChange(checked, dcpFilename) {
    const dcps = (await this.form.api.get(`/storage/${dcpFilename}/references`)).data.dcps

    if (checked) {
      this.setState({ lastCheckedRow: dcpFilename })

      if (dcps.length) {
        this.setState({ confirmCheckboxSelectionModalOpen: true, confirmModalList: dcps })
      } else {
        this.setState({ checkedDCPs: _.uniq([...this.state.checkedDCPs, dcpFilename]) });
      }
    } else {
      const referencedDCPs = dcps.map((dcp) => dcp.filename)

      this.setState({ checkedDCPs: _.without([...this.state.checkedDCPs], dcpFilename, ...referencedDCPs) });
    }
  }

  async onConfirmFinalDeletionModal(playlistIds) {
    await this.form.api.delete('/storage', {
      playlist_ids: playlistIds,
      confirmation: Query.get('code'),
    })

    this.setState({ confirmFinalDeletionModalOpen: false, deletionConfirmedSuccessfully: true, spinnerVisible: true })

    this.load();
  }

  async onConfirmScheduleDeletionModal(playlistIds) {
    try {
      await this.form.api.delete('/storage', { playlist_ids: playlistIds })
    } catch (_err) {
      // This endpoint returns a 428 response on success as it requires a confirmation code to proceed
    }

    this.setState({
      checkedDCPs: [],
      confirmScheduleDeletionModalOpen: false,
      deletionScheduledSuccessfully: true,
    })
  }

  onConfirmCheckboxSelectionModal() {
    const references = this.state.confirmModalList.map((dcp) => dcp.filename)

    this.setState({
      confirmCheckboxSelectionModalOpen: false,
      checkedDCPs: [...this.state.checkedDCPs, this.state.lastCheckedRow, ...references],
    })
  }

  onCancelFinalDeletionModal = () => {
    this.load();

    this.setState({ confirmFinalDeletionModalOpen: false, deletionCanceledSuccessfully: true })
  }

  onCancelModal = () => {
    this.setState({ confirmScheduleDeletionModalOpen: false, confirmCheckboxSelectionModalOpen: false })
  }

  render() {
    return (
      <Container>
        <ConfirmModal
          open={ this.state.confirmCheckboxSelectionModalOpen }
          dcps={ this.state.confirmModalList }
          title={ I18n.t('storage.modal.references_deletion.title') }
          confirmText={ I18n.t('storage.modal.references_deletion.yes') }
          cancelText={ I18n.t('storage.modal.references_deletion.no') }
          onConfirm={ this.onConfirmCheckboxSelectionModal }
          onCancel={ this.onCancelModal }/>

        <ConfirmModal
          open={ this.state.confirmScheduleDeletionModalOpen }
          dcps={ this.state.confirmModalList }
          title={ I18n.t('storage.modal.schedule_deletion.title', { count: this.state.confirmModalList.length }) }
          confirmText={ I18n.t('storage.modal.schedule_deletion.yes') }
          cancelText={ I18n.t('storage.modal.schedule_deletion.no') }
          onConfirm={ this.onConfirmScheduleDeletionModal }
          onCancel={ this.onCancelModal }/>

        <ConfirmModal
          open={ this.state.confirmFinalDeletionModalOpen }
          dcps={ this.state.confirmModalList }
          title={ I18n.t('storage.modal.final_deletion.title', { count: this.state.confirmModalList.length }) }
          confirmText={ I18n.t('storage.modal.final_deletion.yes') }
          cancelText={ I18n.t('storage.modal.final_deletion.no') }
          onConfirm={ this.onConfirmFinalDeletionModal }
          onCancel={ this.onCancelFinalDeletionModal }/>

        <div className="row">
          <div className="col-sm-6">
            <h1 className="pull-left">Storage</h1>

            <Spinner visible={ this.state.spinnerVisible } className="pull-left storage__spinner" />

            {
              // Currently deletion of storages is disabled
              (false && !!this.state.checkedDCPs.length) &&
                <button
                  type="submit"
                  className="btn btn-primary pull-right"
                  onClick={ () => this.handleDelete() }>
                  Delete
                </button>
            }
          </div>

          <div className="col-sm-6">
            <form onSubmit={ (event) => {
              event.preventDefault();

              this.setState({ page: 0 }, this.load)
            } }>
              <div className="input-group">
                <input
                  type="search"
                  className="form-control users__query"
                  value={ Form.value(this.state.query) }
                  onChange={ (event) => this.onSearchChanged(event.target.value) }
                  placeholder="Search..." />

                <span className="input-group-btn">
                  <button type="submit" className="btn btn-primary">
                    <span className="fa fa-search"></span>
                  </button>
                </span>
              </div>
            </form>
          </div>
        </div>

        {
          this.state.deletionScheduledSuccessfully && <Alert mode='warning' message={ I18n.t('storage.alert.schedule_deletion_confirmed') } />
        }

        {
          this.state.deletionConfirmedSuccessfully && <Alert mode='success' message={ I18n.t('storage.alert.deletion_confirmed') } />
        }

        {
          this.state.deletionCanceledSuccessfully && <Alert mode='success' message={ I18n.t('storage.alert.deletion_canceled') } />
        }

        <Table
          rows={ this.state.dcps }
          checkedRows={ this.state.checkedDCPs }
          order={ this.state.order }
          direction={ this.state.direction }
          onFetchMoreRows={ this.fetchMoreDCPs }
          onRowSelectChange={ this.onRowSelectChange }
          onSortChange={ this.onSortChanged } />
      </Container>
    );
  }
}
