import React from 'react';
import Waypoint from 'react-waypoint';

import Checkbox from 'components/Forms/Checkbox';
import SortLink from 'components/Forms/SortLink';
import Spinner from 'components/Spinner';

import I18n from 'services/I18n';

export default class Table extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaidng: true,
    };

    this.onCheckboxCheckedChanged = this.onCheckboxCheckedChanged.bind(this);
    this.onInfiniteScrolling = this.onInfiniteScrolling.bind(this);
    this.classNameForDCP = this.classNameForDCP.bind(this);
    this.convertByteToGigabyte = this.convertByteToGigabyte.bind(this);
  }

  async onInfiniteScrolling() {
    this.setState({ loading: true });

    await this.props.onFetchMoreRows();

    this.setState({ loading: false });
  }

  async onCheckboxCheckedChanged(dcp, checked) {
    await this.props.onRowSelectChange(checked, dcp.filename);
  }

  convertByteToGigabyte(byte) {
    return Math.round(byte / (1024 * 1024 * 1024));
  }

  classNameForDCP = (dcp) => {
    const classes = ['storage-table__cpl'];

    if ((dcp.cpl_titles || []).length > 1) {
      classes.push('storage-table__cpl--group');
    }

    if (dcp.scheduled_for_deletion) {
      classes.push('storage-table__cpl--scheduled-for-deletion');
    }

    return classes.join(' ');
  }

  render () {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>
              <SortLink order="film_title" current={ this.props.order } direction={ this.props.direction } sort={ this.props.onSortChange }>
                TITLE
              </SortLink>
            </th>

            <th>
              <SortLink order="cpl_titles" current={ this.props.order } direction={ this.props.direction } sort={ this.props.onSortChange }>
                CPL
              </SortLink>
            </th>

            <th>
              <SortLink order="start_date_d_ch" current={ this.props.order } direction={ this.props.direction } sort={ this.props.onSortChange }>
                START D-CH
              </SortLink>
            </th>

            <th>
              <SortLink order="start_date_f_ch" current={ this.props.order } direction={ this.props.direction } sort={ this.props.onSortChange }>
                START F-CH
              </SortLink>
            </th>

            <th>
              <SortLink order="start_date_i_ch" current={ this.props.order } direction={ this.props.direction } sort={ this.props.onSortChange }>
                START I-CH
              </SortLink>
            </th>

            <th>
              <SortLink order="cpl_theatrical_flags" current={ this.props.order } direction={ this.props.direction } sort={ this.props.onSortChange }>
                THEATRICAL
              </SortLink>
            </th>

            <th>
              <SortLink order="cpl_homevideo_flags" current={ this.props.order } direction={ this.props.direction } sort={ this.props.onSortChange }>
                HOMEVIDEO
              </SortLink>
            </th>

            <th>
              <SortLink order="filesize" current={ this.props.order } direction={ this.props.direction } sort={ this.props.onSortChange }>
                DCP SIZE
              </SortLink>
            </th>

            <th>
              <SortLink order="charged_fee_from" current={ this.props.order } direction={ this.props.direction } sort={ this.props.onSortChange }>
                FEE FROM
              </SortLink>
            </th>

            {
              // Currently deletion of storages is disabled
              false && <th></th>
            }
          </tr>
        </thead>

        <tbody>
          {
            this.props.rows.map((dcp) =>
               <tr key={ dcp.filename } className={ this.classNameForDCP(dcp) }>
                 <td>
                   <a href={ `/films/${dcp.film_id}` }>{ dcp.film_title }</a>
                 </td>

                 <td className="storage-table__cpls">
                   { (dcp.cpl_titles || []).map((title, i) => <p key={ `${i}-${title}` }>{ title }</p>) }
                 </td>
                 <td>{ I18n.date(dcp.start_date_d_ch) }</td>
                 <td>{ I18n.date(dcp.start_date_f_ch) }</td>
                 <td>{ I18n.date(dcp.start_date_i_ch) }</td>

                 <td>
                   {
                     (dcp.cpl_theatrical_flags || []).map((cpl_theatrical_flags, i) =>
                       <p key={ `${i}-${dcp.filename}` }><i className={ 'fa icon icon-lg ' + (cpl_theatrical_flags ? 'fa-check asset-status--done' : 'fa-minus') } /></p>
                     )
                   }
                 </td>

                 <td>
                   {
                     (dcp.cpl_homevideo_flags || []).map((cpl_homevideo_flags, i) =>
                       <p key={ `${i}-${dcp.filename}` }><i className={ 'fa icon icon-lg ' + (cpl_homevideo_flags ? 'fa-check asset-status--done' : 'fa-minus') } /></p>
                     )
                   }
                 </td>

                 <td>{ `${this.convertByteToGigabyte(dcp.filesize)} GB` }</td>

                 <td>{ I18n.date(dcp.charged_fee_from) }</td>

                 <td>
                  {
                    // Currently deletion of storages is disabled
                    (false && !dcp.scheduled_for_deletion) &&
                      <Checkbox
                        value={ dcp.filename }
                        checked={ this.props.checkedRows.includes(dcp.filename) }
                        onChange={ (checked) => this.onCheckboxCheckedChanged(dcp, checked) }
                        dark={true} />
                  }
                 </td>
               </tr>
            )
          }

          <tr>
            <td colSpan='10'>
              <Waypoint scrollableAncestor={ document.getElementsByClassName('content')[0] } onEnter={ this.onInfiniteScrolling } />

              {
                this.state.loading && (
                  <div className='p-relative' style={ { height: '30px' } }>
                    <Spinner visible={ true } size={ 'big' } />
                  </div>
                )
              }
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}
