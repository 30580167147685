import React from 'react';

import DateTimePicker from 'services/DateTimePicker';
import FormService from 'services/Form';
import I18n from 'services/I18n';

export default class Region extends React.Component {

  constructor(props) {
    super(props);

    this.form = new FormService(this, {
      onSetState: (state) => {
        this.props.change(state.region);
      },
    });

    this.state = {
      loading: false,
      region: props.region,
      errors: props.errors,
    };
  }

  componentWillReceiveProps(props) {
    this.setState(props);
  }

  render() {
    if(!this.props.region) {
      return null;
    }

    return (
      <div key={this.props.region.locale}>
        <hr />

        <h3>{
          this.props.region.locale == 'en' ?
            'Original' :
            this.props.region.locale.toUpperCase()[0] + '-CH'
        }</h3>

        {this.form.fields.input('region.title', 'Title', {
          input: {
            disabled: !this.props.is_procinema_parsing_disabled,
          },
        })}
        {this.form.fields.file('region.image', 'Poster')}

        {this.form.fields.textarea('region.description', 'Description', {
          element: {
            rows: 16,
            disabled: !this.props.is_procinema_parsing_disabled,
          },
        })}

        {this.props.region.locale != 'en' &&
          this.renderReleaseDates(this.props.region)}
      </div>
    );
  }

  renderReleaseDates(region) {
    let waitForDates = (
      region.id &&
        (!('release_at' in region) || !('release_vod_at' in region))
    );

    if(waitForDates) {
      return ;
    }

    return (
      <div>
        {!this.state.region.is_no_release && this.form.fields.input(
          'region.release_at',
          'Release Cinema',
          {
            input: {
              value: undefined,
              placeholder: 'TBA',
              defaultValue: I18n.datetime(region.release_at),
              ref: DateTimePicker.binder(
                this.form.setter('region.release_at'),
                {format: 'DD.MM.YYYY'},
              ),
              disabled: !this.props.is_procinema_parsing_disabled,
            },
          }
        )}

        {this.form.fields.checkbox(
          'region.is_no_release',
          'No Release Cinema',
          {
            disabled: !this.props.is_procinema_parsing_disabled,
          }
        )}

        {!this.state.region.is_no_release_vod && this.form.fields.input(
          'region.release_vod_at',
          'Release Homevideo',
          {
            input: {
              value: undefined,
              placeholder: 'TBA',
              defaultValue: I18n.datetime(region.release_vod_at),
              ref: DateTimePicker.binder(
                this.form.setter('region.release_vod_at'),
                {format: 'DD.MM.YYYY'},
              ),
            },
          }
        )}

        {this.form.fields.checkbox(
          'region.is_no_release_vod',
          'No Release VOD',
        )}
      </div>
    );
  }

}
