import React from 'react';
import I18n from 'services/I18n';
import Text from 'services/Text';

const $ = require('jquery');
require('jquery-typeahead/dist/jquery.typeahead.min.js');

export default class Navigation extends React.Component {

  constructor() {
    super();
    this.createTypeahead = this.createTypeahead.bind(this);
  }

  createTypeahead(element) {
    this.typeahead = $.typeahead({
      input: element,
      highlight: false,
      filter: false,
      minLength: 1,
      maxItem: 15,
      hint: true,
      dynamic: true,
      mustSelectItem: false,
      backdrop: {
        'background-color': '#fff',
      },
      href:
        this.props.user && this.props.user.role == 'journalist' ?
          '/screeners/{{film_id}}' :
          '/films/{{film_id}}',
      display: 'title',
      emptyTemplate: 'No result for "{{query}}"',
      template: function(query, item) {
        let itemTitle = item.title || item.original_title;
        var text = Text.highligh(itemTitle, query)

        if(itemTitle == item.original_title) {
          return text;
        }

        return ' \
          ' + text + ' \
          <div class="typeahead__item-original-title"> \
            {{original_title}} \
          </div> \
        ';
      },
      source: {
        ajax: {
          url: '/api/v2/films/autocomplete',
          headers: this.props.api.createHeaders(),
          data: {
            query: '{{query}}',
          },
        },
      },
      debug: false,
      callback: {
        onShowLayout: function () {
          $('.typeahead__backdrop').height($('body').outerHeight() + 256);
        },
        onClickBefore: (_node, _item, _args, event) => {
          event.preventDefault();

          $(element).typeahead('close');

          $('.typeahead__container')
            .removeClass('backdrop')
            .removeClass('result')
            .removeClass('hint');
        },
      },
    });
  }

  search(event) {
    event.preventDefault();

    let query = new URLSearchParams;
    let input = document.getElementById('navigation__search-input');

    query.set('query', input.value);
    this.props.history.push('/search?' + query.toString());
    this.typeahead.hideLayout();
  }

  render() {
    if(!this.props.user) {
      return null;
    }

    return (
      <form
        id="navigation__search-form"
        className="navbar-search col-xs-9 col-sm-6 col-md-4"
        onSubmit={(event) => {
          this.search(event);
        }}
      >
        <div className="typeahead__container">
          <div className="input-group">
            <div className="typeahead__field">
              <span className="input-group-addon" id="basic-addon1">
                <i className="icon fa fa-search fa-fw search__icon"></i>
              </span>

              <span className="typeahead__query">
                <input
                  type="text"
                  id="navigation__search-input"
                  className="form-control navigation__search"
                  name="query"
                  autoComplete="off"
                  placeholder={I18n.t('navigation.search')}
                  defaultValue={
                    (new URLSearchParams(
                      (new URL(location)).search,
                    )).get('query')
                  }
                  ref={this.createTypeahead}
                  onKeyPress={(event) => {
                    if(event.key == 'Enter') {
                      this.search(event);
                    }
                  }}
                />
              </span>
            </div>
          </div>
        </div>

        <input type="submit" style={{display: 'none'}} />
      </form>
    );
  }

}
