import React from 'react';

export default class Row extends React.PureComponent {

  render() {
    let hasLabel = this.props.title !== undefined && this.props.title !== null;

    return (
      <div className={'form-group row ' + this.props.className}>
        {hasLabel && (
          <label className="
            col-xs-12
            col-sm-4
            form-control-label
            form-labels-mini
          ">{this.props.title}</label>
        )}

        <div className={
          'col-xs-12 ' +
            (hasLabel && 'col-sm-8') +
            ' ' +
            (this.props.contentClassName || '')
        }>
          {this.props.children}
        </div>
      </div>
    );
  }

}
