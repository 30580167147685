import React from 'react';

import I18n from 'services/I18n';

const t = I18n.namespace('deliver');

export default class Help extends React.Component {

  render() {
    if(!this.props.playlist) {
      return null;
    }

    return (
      <section className="section section-movielist-list">
        <div className="container-fluid">
          {this.props.playlist.dkdm && this.props.playlist.dkdm.url && (
            <div>
              <p>
                <span className="kdm-icon">
                  <i className="fa fa-lock icon icon-lg fa-fw yellow"></i>
                </span>

                <span className="space">{t('kdm_valid_in_future')}</span>
              </p>

              <p>
                <span className="kdm-icon">
                  <i className="fa fa-lock icon icon-lg fa-fw green"></i>
                </span>

                <span className="space">{t('kdm_valid')}</span>
              </p>

              <p>
                <span className="kdm-icon">
                  <i className="fa fa-lock icon icon-lg fa-fw orange"></i>
                </span>

                <span className="space">{t('kdm_expire_soon')}</span>
              </p>

              <p>
                <span className="kdm-icon">
                  <i className="fa fa-lock icon icon-lg fa-fw red"></i>
                </span>

                <span className="space">{t('kdm_expired')}</span>
              </p>
            </div>
          )}

          <div className="histories">
            <p>
              <span className="transfer-icon">
                <i title="prepared" className="
                  icon icon-lg fa-fw fa fa-circle-o asset-status--waiting
                "></i>
              </span>

              {t('dcp_prepared')}
            </p>

            <p>
              <span className="transfer-icon">
                <i className="
                  icon icon-lg fa-fw fa fa-dot-circle-o asset-status--progress
                "></i>
              </span>

              {t('dcp_in_progress')}
            </p>

            <p>
              <span className="transfer-icon">
                <i className="
                  icon icon-lg fa-fw fa fa-check-circle asset-status--done
                "></i>
              </span>

              {t('dcp_delivered')}
            </p>
          </div>
        </div>
      </section>
    );
  }
}
