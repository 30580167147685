import React from 'react';

import EditComponent from 'components/Forms/Edit';

import Query from 'services/Query';
import Form from './Form';

export default class Edit extends EditComponent {

  static key = 'film';
  static url = '/films';

  save(data) {
    this.setState(data);
    let url = '/films/' + this.state.film.id;
    return this.form.api.post(url, data).then(() => {
      Query.redirect(url);
    });
  }

  render() {
    return (
      <Form
        {...this.props}
        {...this.state}
        save={this.save}
      />
    );
  }

}
