import React from 'react';
import moment from 'moment/moment';

import I18n from 'services/I18n';
import Form from 'services/Form';

import Utilities from 'components/Films/Utilities';

const debounce = require('lodash/debounce')

export default class Cities extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      organizations: [],
      foundOrganizations: [],
      cities: [],
      foundCities: [],
      screens: [],
      screensDescriptions: {},
    };

    this.form = new Form(this, {
      onSetState: (state) => {
        this.props.setScreens(state.screens);
      },
    });

    this.loadedDescriptions = {};

    this.toggleCity = this.toggleCity.bind(this);
    this.toggleOrganization = this.toggleOrganization.bind(this);
    this.toggleScreens = this.toggleScreens.bind(this);
    this.debouncedSearch = debounce(this.search.bind(this), 500);
  }

  render() {
    if(!this.props.cities) {
      return null;
    }

    return (
      <div>
        <div className="row" style={{marginTop: '-20px'}}>
          <div className="col-lg-6 col-md-12">
            <div className="jumbotron jumbotron--compact">
              <div className="row">
                <div className="col-xs-12">
                  <div className="table-stack">
                    <div className="input-group">
                      <input
                        type="search"
                        id="playlist-delivery__query"
                        placeholder="Find city, organization, or screen"
                        className="
                          form-control
                          form-control-light
                          kdms-delivery__search-query
                        "
                        onChange={(event) => {
                          this.debouncedSearch(event.target.value);
                        }}
                      />

                      <div className="input-group-btn">
                        <button
                          type="button"
                          className="btn btn btn-primary"
                          style={{marginBottom: '0'}}
                          onClick={() => {
                            let value = document.getElementById(
                              'playlist-delivery__query',
                            );

                            this.search(value);
                          }}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <span className="table-stack">
              {this.renderCities()}
            </span>
          </div>
        </div>
      </div>
    );
  }

  search(query) {
    if(query) {
      query = I18n.removeAccents(query).toLowerCase();
    }

    let cities = [];
    let organizations = [];
    let screens = [];

    if(query) {
      this.props.cities.forEach((city) => {
        let isCityNameFound =
          I18n
            .removeAccents(city.name)
            .toLowerCase()
            .includes(query);

        if(isCityNameFound) {
          cities.push(city.id);
          city.organizations.forEach((organization) => {
            organizations.push(organization.id);
            organization.screens.forEach((screen) => {
              screens.push(screen.id);
            });
          });
        }

        city.organizations.forEach((organization) => {
          let isOrganizationNameFound =
            I18n
              .removeAccents(organization.name)
              .toLowerCase()
              .includes(query);

          if(isOrganizationNameFound) {
            cities.push(city.id);
            organizations.push(organization.id);
            organization.screens.forEach((screen) => {
              screens.push(screen.id);
            });
          }

          if(!this.props.playlist.dkdm || !this.props.playlist.dkdm.url) {
            return ;
          }

          let isScreenNameFound = false;
          if(organization.screens) {
            organization.screens.find((screen) => {
              let found =
                I18n
                  .removeAccents(screen.name)
                  .toLowerCase()
                  .includes(query);

              if(found) {
                isScreenNameFound = true;
                screens.push(screen.id);
              }
            });
          }

          if(isScreenNameFound) {
            organizations.push(organization.id);
            cities.push(city.id);
          }
        });
      });
    }

    this.setState({
      query: query,
      cities: cities,
      foundCities: cities,
      foundOrganizations: organizations,
      foundScreens: screens,
      organizations: [],
    });
  }

  highlight(text, query) {
    var clearText = I18n.removeAccents(text).toLowerCase();

    var start = clearText.indexOf(query);
    if(start !== -1) {
      let end = start + query.length;

      let result = (
        <span>
          {text.substr(0, start)}

          <strong className="style__highlight-query">
            {text.substr(start, end - start)}
          </strong>

          {text.substr(end)}
        </span>
      );

      return [result, true];
    }

    return [text, false];
  }

  renderCities() {
    return (
      <table className="table-stack--booking table">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th className="table-cell--name">Screen</th>
            <th className="table-cell--date">Kdm valid from</th>
            <th className="table-cell--date">Kdm valid to</th>
            <th className="table-cell--date">Kdm created</th>
            <th>Recipients</th>
          </tr>
        </thead>

        <tbody>
          <tr className="table-row--main">
            <td style={{height: '16px'}}></td>
          </tr>
          {this.props.cities.map((city) => {
            return this.renderCity(city);
          })}
        </tbody>
      </table>
    );
  }

  toggleCity(event) {
    let target = event.target;
    if(target.nodeName != 'A') {
      target = target.parentNode;
    }

    this.form.toggle('cities', parseInt(target.dataset.id));
  }

  renderCity(city) {
    if(!city.organizations) {
      return null;
    }

    let name = city.name;
    if(this.state.query) {
      if(!this.state.foundCities.includes(city.id)) {
        return null;
      }

      name = this.highlight(city.name, this.state.query)[0];
    }

    let isExpanded = this.state.cities.includes(city.id);

    return [
      <tr className="table-row--main" key={'city-' + city.id}>
        <td className="table-cell--collapse">
          <a
            href="#"
            data-id={city.id}
            onClick={this.toggleCity}
            className={
              'asset-status-item ' +
                (!isExpanded && 'collapsed')
            }
          >
            <i
              className="icon fa fa-chevron-right fa-fw asset-status-collapse"
            ></i>
          </a>
        </td>

        <td className="table-cell--checkbox"></td>

        <td className="table-cell--icons">
          {Utilities.getTransferIcon(city.transfer_status_cd)}
          {this.kdmStatusIcon(city.kdm_start_at, city.kdm_end_at)}
        </td>

        <td className="table-cell--name" colSpan="5">
          <strong className="kdms-form__city-name kdms-form__name-value">
            {name}
          </strong>
        </td>
      </tr>,
      isExpanded && (
        <tr
          className="table-collapse table-row--child collapse in"
          key={'organizations-' + city.id}
        >
          <td className="table-cell--container" colSpan="8">
            <table className="table playlist-delivery__organizations">
              <tbody>
                {city.organizations.map((organization) => {
                  let isOrganizationVisible =
                    this.state.organizations.includes(organization.id);

                  return [
                    this.renderOrganization(
                      organization,
                      isOrganizationVisible,
                    ),
                    isOrganizationVisible && this.renderScreens(
                      organization,
                    ),
                  ];
                })}
              </tbody>
            </table>
          </td>
        </tr>
      ),
    ];
  }

  toggleOrganization(event) {
    let target = event.target;
    if(target.nodeName != 'A') {
      target = target.parentNode;
    }

    this.form.toggle('organizations', parseInt(target.dataset.id));
    this.loadScreensDescriptions(parseInt(target.dataset.id));
  }

  toggleScreens(event) {
    this.form.toggleArray(
      'screens',
      event.target.dataset.screens.split(',').map((screenId) => {
        return parseInt(screenId);
      }),
       event.target.checked,
    );
  }

  loadScreensDescriptions(organizationId) {
    if(this.loadedDescriptions[organizationId]) {
      return ;
    }

    this.loadedDescriptions[organizationId] = true;

    let organization = null;
    this.props.cities.forEach((city) => {
      city.organizations.forEach((localOrganization) => {
        if(localOrganization.id == organizationId) {
          organization = localOrganization;
        }
      });
    });

    if(!organization || !organization.screens) {
      return ;
    }

    let ids = organization.screens.map((screen) => {
      return screen.id;
    });

    this
      .props
      .api
      .get('/screens/info?ids=' + ids.join(','))
      .then((response) => {
        let descriptions = {...this.state.screensDescriptions};

        response.data.forEach((screen) => {
          descriptions[screen.id] = `
Media Block maker: ${screen.server_make}
Media Block model: ${screen.server_model}
Media Block serial: ${screen.server_serial}
Media Block software version: ${screen.server_software_version}
Projector maker: ${screen.projector_make}
Projector model: ${screen.projector_model}
Projector serial: ${screen.projector_serial}
Projector software version: ${screen.projector_software_version}
3D type: ${screen.type3d}
35mm Backup: ${screen.backup35mm ? 'yes' : 'no'}
Adjustable Screen Mask (Top/Side/Both/None): ${screen.screen_mask}
Screen Aspect Ratio (1.85/2.39/1.66): ${screen.screen_aspect_ratio}
Integrator: ${screen.integrator}
Installation date: ${I18n.date(screen.installation_date)}
          `;
        });

        this.setState({screensDescriptions: descriptions});
      });
  }

  renderOrganization(organization, isVisible) {
    let name = organization.name;

    if(this.state.query) {
      if(!this.state.foundOrganizations.includes(organization.id)) {
        return null;
      }

      name = this.highlight(organization.name, this.state.query)[0];
    }

    let screensIds = organization.screens.map((screen) => {
      return screen.id;
    });

    return (
      <tr className="table-row--group" key={'organization-' + organization.id}>
        <td className="table-cell--collapse">
          {this.props.playlist.dkdm && this.props.playlist.dkdm.url && (
            <a
              href="#"
              data-id={organization.id}
              className={
                'playlist-delivery__organization-chevron asset-status-item ' +
                  (!isVisible && 'collapsed')
              }
              onClick={this.toggleOrganization}
            >
              <i className="
                icon
                fa
                fa-chevron-right
                fa-fw
                asset-status-collapse
              "></i>
            </a>
          )}
        </td>

        <td className="table-cell--checkbox">
          <label
            className="custom-control custom-checkbox custom-checkbox-compact"
          >
            <input
              type="checkbox"
              id={'organization-' + organization.id}
              className="custom-control-input"
              data-screens={screensIds.join(',')}
              checked={screensIds.every((screenId) => {
                return this.state.screens.includes(screenId);
              })}
              onChange={this.toggleScreens}
            />

            <span className="custom-control-indicator">
              <i className="icon fa fa-fw fa-check"></i>
            </span>
          </label>
        </td>

        <td className="
          table-cell--icons
          playlist-delivery__organization-transfer-status
        ">
          {organization.transfer_status_cd !== null ?
            Utilities.getTransferIcon(organization.transfer_status_cd) :
            undefined}

          {this.kdmStatusIcon(
            organization.kdm_start_at,
            organization.kdm_end_at
          )}
        </td>

        <td className="table-cell--name" colSpan="5">
          <label
            htmlFor={'organization-' + organization.id}
            className="playlist-delivery__organization-name"
          >
            {name}
          </label>
        </td>
      </tr>
    );
  }

  renderScreens(organization) {
    if(!organization.screens || organization.screens.length === 0) {
      return null;
    }

    return (
      <tr
        className="table-collapse table-row--child collapse in"
        key={'screens-' + organization.id}
      >
        <td className="table-cell--container" colSpan="8">
          <table className="table">
            <tbody>
              {organization.screens.map((screen) => {
                let name = screen.name;

                if(this.state.query) {
                  if(!this.state.foundScreens.includes(screen.id)) {
                    return null;
                  }

                  name = this.highlight(screen.name, this.state.query)[0];
                }

                if(screen.kdms.length === 0) {
                  return this.renderKdm(screen, name, null, 0);
                }

                return screen.kdms.map((kdm, kdmIndex) => {
                  return this.renderKdm(screen, name, kdm, kdmIndex);
                });
              })}
            </tbody>
          </table>
        </td>
      </tr>
    );
  }

  renderKdm(screen, name, kdm, kdmIndex) {
    return (
      <tr
        key={screen.id + '-' + (kdm && kdm.id)}
        className="kdms-form__screen-row"
      >
        <td className="table-cell--collapse"></td>

        <td className="table-cell--checkbox">
          {kdmIndex === 0 && screen.enabled ?
            (
              <label className="
                custom-control
                custom-checkbox
                custom-checkbox-compact
              ">
                <input
                  type="checkbox"
                  id={'screen-' + screen.id}
                  className="custom-control-input"
                  data-screens={screen.id}
                  checked={this.state.screens.includes(screen.id)}
                  onChange={this.toggleScreens}
                />

                <span className="custom-control-indicator">
                  <i className="icon fa fa-fw fa-check"></i>
                </span>
              </label>
            ) :
            (
              <i className="
                icon
                icon-lg
                fa
                fa-minus
                fa-fw
                icon--booking--done
              "></i>
            )
          }
        </td>

        <td className="table-cell--icons">
          <span>
            <i className="icon icon-lg fa fa-fw" />
          </span>

          {kdm && this.kdmStatusIcon(kdm.start_at, kdm.end_at)}
        </td>

        <td className="table-cell--name" title={screen.description}>
          <label
            htmlFor={'screen-' + screen.id}
            className="playlist-delivery__screen-name"
            title={this.state.screensDescriptions[screen.id]}
          >
            <em className="kdms-form__name-value">{name}</em>
          </label>
        </td>

        {!kdm && (<td colSpan="5"></td>)}

        {kdm && (
          <td className="
            table-cell--date
            table-cell--hidden-md-down
          ">
            <span className="table-cell--label">KDM valid from</span>

            <span className="table-cell--value">
              {I18n.datetime(kdm.start_at)}
            </span>
          </td>
        )}

        {kdm && (
          <td className="
            table-cell--date
            table-cell--hidden-md-down
          ">
            <span className="table-cell--label">KDM valid to</span>

            <span className="table-cell--value">
              {I18n.datetime(kdm.end_at)}
            </span>
          </td>
        )}

        {kdm && (
          <td className="
            table-cell--date
            table-cell--hidden-md-down
          ">
            <span className="table-cell--label">KDM created</span>

            <span className="table-cell--value">
              {I18n.datetime(kdm.created_at)}
            </span>
          </td>
        )}

        {kdm && (
          <td className="
            table-cell--date
            table-cell--hidden-md-up
          ">
            {I18n.datetime(kdm.start_at)}
            -
            {I18n.datetime(kdm.end_at)}
          </td>
        )}

        {kdm && (
          <td className="table-cell--info">{kdm.email}</td>
        )}
      </tr>
    );
  }

  kdmStatusIcon(startAt, endAt) {
    return (
      <span className="kdm-icon">
        <i className={
          'fa fa-lock icon icon-lg fa-fw ' + this.kdmStatusClass(
            startAt,
            endAt,
          )
        }></i>
      </span>
    );
  }

  kdmStatusClass(startAt, endAt) {
    if(!startAt || !endAt) {
      return null;
    }

    let startAtMoment = moment(startAt);
    let endAtMoment = moment(endAt);
    let now = moment();

    if(now.isAfter(endAtMoment)) {
      return 'red';
    }

    if(now.isBefore(startAtMoment)) {
      return 'yellow';
    }

    return 'green';
  }

}
