import React from 'react';

import Container from 'components/Forms/Container';
import Row from 'components/Forms/Row';

import Query from 'services/Query';
import Form from 'services/Form';

export default class Ingest extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      film: this.props.film || {},
      dcps: [],
      playlists: {
        dcps: [],
      },
      errors: {},
    };

    this.save = this.save.bind(this);
    this.form = new Form(this);
  }

  componentDidMount() {
    this.load();
  }

  load() {
    this.form.api.receive('/films/dcps', {id: this.props.id});
  }

  save(event) {
    event.preventDefault();

    this
      .form
      .api
      .post(
        '/films/' + this.props.id + '/ingest',
        {playlists: this.state.playlists},
      )
      .then(() => {
        Query.redirect('/films/' + this.props.id + '/edit')
      });
  }

  render() {
    let region = null;
    if(this.state.film.regions) {
      region = this.state.film.regions.find((localRegion) => {
        return localRegion.locale == 'en';
      });
    }

    return (
      <Container title={`${region && region.title}: Ingest DCPs`}>
        <form onSubmit={this.save}>

          <label className="form-control-label form-labels-mini">
            DCPs
          </label>

          <Row contentClassName="films-ingest__dcps-container">
            <div className="films-ingest__dcps">
              {this.state.dcps.map((dcp) => {
                return (
                  <div key={dcp}>
                    {this.form.fields.checkbox(
                      'playlists.dcps',
                      null,
                      {
                        label: dcp,
                        checked: this.state.playlists.dcps.includes(dcp),
                        onChange: this.form.callback(
                          `playlists.dcps.${dcp}`,
                          ((checked) => {
                            this.form.toggle('playlists.dcps', dcp, checked);
                          }),
                        ),
                      }
                    )}
                  </div>
                );
              })}
            </div>
          </Row>

          <hr />

          <div className="has-danger">
            <div className="form-control-feedback">
              {this.state.errors.fatal}
            </div>
          </div>

          <Row>
            <button type="submit" className="btn btn-primary">Ingest</button>
            &nbsp;
            <a
              href={Query.getRedirectUrl(`/films/${this.props.id}/edit`)}
              className="btn btn-danger"
            >Cancel</a>

            <div className={
              (this.state.errors._ || []).length > 0 ? 'has-danger' : ''
            }>
              <div className="form-control-feedback">
                {(this.state.errors._ || []).join('; ')}
              </div>
            </div>
          </Row>
        </form>
      </Container>
    );
  }

}
