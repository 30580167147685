import React from 'react';

import Checkbox from 'components/Forms/Checkbox';
import FormService from 'services/Form';
import I18n from 'services/I18n';
import Api from 'services/Api';
import Query from 'services/Query';

import PlaylistsUtil from '../Playlists';

export default class Playlists extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tab: 'cinema',
    };

    this.api = new Api();

    this.form = new FormService(this, {
      onSetState: (state) => {
        this.props.onChange(state.playlists);
      },
    });
  }

  componentWillReceiveProps(props) {
    this.setState({playlists: props.playlists});
  }

  render() {
    if(!this.props.playlists) {
      return null;
    }

    this.state.playlists.forEach((playlist, index) => {
      playlist.index = index;
    });

    let cinema = this.filter(this.state.playlists, 'cinema');
    let webfoyer = this.filter(this.state.playlists, 'webfoyer');
    let vod = this.filter(this.state.playlists, 'vod');

    let currentTab = this.state.tab;
    if(currentTab == 'cinema' && cinema.length == 0) {
      currentTab = 'webfoyer';
    }

    if(currentTab == 'webfoyer' && webfoyer.length == 0) {
      currentTab = 'vod';
    }

    const ingestMovietransit = (filmId) => {
      this.api.get('/films/' + filmId + '/movietransit_ingest', {}).then((response) => {
        Query.redirect('/films/' + filmId)
      });
    }

    return (
      <div>
        {this.renderSwitcher(currentTab, cinema, webfoyer, vod)}

        <div className="tab-content">
          {this.renderTab(currentTab, 'cinema', cinema)}
          {this.renderTab(currentTab, 'webfoyer', webfoyer)}
          {this.renderTab(currentTab, 'vod', vod)}
        </div>

        {
          this.props.user.role == 'admin' ?
            (
              <section
                className="section-moviedetail-assets container-fluid"
                style={{marginTop: '-24px'}}
              >
                <a
                  href={'/films/' + this.props.film.id + '/playlists/create'}
                  className="btn btn-primary"
                >Create playlist</a>

                &nbsp;

                <a
                  href={'/films/' + this.props.film.id + '/ingest'}
                  className="btn btn-primary"
                >Ingest</a>

                &nbsp;

                <button
                  onClick={() => ingestMovietransit(this.props.film.id)}
                  className="btn btn-primary"
                >Movietransit Ingest</button>
              </section>
            ) :
            null
        }
      </div>
    );
  }

  renderSwitcher(currentTab, cinema, webfoyer, vod) {
    return (
      <section className="section-moviedetail-tabs">
        <nav className="navbar navbar-full navbar-light">
          <div className="container-fluid row">
            <div className="col-xs-12">
              <ul className="nav navbar-nav">
                {cinema.length > 0 && (
                  <li className={
                    'nav-item ' + (currentTab == 'cinema' && 'active')
                  }>
                    <a
                      className="nav-link"
                      onClick={() => {
                        this.setState({tab: 'cinema'})
                      }}
                    >
                      Theatrical
                    </a>
                  </li>
                )}

                {webfoyer.length > 0 && (
                  <li className={
                    'nav-item ' + (currentTab == 'webfoyer' && 'active')
                  }>
                    <a
                      className="nav-link"
                      onClick={() => {
                        this.setState({tab: 'webfoyer'})
                      }}
                    >
                      Foyer
                    </a>
                  </li>
                )}

                {vod.length > 0 && (
                  <li className={
                    'nav-item ' + (currentTab == 'vod' && 'active')
                  }>
                    <a
                      className="nav-link"
                      onClick={() => {
                        this.setState({tab: 'vod'})
                      }}
                    >
                      Homevideo
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </section>
    );
  }

  filter(playlists, kind) {
    return playlists.filter((playlist) => {
      return playlist[kind];
    });
  }

  renderTab(currentTab, kind, ungrouppedPlaylists) {
    if(currentTab != kind) {
      return null;
    }

    let grouppedPlaylists = PlaylistsUtil.group(
      ungrouppedPlaylists,
      'playlist_title',
    );

    return (
      <div className="tab-pane fade active in">
        <section className="section-moviedetail-assets container-fluid">
          <table className="film-edit tablesaw tablesaw-stack table">
            {grouppedPlaylists.map(([title, playlists]) => {
              return [
                <thead key={'head-' + title}>
                  <tr>
                    <th>{title}</th>
                    {kind == 'cinema' && <th>preload</th>}
                    {kind == 'webfoyer' && <th>preferred</th>}
                    <th>DE</th>
                    <th>FR</th>
                    <th>IT</th>
                    <th>Hide</th>
                    {kind != 'vod' && <th>public</th>}

                    {/* if current_user.admin? */ }
                    {kind == 'cinema' && <th>invoice</th>}
                  </tr>
                </thead>,
                this.renderBody(kind, playlists),
              ];
            })}
          </table>
        </section>
      </div>
    );
  }

  renderBody(kind, ungrouppedPlaylists) {
    let grouppedPlaylists = PlaylistsUtil.group(
      ungrouppedPlaylists,
      'filename',
    );

    return grouppedPlaylists.map(([filename, playlists]) => {
      return (
        <Body
          key={filename}
          playlists={playlists}
          kind={kind}
          form={this.form}
          user={this.props.user}
        />
      );
    });
  }

}

class Body extends React.Component {

  shouldComponentUpdate(nextProps) {
    let result = false;
    nextProps.playlists.forEach((playlist, index) => {
      if(playlist !== this.props.playlists[index]) {
        result = true;
      }
    });

    return result;
  }

  render() {
    return (
      <tbody className={
        'playlist_types ' +
          (this.props.playlists.length > 1 && 'table-body--assetgroup')
      }>
        {this.props.playlists.map((playlist) => {
          let reference = 'theatrical_type';
          if(this.props.kind == 'vod') {
            reference = 'homevideo_type'
          }

          let type = playlist[reference];
          let prefix = `playlists.${playlist.index}.${reference}`;

          return (
            <tr key={playlist.id}>
              <td className="playlist">
                <a
                  className="title grey"
                  href={
                    this.props.user.role == 'admin' ?
                      '/films/' +
                        playlist.film_id +
                        '/playlists/' +
                        playlist.id :
                      null
                  }
                  data-state={JSON.stringify({
                    film: this.props.film,
                    playlist: playlist,
                  })}
                >
                  {PlaylistsUtil.renderTitle(this.props.kind, playlist)}
                </a>
              </td>

              {this.props.kind == 'cinema' && (
                <td>
                  <Checkbox
                    checked={type.preload}
                    onChange={this.props.form.setter(`${prefix}.preload`)}
                    disabled={this.props.disabled}
                    errors={this.props.form.get(`errors.${prefix}.preload`)}
                    dark={true}
                  />
                </td>
              )}

              {this.props.kind == 'webfoyer' && (
                <td>
                  <Checkbox
                    checked={type.prioritized}
                    onChange={
                      this.props.form.setter(`${prefix}.prioritized`)
                    }
                    disabled={this.props.disabled}
                    errors={
                      this.props.form.get(`errors.${prefix}.prioritized`)
                    }
                    dark={true}
                  />
                </td>
              )}

              <td>
                <Checkbox
                  trueValue="1"
                  falseValue="0"
                  checked={type.region.de == '1'}
                  onChange={this.props.form.setter(`${prefix}.region.de`)}
                  disabled={this.props.disabled}
                  errors={this.props.form.get(`errors.${prefix}.region.de`)}
                  dark={true}
                />
              </td>

              <td>
                <Checkbox
                  trueValue="1"
                  falseValue="0"
                  checked={type.region.fr == '1'}
                  onChange={this.props.form.setter(`${prefix}.region.fr`)}
                  disabled={this.props.disabled}
                  errors={this.props.form.get(`errors.${prefix}.region.fr`)}
                  dark={true}
                />
              </td>

              <td>
                <Checkbox
                  trueValue="1"
                  falseValue="0"
                  checked={type.region.it == '1'}
                  onChange={this.props.form.setter(`${prefix}.region.it`)}
                  disabled={this.props.disabled}
                  errors={this.props.form.get(`errors.${prefix}.region.it`)}
                  dark={true}
                />
              </td>

              <td>
                <Checkbox
                  checked={type.hidden}
                  onChange={this.props.form.setter(`${prefix}.hidden`)}
                  disabled={this.props.disabled}
                  errors={this.props.form.get(`errors.${prefix}.hidden`)}
                  dark={true}
                />
              </td>

              {this.props.kind != 'vod' && (
                <td>
                  <Checkbox
                    checked={type.public}
                    onChange={this.props.form.setter(`${prefix}.public`)}
                    disabled={this.props.disabled}
                    errors={this.props.form.get(`errors.${prefix}.public`)}
                    dark={true}
                  />
                </td>
              )}

              {this.props.kind == 'cinema' && (
                <td>
                  <div className="invoice_col">
                    {I18n.date(playlist.last_invoice_date)}
                  </div>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    );
  }

}
