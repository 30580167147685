import React from 'react';
import axios from 'axios';

import App from 'components/App';

export default class FormApi {

  static processError(error) {
    App.instance.setState({
      footer: false,
      error: error,
      message: (
        <section className="section" style={{marginBottom: '25px'}}>
          <p className="text-danger">
            Request failed; please, try again or contact our support:&nbsp;
            <a href="mailto:service@diagonal.com">service@diagonal.com</a>.
            <br /><br />
          </p>

          {error.response && error.response.data && (
            <code>
              {error.response && JSON.stringify(error.response.data)}
            </code>
          )}
        </section>
      ),
    });
  }

  constructor(component) {
    this.cancels = [];
    this.component = component;
    this.error = this.error.bind(this);
    this.bindCancels();
  }

  bindCancels() {
    let oldUnmount = this.component.componentWillUnmount;
    this.component.componentWillUnmount = () => {
      this.cancels.forEach((cancel) => {
        cancel();
      });

      if(oldUnmount) {
        oldUnmount.call(this.component);
      }
    }
  }

  submit(method, url, data, options = {}) {
    if(!options.noLoading) {
      this.component.setState({errors: {}, loading: true});
    }

    let cancelled = false;
    options = this.appendCancel(options, () => {
      cancelled = true;
    });

    let result =
      this
        .component
        .props
        .api[method](url, data, options.axios)
        .then((response) => {
          if(!options.noLoading) {
            this.component.setState({loading: false});
          }

          if(!options.noFooter) {
            App.instance.setState({footer: true});
          }

          return response;
        })
        .catch((error) => {
          if(cancelled) {
            error.cancel = true;
            throw error;
          }

          if(!options.noLoading) {
            this.component.setState({loading: false});
          }

          if(!options.noFooter) {
            App.instance.setState({footer: true});
          }

          if(options.noErrorHandling) {
            throw error;
          }

          let data = error.response && error.response.data || {};
          if(data.error == 'Confirmation::Require') {
            this.component.setState({confirmationRequired: true});
            throw error;
          }

          if(data.errors && Object.keys(data.errors).length > 0) {
            this.component.setState({
              errors: {
                ...data.errors,
                fatal: data.message || (
                  'Error occured during request: check data you entered ' +
                    'and try again'
                ),
              },
            });

            throw error;
          }

          let message =
            'Failed to ' +
              (method === 'get' ? 'receive' : 'submit') +
              ' data';

          if(data.message) {
            if(data.error == 'PublicError') {
              message = data.message;
            } else {
              message += ': ' + data.message;
            }
          } else if(data.exception) {
            message += ' (' + data.exception + ')';
          } else {
            message += ': ' + error.toString();
          }

          this.component.setState({errors: {fatal: message}});
          throw error;
        });

    return result;
  }

  get(url, params, options = {}) {
    return this.submit('get', url, params, options);
  }

  post(url, data, options = {}) {
    return this.submit('post', url, data, options);
  }

  put(url, data, options = {}) {
    return this.submit('put', url, data, options);
  }

  delete(url, params, options = {}) {
    return this.submit('delete', url, params, options);
  }

  error(error) {
    return FormApi.processError(error);
  }

  receive(url, params, options = {}) {
    return new Promise((resolve, reject) => {
      this
        .submit('get', url, params, {noErrorHandling: true, ...options})
        .then((response) => {
          this.component.setState(
            {...response.data, ready: true},
            resolve.bind(null, response),
          );
        })
        .catch((error) => {
          if(error.cancel) {
            return ;
          }

          FormApi.processError(error);
          reject(error);
        });
    });
  }

  appendCancel(options, callback) {
    if(!options.axios) {
      options.axios = {};
    }

    if(options.axios.cancelToken) {
      return ;
    }

    let cancel;
    options.axios.cancelToken = new axios.CancelToken((cancelExecutor) => {
      cancel = cancelExecutor;
    });

    this.cancels.push(() => {
      callback();
      cancel();
    });

    return options;
  }

}
