import React from 'react';

import Container from 'components/Forms/Container';
import Errors from 'components/Forms/Errors';
import Row from 'components/Forms/Row';

import Form from 'services/Form';

export default class DeleteDcps extends React.Component {

  constructor(props) {
    super(props);

    this.form = new Form(this);
    this.state = {
      cleanup: {},
      errors: {},
    };

    this.send = this.send.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  load() {
    this.form.api.receive('/playlists/delete_dcps');
  }

  redirect() {
    this.props.history.push('/films/' + this.state.playlist.film_id + '/edit');
  }

  send(event) {
    event.preventDefault();

    let message =
      'Are you sure you want to delete these DCPs from dalco? The action ' +
        'is irreversible.';

    if(!confirm(message)) {
      return ;
    }

    this
      .form
      .api
      .post('/playlists/delete_dcps', this.state.cleanup)
      .then(() => {
        alert('DCPs removal was scheduled');
        this.setState({cleanup: {}, confirmationRequired: false});
      });
  }

  render() {
    return (
      <Container title="Delete DCPs">
        <form onSubmit={this.send}>
          {this.form.fields.select('cleanup.archive_disk_id', 'Archived To', {
            blank: 'none',
            valuesObjects: this.state.archive_disks,
          })}

          {this.form.fields.textarea('cleanup.dcps', 'DCPs', {
            element: {
              rows: 25,
              onChange: this.form.callback('cleanup.dcps', (event) => {
                event.target.value =
                  event
                    .target
                    .value
                    .split('\n')
                    .map((line) => {
                      let result =
                        line
                          .split('/')
                          .slice(-1)[0]
                          .replace(/^\s+|\s+$/g, '');

                      return result;
                    })
                    .filter((line) => {
                      return line;
                    })
                    .join('\n');

                this.form.setter('cleanup.dcps')(event);
              }),
            },
            comment: (
              <div>
                <p>
                  DCPs filenames, one per line<br />
                  DCP can contain path that will be stripped
                </p>

                <p>
                  Example:
                </p>

                <pre>
                  /path/to/TITLE_FTR-Part1_F-177_RIS_20171113_DXL_IOP_VF<br/>
                  /path/to/TITLE_FTR-Part2_F-177_RIS_20170929_DXL_IOP_OV<br/>
                </pre>
              </div>
            ),
          })}

          {this.state.confirmationRequired && (
            this.form.fields.input(
              'cleanup.confirmation',
              'Confirmation Code',
              {
                comment: (
                  <p>
                    Check delete@diagonal.com for confirmation code
                  </p>
                ),
              },
            )
          )}

          <Row title="">
            <Errors errors={this.state.errors.fatal} />
            <button type="submit" className="btn btn-danger">Delete</button>
            &nbsp;

            <a href="/" className="btn btn-default">Cancel</a>
          </Row>
        </form>
      </Container>
    );
  }

}
