import React from 'react';

import FormService from 'services/Form';
import Query from 'services/Query';

import Container from 'components/Forms/Container';

export default class Edit extends React.Component {

  constructor(props) {
    super(props);

    this.form = new FormService(this);
    this.save = this.save.bind(this);

    if(!this.redirect) {
      this.redirect =
        Query.getRedirectCallback(this.constructor.url).bind(this);
    }

    this.state = {
      errors: {},
      [this.constructor.key]: this.props[this.constructor.key] || {},
    };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    let url = this.constructor.url + '/' + this.props.id + '/edit';
    this.form.api.receive(url);
  }

  save(data) {
    this.setState({[this.constructor.key]: data});
    let url = this.constructor.url + '/' + this.state[this.constructor.key].id;
    let promise = this.form.api.post(url, data).then(this.redirect);
    return promise;
  }

  render() {
    return (
      <Container title={
        'Edit ' +
          this.constructor.key.replace(/_/g, ' ') +
          ' #' + this.props.id +
          (
            this.state[this.constructor.key].name ?
              ' ' +
                this.state[this.constructor.key].name :
              ''
          )
      }>
        {this.renderBody()}
      </Container>
    );
  }

  renderBody() {
    throw new Error('not implemented');
  }

}
