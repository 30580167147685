import React from 'react';

import CreateComponent from 'components/Forms/Create';

import Query from 'services/Query';

import Form from './Form';

export default class Create extends CreateComponent {

  static key = 'screen';
  static url = '/screens';

  constructor(props) {
    super(props);
    this.redirect =
      Query
        .getRedirectCallback(
          '/organizations/' + this.props.organizationId + '/screens',
        )
        .bind(this);
  }

  save(data) {
    data.organization_id = this.props.organizationId;
    return super.save(data);
  }

  load() {
    this.setState({
      screen: {
        certificates: [],
      },
    });
  }

  renderBody() {
    return (
      <Form
        {...this.state}
        save={this.save}
      />
    );
  }
}
