import React from 'react';
import Waypoint from 'react-waypoint';

import App from 'components/App';
import Navigation from 'components/App/Navigation';
import Container from 'components/Forms/List';
import SortLink from 'components/Forms/SortLink';

import Form from 'services/Form';
import Query from 'services/Query';

export default class List extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      query: Query.get('query'),
      total: 0,
      users: [],
    };

    this.loading = false;
    this.form = new Form(this);
    this.more = this.more.bind(this);
    this.search = this.search.bind(this);
    this.setQuery = this.setQuery.bind(this);
    this.sort = this.sort.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  load(append = false) {
    if(this.loading) {
      return ;
    }

    this.loading = true;
    let previousUsers = this.state.users;

    Query.set('query', this.state.query);
    Query.set('order', this.state.order);
    Query.set('direction', this.state.direction);

    this
      .form
      .api
      .get('/users', {
        page: this.state.page,
        query: this.state.query,
        order: this.state.order || 'name',
        direction: this.state.direction || 'asc',
      })
      .then((response) => {
        this.loading = false;
        let users = response.data.users;
        if(append) {
          users = previousUsers.concat(users);
        }

        this.setState({
          ...response.data,
          users: users,
        });
      });
  }

  more() {
    if(this.state.page >= this.state.total - 1) {
      return ;
    }

    this.setState({page: this.state.page + 1});
    this.load(true);
  }

  search(event) {
    event.preventDefault();
    this.setState({page: 0}, () => {
      this.load();
    });
  }

  setQuery(event) {
    this.setState({query: event.target.value});
  }

  sort(order, direction) {
    this.setState({order: order, direction: direction}, this.load);
  }

  render() {
    return (
      <Container>
        {this.renderHeader()}
        <table className="table">
          <thead>
            <tr>
              <th>
                <SortLink
                  order="name"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >Name</SortLink>
              </th>

              <th>
                <SortLink
                  order="email"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >Email</SortLink>
              </th>

              <th>
                <SortLink
                  order="organizations"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >Organizations</SortLink>
              </th>

              <th>
                <SortLink
                  order="role_cd"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >Role</SortLink>
              </th>

              <th>
                <SortLink
                  order="region_cd"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >Region</SortLink>
              </th>

              <th>
                <SortLink
                  order="transfers"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >Transfers</SortLink>
              </th>

              <th>
                <SortLink
                  order="activated"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >Active</SortLink>
              </th>

              <th>
                <SortLink
                  order="is_notifications_active"
                  current={this.state.order}
                  direction={this.state.direction}
                  sort={this.sort}
                >Notifications</SortLink>
              </th>

              <th>
                Login
              </th>
            </tr>
          </thead>

          <tbody>
            {this.renderUsers(this.state.users)}
            <tr>
              <td>
                <Waypoint
                  scrollableAncestor={
                    document.getElementsByClassName('content')[0]
                  }
                  onEnter={this.more}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </Container>
    );
  }

  renderHeader() {
    return (
      <div className="row">
        <div className="col-sm-6"><h1>Users</h1></div>
        <div className="col-sm-6">
          <form onSubmit={this.search}>
            <div className="input-group">
              <input
                type="search"
                className="form-control users__query"
                value={Form.value(this.state.query)}
                onChange={this.setQuery}
                placeholder="Search..."
              />

              <span className="input-group-btn">
                <button type="submit" className="btn btn-primary">
                  <span className="fa fa-search"></span>
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    );
  }

  login(user) {
    localStorage.clear();

    this.props.api.post('/session/' + user.id).then((response) => {
      this.props.api.setToken(response.data.token);
      App.instance.setState({user: response.data.user});

      this.props.history.push(
        Navigation.getFilmsUrl('/', response.data.user),
      );
    });
  }

  renderUsers(users) {
    return users.map((user) => {
      return (
        <tr key={user.id} className="users__item">
          <td className="users__name">
            <a
              href={'/users/' + user.id + '?redirect=' + Query.getEncodedUrl()}
              data-state={JSON.stringify({user: user})}
            >{user.name || '—'}</a>
          </td>
          <td className="users__email">
            <a
              href={'/users/' + user.id + '?redirect=' + Query.getEncodedUrl()}
              data-state={JSON.stringify({user: user})}
            >{user.email}</a>
          </td>
          <td className="users__organization">
            {this.renderOrganizations(user)}
          </td>
          <td className="users__role">
            {user.role}
          </td>
          <td className="users__region">
            {user.region}
          </td>
          <td className="users__transfers">
            <a href="/transfers#?user_id={user.id}">
              {user.transfers_count}
            </a>
          </td>
          <td className="users__active">
            <i className={
              'icon icon-lg fa fa-fw ' + (
                user.activated ?
                  'fa-check icon-users--active' :
                  'fa-minus icon-users--notactive'
              )
            } />
          </td>
          <td className="users__is-notifications-active">
            <i className={
              'icon icon-lg fa fa-fw ' + (
                user.is_notifications_active ?
                  'fa-check icon-users--active' :
                  'fa-minus icon-users--notactive'
              )
            } />
          </td>
          <td>
            <a
              href="#"
              className="btn btn-sm btn-primary"
              onClick={this.login.bind(this, user)}
            >
              <span className="fa fa-sign-in"></span>
            </a>
          </td>
        </tr>
      );
    });
  }

  renderOrganizations(user) {
    if(!user.organizations) {
      return ;
    }

    var organizationsLastIndex = user.organizations.length - 1;
    return user.organizations.map((organization, index) => {
      return (
        <span
          key={organization.id}
          className="users__organization-name"
        >
          <a href={
            '/organizations/' +
              organization.id +
              '?redirect=' +
              Query.getEncodedUrl()
          }>
            {organization.name}
          </a>{index != organizationsLastIndex && ', '}
        </span>
      );
    });
  }

}
