import React from 'react';
import Modal from 'react-modal';
import _ from 'underscore';

import Spinner from 'components/Spinner';

import ModalService from 'services/Modal';

export default class ConfirmModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      disableButtons: false,
    }

    this.classNameForDCP = this.classNameForDCP.bind(this);

    this.onClickCancel = this.onClickCancel.bind(this);
    this.onClickConfirm = this.onClickConfirm.bind(this);
  }

  componentWillUpdate() {
    this.state.disableButtons = false;
  }

  classNameForDCP = (dcp) => {
    const classes = ['storage-table__cpl'];

    if ((dcp.cpl_titles || []).length > 1) {
      classes.push('storage-table__cpl--group');
    }

    return classes.join(' ');
  }

  onClickCancel() {
    if (!this.state.disableButtons) {
      this.setState({ disableButtons: true });

      this.props.onCancel();
    }
  }

  onClickConfirm() {
    if (!this.state.disableButtons) {
      this.setState({ disableButtons: true });

      this.props.onConfirm(
        _.sortBy(_.flatten(this.props.dcps.map((dcp) => dcp.playlist_ids)))
      )
    }
  }

  render () {
    return (
      <Modal
        appElement={ document.getElementById('app') }
        isOpen={ this.props.open }
        style={{
          ...ModalService.STYLE,
          content: { ...ModalService.STYLE.content, maxWidth: '1200px' },
        }}
      >
        <div>
          <p>{ this.props.title }</p>

          {
            !!this.props.dcps.length &&
              <table className="table" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>TITLE</th>
                    <th>CPL</th>
                  </tr>
                </thead>

                <tbody>
                  {
                    this.props.dcps.map((dcp) =>
                      <tr key={ dcp.filename } className={ this.classNameForDCP(dcp) }>
                        <td>
                          <a href={ `/films/${dcp.film_id}` }>{ dcp.film_title }</a>
                        </td>

                        <td className="storage-table__cpls">
                          { (dcp.cpl_titles || []).map((title, i) => <p key={ `${i}-${title}` }>{ title }</p>) }
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
          }

          <div className='storage__modal__footer p-relative'>
            <Spinner visible={ this.state.disableButtons } className='storage__modal__spinner' />

            <div>
              <button
                className="storage__modal__btn btn btn-primary text-white ml-2 pull-right"
                disabled={ this.state.disableButtons }
                onClick={ this.onClickConfirm }>
                { this.props.confirmText || 'YES' }
              </button>

              <button
                className="storage__modal__btn btn text-white pull-right"
                disabled={ this.state.disableButtons }
                onClick={ this.onClickCancel }>
                { this.props.cancelText || 'NO' }
              </button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
