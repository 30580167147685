import I18n from 'services/I18n';

export default class Films {

  static localePriorities = {
    en: 1,
    de: 2,
    fr: 3,
    it: 4,
  }

  static getLocalTitle(film) {
    let region = film.regions.find((region) => {
      return I18n.getLocale() == region.locale;
    });

    if(!region || !region.title) {
      region =
        film
          .regions
          .sort((firstRegion, secondRegion) => {
            let firstPriority = this.localePriorities[firstRegion];
            let secondPriority = this.localePriorities[secondRegion];

            if(firstPriority < secondPriority) {
              return -1;
            }

            if(firstPriority > secondPriority) {
              return 1;
            }

            return 0;
          })
          .find((region) => {
            return !!region.title;
          })
    }

    if(!region) {
      return null;
    }

    return region.title;
  }

  static getFullTitle(film) {
    let parts = [];
    film.regions.forEach((region) => {
      if(!region.title) {
        return ;
      }

      parts.push(region.title);
    });

    return [...new Set(parts)].join('\n');
  }

  static getImage(film, forceRetina = false) {
    let image = null;

    film.regions.forEach((region) => {
      if(I18n.getLocale() == region.locale) {
        if (region.image && region.image.url) {
          image = region.image;
        } else {
          image = region.lowres_image;
        }
      }
    });

    if (!image) { image = {}; }

    const isRetina = forceRetina || (window.devicePixelRatio > 1 && image.retina_thumb && image.retina_thumb.url);

    if (isRetina) { return image.retina_thumb.url; }
    if (image.thumb && image.thumb.url) { return image.thumb.url; }

    return null;
  }

}
