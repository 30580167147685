import React from 'react';
import I18n from 'services/I18n';

class Footer extends React.Component {

  render() {
    const t = I18n.namespace('footer');

    return (
      <footer className="section-footer">
        <div className="container-fluid">
          <h1
            className="footer-headline"
            dangerouslySetInnerHTML={{__html: t('text')}}
          ></h1>
          <p
            className="footer-lead"
            dangerouslySetInnerHTML={{__html: t('lead')}}
          ></p>
        </div>
      </footer>
    );
  }
}

export default Footer;
