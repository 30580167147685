import React from 'react';

import App from 'components/App';
import Errors from 'components/Forms/Errors.jsx';
import Row from 'components/Forms/Row.jsx';

import FormService from 'services/Form';
import I18n from 'services/I18n';

import Form from './Deliver/Form';
import Help from './Deliver/Help';
import Input from './Deliver/Input';
import Cities from './Deliver/Cities';

const t = I18n.namespace('deliver');

export default class Deliver extends React.Component {

  constructor(props) {
    super(props)
    this.state = this.getDefaultState();

    this.form = new FormService(this);
    this.set = this.set.bind(this);
    this.submit = this.submit.bind(this);
  }

  getDefaultState() {
    return {
      playlist: null,
      delivery: {
        kdms: [],
        playlists: [],
      },
      errors: {},
    };
  }

  componentDidMount() {
    this.load(this.props.filmId, this.props.id);
  }

  componentWillReceiveProps(props) {
    this.load(props.filmId, props.id);
  }

  load(filmId, playlistId) {
    if (this.playlistId == playlistId) {
      return ;
    }

    this.playlistId = playlistId;
    this.filmId = filmId;

    App.instance.setState({footer: false});
    this.form.api.receive('/playlists/' + playlistId + '/deliver?film_id='+filmId).then((result) => {
      App.instance.setState({footer: true});
      return result;
    });
  }

  submit(event) {
    event.preventDefault();

    this.form.api.post('/playlists/deliver', this.state.delivery)
      .then(() => {
        this.props.history.push('/films/' + this.state.playlist.film_id);
      });
  }

  set(delivery) {
    this.setState({
      delivery: {
        ...this.state.delivery,
        ...delivery,
      },
    });
  }

  render() {
    if(!this.state.playlist) {
      return null;
    }

    if(this.props.filmId != this.state.playlist.film_id) {
      return (
        <span>Playlist not found</span>
      );
    }

    return (
      <form onSubmit={this.submit}>
        <section className="section-breadcrumb container-fluid">
          <div className="row">
            <div className="col-xs-12">
              <a
                href={'/films/' + this.props.filmId}
                className="btn btn-link"
              >
                <i className="icon fa fa-chevron-left fa-fw"></i>
                <span>{t('back')}</span>
              </a>
              <a href="https://uniquex.com/movietransit-digital-cinema-package/" target="_blank" className="pull-right">
                <img src="/static/images/delivery-uniquex-mt.svg" />
              </a>
            </div>
          </div>
        </section>

        <Input
          {...this.props}
          playlist={this.state.playlist}
          playlists={this.state.playlists}
          reset={() => {
            this.setState(this.getDefaultState());
          }}
          setState={(state) => {
            this.setState({
              ...this.state,
              delivery: {
                ...this.state.delivery,
                playlists: state.playlists,
                kdms: state.kdms,
              },
              errors: {
                ...this.state.errors,
                playlists: null,
                kdms: null,
              },
            });
          }}
        />

        {(this.state.errors.playlists || this.state.errors.kdms) && (
          <section className="section-transfer container-fluid">
            <Row>
              <Errors errors={this.state.errors.playlists} />
              <Errors errors={this.state.errors.kdms} />
            </Row>
          </section>
        )}

        <section className="section-transfer container-fluid">
          <Cities
            api={this.props.api}
            playlist={this.state.playlist}
            cities={this.state.cities}
            setScreens={this.form.setter('delivery.screens')}
          />

          <Errors errors={this.state.errors.screens} />

          <div className="jumbotron">
            <Form
              kdms={this.state.delivery.kdms.length > 0}
              playlists={this.state.delivery.playlists.length > 0}
              film={this.state.film}
              distributors={this.state.distributors}
              errors={this.state.errors}
              set={this.set}
              setErrors={this.form.setter('errors')}
              currentUser={this.props.currentUser}
            />

            <Errors errors={this.state.errors.fatal} />

            <div className="form-group row">
              <div className="col-sm-offset-4 col-sm-8">
                <button
                  type="submit"
                  className="btn btn-primary kdm-submit-button"
                  disabled={this.state.loading}
                >
                  {t('order')}
                </button>
              </div>
            </div>
          </div>
        </section>

        <Help playlist={this.state.playlist} />
      </form>
    );
  }

}
