import React from 'react';

import Films from 'services/Films';
import I18n from 'services/I18n';
import Image from 'services/Image';

let jQuery = require('jquery');
const t = I18n.namespace('film');

export default class Header extends React.Component {

  constructor(props) {
    super(props);

    this.parallaxElement = null;
    this.initializeParallaxEffect = this.initializeParallaxEffect.bind(this);
  }

  componentDidMount() {
    jQuery('.content').addClass('content--transparent');
  }

  componentWillUnmount() {
    jQuery('.content').removeClass('content--transparent');
    if(this.parallaxElement) {
      try {
        jQuery(this.parallaxElement).parallax('destroy');
      } catch(error) {
        // ignore error
      }
    }
  }

  render() {
    let regions = this.getFilmRegions(this.props.film);

    let imageSrc = this.getFilmCover(regions);
    Image.load(imageSrc).then(() => {
      window.setTimeout(
        () => {
          jQuery('.parallax-mirror').css({top: '32px'});
        },
        1000,
      );
    });

    return (
      <section className="section section-moviedetail">
        <div
          className="section-moviedetail-header parallax-window"
          data-image-src={imageSrc}
          data-z-index="1"
          data-speed="-0.5"
          ref={this.initializeParallaxEffect}
        >
          <div className="section-moviedetail-content">
            <div className="section-moviedetail-title">
              <div className="container">
                {this.renderHeader(regions)}
              </div>
            </div>
            <div className="section-moviedetail-description">
              <div className="container">
                {this.renderDescription(regions)}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  initializeParallaxEffect(element) {
    if(!element) {
      return ;
    }

    this.parallaxElement = element;
    jQuery(element).parallax({boxOffsetTop: 32});
    jQuery(window).trigger('resize').trigger('scroll');
    jQuery('.parallax-mirror').css({top: '32px'});

    Image.load(jQuery('.parallax-window').data('image-src')).then(() => {
      jQuery('.parallax-slider').addClass('parallax-slider-loaded');
    });
  }

  getFilmCover(regions) {
    const getBlurredUrl = (image) => {
      if(!image) { return null; }
      if(!image.blurred) { return null; }
      if(!image.blurred.url) { return null; }

      return image.blurred.url;
    }

    let cover = Header.getFilmRegionValue(regions, (region) =>
      getBlurredUrl(region.image) || getBlurredUrl(region.lowres_image)
    );

    if(!cover) {
      cover = '/static/images/cover.png';
    }

    return cover;
  }

  renderHeader(regions) {
    let title = Header.getFilmRegionValue(regions, (region) => {
      return region.title;
    });

    return (
      <div className="row">
        <div className="col-xs-12 col-md-9">
          <div className="row">
            <div className="col-xs-12">
              <h1 className="section-moviedetail-heading">
                {title}

                {this.props.user_can_edit_film && (
                  <small>
                    &nbsp;
                    <a
                      className="movie-edit"
                      href={
                        '/films/' + this.props.film.id + '/edit'
                      }
                      data-state={JSON.stringify({
                        film: this.props.film,
                        playlists: this.props.playlists,
                      })}
                    >
                      <i className="icon fa fa-pencil fa-fw"></i>
                    </a>
                  </small>
                )}
              </h1>
              <p className="section-moviedetail-subheading">
                {this.getFilmTitles(regions, title).map((title) => {
                  return <span key={title}>{title}<br /></span>;
                })}
              </p>
            </div>
          </div>
          <div className="movie-quickinfo row film__info">
            <div className="movie-quickinfo__item col-xs-6 col-md-4 col-lg-3">
              <h5 className="list-group-item-heading">
                {
                  (this.props.film.film_genres &&
                    this.props.film.film_genres.count > 1) ?
                    t('genres') :
                    t('genre')
                }
              </h5>
              <p className="list-group-item-text">
                {
                  this.props.film.film_genres &&
                    this.props.film.film_genres.map((genre) => {
                      return genre.genre;
                    })
                }
                &nbsp;
              </p>
            </div>
            <div className="movie-quickinfo__item col-xs-6 col-md-4 col-lg-3">
              <h5 className="list-group-item-heading">
                {
                  this.props.film.countries &&
                    this.props.film.countries.count > 1 ?
                    t('countries') :
                    t('country')
                }
              </h5>
              <p className="list-group-item-text">
                {
                  this.props.film.countries &&
                    this.props.film.countries.map((country) => {
                      return country[I18n.getLocale()];
                    })
                    .join(', ')
                }
                &nbsp;
              </p>
            </div>
            <div className="movie-quickinfo__item col-xs-6 col-md-4 col-lg-3">
              <h5 className="list-group-item-heading">{t('year')}</h5>
              <p className="list-group-item-text">
                {this.props.film.year}
                &nbsp;
              </p>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-3">
          <div className="logo logo-md">
            {
              this.props.film.organization &&
                this.props.film.organization.logo &&
                this.props.film.organization.logo.url &&
                <img
                  src={this.props.film.organization.logo.url}
                  alt={this.props.film.organization.name}
                  className="film__distributor-logo"
                />
            }
          </div>
        </div>
      </div>
    );
  }

  static getFilmRegionValue(regions, callback) {
    let region = Header.findRegionByLocale(regions, I18n.getLocale());
    let result = callback(region);
    if(result) {
      return result;
    }

    for(region of regions) {
      let result = callback(region);
      if(result) {
        return result;
      }
    }

    return null;
  }

  getFilmRegions(film) {
    let order = ['en', 'de', 'fr', 'it'];

    let regions = order.map((locale) => {
      return film.regions.find((region) => {
        return region.locale == locale;
      });
    });

    return regions;
  }

  getFilmTitles(regions, originalTitle) {
    let titles = [];
    regions.forEach((region) => {
      if(!region.title) {
        return ;
      }

      if(region.title == originalTitle) {
        return ;
      }

      if(titles.indexOf(region.title) === -1) {
        titles.push(region.title);
      }
    });

    return titles;
  }

  renderDescription(regions) {
    let description = Header.getFilmRegionValue(regions, (region) => {
      return region.description;
    })

    if(description) {
      description = description.replace(/<br \/>/g, '');
    }

    return (
      <div className="row">
        <div className="col-xs-12 col-md-3">
          <img
            src="/static/images/cover.png"
            className="
              cover-image
              section-moviedetail-poster
              section-moviedetail-poster--empty
              img-fluid
            "
            ref={(image) => {
              let url = Films.getImage(this.props.film, true);

              if (!url || !image) {
                return;
              }

              Image.loadTo(url, image).then(() => {
                image.classList.remove('section-moviedetail-poster--empty');
              });
            }}
          />
        </div>

        <div className="movie-description col-xs-12 col-md-7">
          <div
            className="movie-excerpt js_article"
            ref={description && this.initializeDescription}
          >
            {(description || '').split('\n').map((part, index) => {
              return <p key={index}>{part}</p>
            })}

            <a href="#" className="readless hidden">
              <i className="icon fa fa-chevron-up fa-fw"></i>
              {t('collapse_description')}
            </a>
          </div>
        </div>

        <div className="movie-info col-xs-12 col-md-2">
          <ul className="list-group">
            {this.renderReleaseDates()}

            {this.props.film.suisa_number && (
              <li className="list-group-item">
                <h5 className="list-group-item-heading">
                  {t('more')}
                </h5>
                <p className="list-group-item-text">
                  <a
                    target="_blank"
                    href={
                      'http://www.tmdb.pro/downloadcenter.php?suisa=' +
                        this.props.film.suisa_number
                    }
                  >
                    TMDb.pro
                  </a>
                </p>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }

  renderReleaseDates() {
    let locales = ['de', 'fr', 'it'];
    if(I18n.getLocale() != 'en') {
      locales = [I18n.getLocale()];
    }

    return locales.map((locale) => {
      let region = Header.findRegionByLocale(this.props.film.regions, locale);

      return (
        <li key={locale} className="list-group-item">
          <h5 className="list-group-item-heading">
            {t(locale.substr(0, 1).toUpperCase() + '-CH')}
          </h5>
          <p className="list-group-item-text">
            {
              region.is_no_release ?
                t('no_release') :
                (
                  region.release_at ?
                    I18n.date(region.release_at) :
                    'tba'
                )
            }
          </p>
        </li>
      );
    });
  }

  static findRegionByLocale(regions, locale) {
    return regions.find((localRegion) => {
      return localRegion.locale == locale;
    });
  }

  initializeDescription() {
    let box = jQuery('.movie-excerpt');
    if(box.length === 0) {
      return ;
    }

    let more = jQuery(`
      <a href="#" class="readmore">
        <i class="icon fa fa-chevron-down fa-fw"></i>
        ${t('expand_description')}
      </a>
    `)

    let less = box.find('a.readless');

    box.css({'max-height': 'inherit', overflow: 'visible'});

    let dotdotdot = function(){
      box.dotdotdot({
        height: 300,
        watch: true,
        after: more,
        callback: function(isTruncated) {
          if(!isTruncated) {
            box.trigger('destroy.dot');
            jQuery(window).trigger('resize').trigger('scroll');
            return ;
          }

          more.show();
          less.removeClass('hidden')
        },
      });
    }

    more.click(function(event){
      event.preventDefault();
      box.trigger('destroy.dot');
      jQuery(this).hide();
      jQuery(window).trigger('resize').trigger('scroll');
    });

    less.click(function(event){
      event.preventDefault();
      dotdotdot();

      window.setTimeout(
        () => {
          jQuery(window).trigger('resize').trigger('scroll');
        },
        500,
      );
    });

    dotdotdot();
  }
}
