import React from 'react';

export default class ContentKindCheckbox extends React.Component {

  render() {
    let value = (this.props.value || '').split(',');
    let actual = (this.props.actual || '').split(',');

    return (
      <input
        type="checkbox"
        className="custom-control-input"
        checked={
          value.every((contentKind) => {
            return actual.indexOf(contentKind) !== -1;
          })
        }
        onChange={(event) => {
          let newValue = [...actual];
          value.forEach((element) => {
            let index = newValue.indexOf(element);
            if(index === -1) {
              return ;
            }

            newValue.splice(index, 1)
          });

          if(event.target.checked) {
            newValue = [...newValue, ...value];
          }

          newValue = newValue.filter((value) => {
            return !!value;
          })

          this.props.onChange(newValue.join(','));
        }}
      />
    );
  }

}
