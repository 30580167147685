const $ = require('jquery');

import React from 'react';

import Select from 'components/Forms/Select';
import Select2 from 'react-select2-wrapper';

import Errors from './Errors';

export default class Dropdown extends React.PureComponent {

  constructor(props) {
    super(props);
    this.change = this.change.bind(this);
    this.oldValue = props.value;
    // this.isFirstChangeEventFired = false;
    // this.isSecondChangeEventFired = false;
  }

  change(event) {
    // let this stay for a while...

    // if(!this.isFirstChangeEventFired) {
    //   this.isFirstChangeEventFired = true;
    //   return ;
    // }

    // if(!this.isSecondChangeEventFired) {
    //   this.isSecondChangeEventFired = true;
    //   return ;
    // }

    let value = $(event.target).val();

    if(this.props.multiple !== false) {
      if(value) {
        value = value.map((rawValue) => {
          return JSON.parse(rawValue);
        });
      } else {
        value = [];
      }
    }

    // prevent excess change events
    if(JSON.stringify(this.oldValue || []) == JSON.stringify(value || [])) {
      return ;
    }

    this.oldValue = value;
    this.props.onChange(value);
  }

  render() {
    let data = null;
    let dataArray = Select.values(this.props)
    if(dataArray) {
      data = dataArray.map(([value, title]) => {
        return {id: JSON.stringify(value), text: title};
      });
    }

    let isMultiple = this.props.multiple !== false;
    let value = this.props.value;

    if(value && typeof value[0] === 'object') {
      value = value.map((current) => {
        return JSON.stringify(current);
      });
    }

    return (
      <Errors errors={this.props.errors}>
        <Select2
          multiple={isMultiple}
          data={data}
          onChange={this.change}
          disabled={this.props.disabled}
          value={value}
          options={this.props.options}
          {...this.props.element}
        />

        <div className="form-control-feedback">
          {(this.props.errors || []).join('; ')}
        </div>
      </Errors>
    );
  }

}
