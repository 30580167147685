import React from 'react';
import Flickity from 'flickity';

import Form from 'services/Form';
import I18n from 'services/I18n';

const $ = require('jquery');

export default class Footer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
    };

    this.form = new Form(this);
    this.showFlickity = this.showFlickity.bind(this);
    this.flickityOptions = {
      autoPlay: 3000,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      imagesLoaded: true,
      cellAlign: 'left',
    };
  }

  componentDidMount() {
    this.form.api.receive('/organizations/footer_list');
  }

  showFlickity(element) {
    if(!element) {
      return ;
    }

    new Flickity(element, {
      autoPlay: 3000,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      imagesLoaded: true,
      cellAlign: 'left',
    });

    $(element).css('opacity', 1);
  }

  render() {
    return (
      <div>
        <section className="login__slider">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-12">
                {this.state.organizations.length == 0 ? null : (
                  <div
                    className="carousel"
                    data-flickity='{  }'
                    ref={this.showFlickity}
                  >
                    {this.state.organizations.map((organization) => {
                      return (
                        <div className="carousel-cell" key={organization.id}>
                          <img
                            src={organization.logo && organization.logo.url}
                            alt={organization.name}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className="login__info">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <div
                  className="login__infobox"
                  dangerouslySetInnerHTML={{
                    __html: I18n.t('authentication.signup'),
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }

}
