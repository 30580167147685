import React from 'react';

import FormComponent from 'components/Forms/Form';

export default class Form extends FormComponent {

  static key = 'disk';
  static url = '/disks';

  renderBody() {
    return (
      <div>
        {this.form.fields.input('disk.name', 'Name',)}
        {this.form.fields.input('disk.size', 'Size (GB)',)}

        {this.form.fields.select('disk.disk_type', 'Type', {
          valuesArray: this.state.disk_types,
        })}
      </div>
    );
  }

}
