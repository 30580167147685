import React from 'react';

import Playlist from './Playlist';
import Playlists from '../Playlists';
import PlaylistsHeader from './PlaylistsHeader';
import PlaylistTitle from './PlaylistTitle';
import Transfers from './Transfers';
import Utilities from '../Utilities';

export default class Cinema extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      transfers: {},
      groups: Utilities.getVisibleIds(this.props.groups),
      playlists: [],
      loadedTransfers: [],
    };

    this.loadTransfers = this.loadTransfers.bind(this);
    this.setTransfers = this.setTransfers.bind(this);
    this.setGroups = this.setGroups.bind(this);
    this.setPlaylists = this.setPlaylists.bind(this);
  }

  loadTransfers(playlistId) {
    let found = null;
    Object.keys(this.props.groups).forEach((key) => {
      this.props.groups[key].forEach((playlist) => {
        if(playlist.id == playlistId) {
          found = playlist;
        }
      });
    });

    let query = {
      playlist_id: playlistId,
      limit: 500,
      locale: 'en',
      foyer:
        this.props.kind == 'webfoyer' &&
          !!found &&
          !!found.foyer,
     };

    return this.props.api.get('/transfers/list', query).then((response) => {
      this.setTransfers(playlistId, response.data.transfers);
    });
  }

  setTransfers(playlistId, transfers) {
    this.setState({
      loadedTransfers: [...this.state.loadedTransfers, playlistId],
      transfers: {
        ...this.state.transfers,
        [playlistId]: transfers,
      },
    });
  }

  render() {
    return (
      <table className="table">
        {this.renderContent()}
      </table>
    );
  }

  setGroups(value) {
    this.setState({groups: value});
  }

  renderContent() {
    let result =
      Object
        .keys(this.props.groups)
        .map((title) => {
          let playlists = this.props.groups[title];
          let lowestId = Utilities.getLowestId(playlists);
          let groups = this.state.groups;
          let isHidden = Utilities.isGroupHidden(groups, playlists, lowestId);

          let result = [
            <PlaylistsHeader
              group={lowestId}
              groups={groups}
              setGroups={this.setGroups}
              hidden={isHidden}
              kind={this.props.kind}
              playlists={playlists}
              title={title}
              key="header"
            />,
          ];

          if(!isHidden) {
            result.push(this.renderGroup(playlists));
          }

          return result;
        });

    return result;
  }

  renderGroup(group) {
    let result =
      Playlists
        .group(group, 'filename')
        .map(([filename, playlists]) => {
          if(!filename) {
            playlists.forEach((playlist) => {
              return this.renderPlaylists([playlist]);
            });
          }

          return this.renderPlaylists(playlists);
        });

    return result;
  }

  setPlaylists(playlists) {
    this.setState({playlists: playlists});
  }

  renderPlaylists(playlists) {
    let lowestPlaylistId = Utilities.getLowestId(playlists).toString();
    let expanded = this.state.playlists.includes(lowestPlaylistId);

    return [
      <tbody
        key={'content-' + playlists[0].id}
        className={
          'table-body--asset ' +
            (playlists.length > 1 && 'table-body--assetgroup')
        }
      >
        {playlists.map((playlist, index) => {
          return [
            <Playlist
              api={this.props.api}
              cinemas={this.props.cinemas}
              index={index}
              kind={this.props.kind}
              loadTransfers={this.loadTransfers}
              lowestPlaylistId={lowestPlaylistId}
              params={this.props.params}
              playlist={playlist}
              playlists={this.state.playlists}
              hasTransfers={this.state.loadedTransfers.includes(playlist.id)}
              setPlaylists={this.setPlaylists}
              user_can_deliver={this.props.user_can_deliver}
              user_can_order={this.props.user_can_order}
              user_can_see_transfers={this.props.user_can_see_transfers}
              user_can_manage_screeners={this.props.user_can_manage_screeners}
              key={'playlist-' + index}
            />,
            <PlaylistTitle
              playlist={playlist}
              kind={this.props.kind}
              hasTransfers={this.state.loadedTransfers.includes(playlist.id)}
              key={'playlist-title-' + index}
            />,
          ];
        })}
      </tbody>,
      (this.props.user_can_see_transfers && expanded && (
        <Transfers
          api={this.props.api}
          loadTransfers={this.loadTransfers}
          playlistId={lowestPlaylistId}
          playlists={playlists}
          kind={this.props.kind}
          setTransfers={this.setTransfers}
          transfers={this.state.transfers[lowestPlaylistId]}
          user_can_manage={this.props.user_can_manage}
          key="transfers"
        />
      )),
    ];
  }

}
