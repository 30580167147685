import React from 'react';

import FormComponent from 'components/Forms/Form';

export default class Form extends FormComponent {

  static key = 'target';
  static url = '/targets';

  renderBody() {
    return (
      <div>
        {this.form.fields.input('target.name', 'Name')}

        {this.form.fields.textarea(
          'target.conversion_params',
          'Conversion Parameters',
          {element: {rows: 10}},
        )}

        {this.form.fields.select(
          'target.transfer_method_cd',
          'Transfer Method',
          {values: this.state.transfer_methods}
        )}

        {(this.state.content_kinds || []).map((contentKind) => {
          return (
            <div key={contentKind}>
              {this.form.fields.checkbox(
                `target.content_kind.${contentKind}`,
                contentKind,
                {
                  trueValue: '1',
                  falseValue: '0',
                }
              )}
            </div>
          );
        })}

        {this.form.fields.checkbox('target.stl', 'STL')}

        {this.form.fields.checkbox(
          'target.ignore_audio',
          'IGNORE INTERNATIONAL/LOCAL AUDIO',
        )}

        {this.form.fields.input('target.encode_threads', 'Encode Threads')}
        {this.form.fields.input('target.decode_threads', 'Decode Threads')}
        {this.form.fields.file('target.watermark', 'Watermark')}

        {this.form.fields.checkbox(
          'target.available_for_diagonal',
          'Available for diagonal',
        )}

        {this.form.fields.checkbox(
          'target.available_for_clients',
          'Available for clients',
        )}
      </div>
    );
  }

}
