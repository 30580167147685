import React from 'react';
import Waypoint from 'react-waypoint';

import moment from 'moment/moment';

import Container from 'components/Forms/List';

export default class List extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      ready: false,
      suisa_histories: [],
      total: 0,
    };

    this.more = this.more.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  load(append = false) {
    if(this.state.loading) {
      return ;
    }

    this.setState({loading: true});

    let query = {page: this.state.page};
    this.props.api.get('/suisa_histories', query).then((response) => {
      let histories = response.data.suisa_histories;
      if(append) {
        histories = this.state.suisa_histories.concat(histories);
      }

      this.setState({
        ...response.data,
        ready: true,
        loading: false,
        suisa_histories: histories,
      });
    });
  }

  more() {
    if(this.state.page >= this.state.total - 1) {
      return ;
    }

    this.setState({page: this.state.page + 1});
    this.load(true);
  }

  render() {
    return (
      this.state.ready &&
        <Container>
          <h1>Suisa Histories</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Date & Time</th>
                <th>Suisa Number</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              {this.renderHistories(this.state.suisa_histories)}
              <tr>
                <td>
                  <Waypoint
                    scrollableAncestor={
                      document.getElementsByClassName('content')[0]
                    }
                    onEnter={this.more}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Container>
    );
  }

  renderHistories(suisa_histories) {
    return suisa_histories.map((history) => {
      return (
        <tr key={history.id} className="
          table-row--users
          suisa-histories__item
        ">
          <td className="suisa-histories__created-at">
            {moment(history.created_at).format('D.M.YYYY HH:mm')}
          </td>
          <td className="suisa-histories__suisa-number">
            {history.suisa_number}
          </td>
          <td className="suisa-histories__user-name">
            {history.user && history.user.name}
          </td>
        </tr>
      );
    });
  }

}
