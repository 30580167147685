import React from 'react';

import Form from 'services/Form';
import Errors from 'services/Errors';
import Query from 'services/Query';

export default class Confirm extends React.Component {

  constructor(props) {
    super(props);
    this.form = new Form(this);
    this.confirm = this.confirm.bind(this);

    try {
      this.state = {
        action: JSON.parse(Query.get('action')),
        code: Query.get('code'),
      };
    } catch(error) {
      alert(
        'Your confirmation url is wrong; please, try again or contact support',
      );
    }
  }

  confirm() {
    let message = 'Are you sure? You will not be able to reverse this action.';

    if(!confirm(message)) {
      return ;
    }

    this
      .form
      .api
      .post('/confirm', {
        data: this.state.action,
        code: Query.get('code'),
      })
      .then(() => {
        this.setState({confirmed: true});
      })
      .catch(Errors.alertHandler('action failed'));
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12 section-title">
            <h3>Confirm action: {this.state.action.subject}</h3>
          </div>
        </div>

        {this.renderBody()}
      </div>
    );
  }

  renderBody() {
    if(this.state.confirmed) {
      return (
        <div className="jumbotron">
          <div className="form-group profile__field-name">
            <div>
              {this.state.action.success.split('\n').map((line, index) => {
                return (
                  <span key={index}>
                    {line} <br />
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="jumbotron">
          <div className="form-group profile__field-name">
            <div>
              {this.state.action.message.split('\n').map((line, index) => {
                return (
                  <span key={index}>
                    {line} <br />
                  </span>
                );
              })}
            </div>
          </div>
        </div>

        <div className="form-group profile__field-name">
          <button
            onClick={this.confirm}
            className="btn btn-success"
          >Confirm</button>

          <a
            href="/"
            className="btn btn-default"
          >Cancel</a>
        </div>
      </div>
    );
  }

}
