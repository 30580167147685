import React from 'react';

import CreateComponent from 'components/Forms/Create';

import Query from 'services/Query';

import Form from './Form';

export default class Create extends CreateComponent {

  static key = 'film';
  static url = '/films';

  save(data) {
    this.setState(data);
    return this.form.api.post('/films', data).then((result) => {
      Query.redirect('/films/' + result.data.id);
    });
  }

  render() {
    return (
      <Form
        {...this.props}
        {...this.state}
        save={this.save}
      />
    );
  }

}
