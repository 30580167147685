import React from 'react';

export default function Contact() {
  return (
    <section className="section-contact container-fluid">
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <div className="card card-inverse">
            <div className="card-block">
              <h3 className="card-title"><strong>diagonal</strong> gmbh</h3>
                <ul className="fa-ul">
                  <li>
                    <i className="fa-li fa fa-map-marker"></i>
                    Bleicherweg 10
                    <br />
                    CH-8002 Zürich
                  </li>
                  <li>
                    <a href="tel:+41 44 508 19 34">
                      <i className="fa-li fa fa-phone" />
                      +41 44 508 19 34
                    </a>
                  </li>
                  <li>
                    <a href="mailto:service@diagonal.com">
                      <i className="fa-li fa fa-envelope" />
                      service@diagonal.com
                    </a>
                  </li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
   );
}
