import React from 'react';

import I18n from 'services/I18n';

import App from 'components/App';

const t = I18n.namespace('menu');

class Menu extends React.Component {

  constructor() {
    super();
    this.state = {user: {access: {}}};
    this.logout = this.logout.bind(this);
  }

  logout(event) {
    event.preventDefault();

    localStorage.clear();
    this.props.api.setToken(undefined);
    sessionStorage.removeItem('token');
    App.instance.setState({user: undefined});

    let form = document.createElement('form');
    form.action = '/users/sign_out';
    form.method = 'POST';

    let input = document.createElement('input');
    input.name = '_method';
    input.value = 'DELETE';
    input.type = 'hidden';

    form.appendChild(input);
    document.body.appendChild(form);

    form.submit();
  }

  render() {

    return (
      <div className="dropdown">
        <button
          className="btn btn-dark dropdown-toggle navbar-profile-button"
          data-toggle="dropdown"
          type="button"
        >
          <i className="icon fa fa-user fa-fw"></i>
          <span className="hidden-md-down">{this.props.user.email}</span>
        </button>

        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuButton"
        >
          <a className="dropdown-item" href="/profile">
            <i className="icon fa fa-user fa-fw"></i>
            {t('profile')}
          </a>

          {
            this.props.user.access.storages &&
              <a className="dropdown-item" href="/storage">
                <i className="icon fa fa-hdd-o fa-fw"></i>
                {t('storage')}
              </a>
          }

          <a className="dropdown-item" href="/contact">
            <i className="icon fa fa-comment fa-fw"></i>
            {t('contact')}
          </a>

          <div className="dropdown-divider"></div>

          {
            this.props.user.access.manage && (
              <div>
                <a href="/users" className="dropdown-item">
                  <i className="icon fa fa-users fa-fw"></i>
                  Users
                </a>

                <a href="/organizations" className="dropdown-item">
                  <i className="icon fa fa-building fa-fw"></i>
                  Organizations
                </a>

                <a href="/targets" className="dropdown-item">
                  <i className="icon fa fa-circle-o fa-fw"></i>
                  Targets
                </a>

                <a href="/search_histories" className="dropdown-item">
                  <i className="icon fa fa-search fa-fw"></i>
                  Search
                </a>

                <a href="/suisa_histories" className="dropdown-item">
                  <i className="icon fa fa-plug fa-fw"></i>
                  Api
                </a>

                <a href="/disks" className="dropdown-item">
                  <i className="icon fa fa-hdd-o fa-fw"></i>
                  Disks
                </a>

                <a href="/archive_disks" className="dropdown-item">
                  <i className="icon fa fa-hdd-o fa-fw"></i>
                  Archive Disks
                </a>

                <a href="/films/delete_dcps" className="dropdown-item">
                  <i className="icon fa fa-trash fa-fw"></i>
                  Bulk Delete
                </a>

                <div className="dropdown-divider"></div>
              </div>
            )
          }

          <a onClick={this.logout} className="dropdown-item" href="#">
            <i className="icon fa fa-sign-out fa-fw"></i>
            {t('sign_out')}
          </a>

        </div>
      </div>
    );
  }

}

export default Menu;
