import React from 'react';

import Errors from 'services/Errors';
import Query from 'services/Query';
import I18n from 'services/I18n';

import PlaylistInfo from './PlaylistInfo';
import Order from './Order';
import Play from './Play';

const t = I18n.namespace('film');

export default class Playlist extends React.Component {

  constructor(props) {
    super(props);


    this.showOrderDialogOrDownload = this.showOrderDialogOrDownload.bind(this);
    this.showPlayerDialog = this.showPlayerDialog.bind(this);
  }

  componentDidMount() {
    if(this.props.playlist.id == Query.get('order')) {
      this.showOrderDialogOrDownload();
    }

    if(this.props.playlist.id == Query.get('play')) {
      this.showPlayerDialog();
    }
  }

  render() {
    let playlist = this.props.playlist;

    let isExpandAvailable;

    if(this.props.kind == 'vod') {
      isExpandAvailable = this.props.user_can_convert;
    } else if(this.props.kind == 'webfoyer' && this.props.playlist.foyer) {
      isExpandAvailable = (
        this.props.user_can_see_transfers &&
          this.props.playlist.has_foyer_transfer
      );
    } else {
      isExpandAvailable = (
        this.props.user_can_see_transfers &&
          (playlist.transfer_status_cd !== null ||
            this.props.hasTransfers === true)
      );
    }

    let playlistsInfo = new PlaylistInfo(
      this.props.playlists,
      this.props.kind,
      this.props.setPlaylists,
      isExpandAvailable,
    );

    return (
      <tr key="download" className="table-row--asset">
        {playlistsInfo.render(
          this.props.lowestPlaylistId,
          playlist,
          this.props.index,
          this.props.hasTransfers,
        )}

        <td></td>

        <td className="table-cell--actions">
          &nbsp;
          {this.renderPlayDialog(playlist)}
          {this.renderOrderDialog(playlist)}
          {this.renderPlayAction(playlist)}
          {this.renderScreenerAction(playlist)}
          {this.renderOrderAction(playlist)}
          {this.renderDeliverAction(playlist)}
        </td>
      </tr>
    );
  }

  showPlayerDialog(event) {
    if(event) {
      event.stopPropagation();
      event.preventDefault();
    }

    Query.set('play', this.props.playlist.id);
  }

  renderPlayDialog(playlist) {
    if(Query.get('play') != playlist.id.toString()) {
      return ;
    }

    return (
      <Play api={this.props.api} playlist={playlist} />
    );
  }

  showOrderDialogOrDownload(event) {
    if(!this.props.user_can_order) {
      return ;
    }

    if(event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if(this.isDownload(this.props.playlist)) {
      let url = '/playlists/' + this.props.playlist.id + '/download';
      if(this.props.kind == 'webfoyer' && this.props.playlist.foyer) {
        url += '_foyer';
      }

      this
        .props
        .api
        .get(url)
        .then((response) => {
          window.location.href = response.data.url;
        })
        .catch(Errors.alertHandler('download failed'));

      return ;
    }

    Query.set('order', this.props.playlist.id);
  }

  renderOrderDialog(playlist) {
    if(Query.get('order') != playlist.id.toString()) {
      return ;
    }

    if(!this.props.user_can_order) {
      return ;
    }

    return (
      <Order
        api={this.props.api}
        cinemas={this.props.cinemas}
        loadTransfers={this.props.loadTransfers}
        playlist={playlist}
      />
    );
  }

  renderScreenerAction(playlist) {
    if(!playlist.screener_id || !this.props.user_can_manage_screeners) {
      return ;
    }

    return (
      <a
        key="screener"
        href={this.getPlaylistUrl(playlist) + '/screeners/new'}
        className="play_video open-popup-link btn btn-light btn-sm"
      >
        <i className="icon fa fa-video-camera fa-fw"></i>
        <span>Screener</span>
      </a>
    );
  }

  renderPlayAction(playlist) {
    if(!playlist.screener && !playlist.preview && !this.isMp4File(playlist)) {
      return ;
    }

    let userCanNotSeePreview = (
      !this.props.user_can_manage_screeners &&
        playlist.content_kind == 'feature'
    );

    if(userCanNotSeePreview) {
      return ;
    }

    return (
      <a
        key="player"
        href="#"
        onClick={this.showPlayerDialog}
        className="play_video open-popup-link btn btn-light btn-sm"
      >
        <i className="icon fa fa-play fa-fw"></i>
        <span>Play</span>
      </a>
    );
  }

  renderOrderAction(playlist) {
    if(!this.props.user_can_order) {
      return ;
    }

    let isVisible = (
      this.props.index == 0 &&
        playlist.on_dalco &&
        !['feature', 'short'].includes(playlist.content_kind) &&
        this.props.kind != 'vod'
    );

    if(!isVisible) {
      return ;
    }

    return (
      <a
        href="#"
        onClick={this.showOrderDialogOrDownload}
        className={'download btn btn-sm ' + (
          playlist.playlist_type_theatrical_public ?
            'btn-primary' :
            'playlist-order-button-not-public'
        )}
      >
        <i className={
          'icon fa fa-fw ' +
            (this.isDownload(playlist) ? 'fa-download' : 'fa-shopping-cart')
        }></i>

        <span>
          {this.isDownload(playlist) ? 'Download' : t('order')}
        </span>
      </a>
    );
  }

  renderDeliverAction(playlist) {
    if(!this.props.user_can_deliver) {
      return ;
    }

    if (playlist.content_kind && !['feature', 'short'].includes(playlist.content_kind)) {
      return ;
    }

    if(this.props.kind == 'vod') {
      return ;
    }

    return (
      <a
        href={this.getPlaylistUrl(playlist) + '/deliver'}
        className="btn btn-primary btn-sm"
      >
        <i className="icon fa fa-external-link fa-fw"></i>
        <span>{t('deliver')}</span>
      </a>
    );
  }

  getPlaylistUrl(playlist) {
    let result =
      '/films/' +
        playlist.film_id +
        '/playlists/' +
        playlist.id;

    return result;
  }

  isDownload(playlist) {
    if (this.props.source == 'movietransit') { return false; }

    if(this.isMp4File(playlist)) {
      return true;
    }

    if(this.isMovFile(playlist)) {
      return true;
    }

    if(this.props.cinemas.length == 0) {
      return true;
    }

    if(this.props.kind == 'webfoyer' && playlist.webfoyer) {
      return true;
    }

    return false;
  }

  isMp4File(playlist) {
    return playlist.filename && playlist.filename.endsWith('.mp4');
  }

  isMovFile(playlist) {
    return playlist.filename && playlist.filename.endsWith('.mov');
  }

}
