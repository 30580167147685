import React from 'react';

import Playlists from '../Playlists';

export default class PlaylistTitle extends React.Component {

  render() {
    let playlist = this.props.playlist;

    return (
      <tr key="dkdm" className="table-row--asset-title">
        <td className="table-cell--icon table-cell--icon-column">
          <i
            className={
              'icon icon-lg fa fa-fw ' + (!this.props.hideEncrypted && (
                (playlist.dkdm && playlist.dkdm.url) ?
                  'fa-unlock-alt' :
                  (playlist.encrypted ? 'fa-lock': 'icon--empty')
              ))
            }
            title={
              !this.props.hideEncrypted && playlist.encrypted ?
                (
                  playlist.dkdm ?
                    'Encrypted and has DKDM' :
                    'Encrypted and does not have DKDM'
                ) :
                undefined
            }
          ></i>
        </td>
        <td colSpan="11">
          {Playlists.renderTitle(this.props.kind, this.props.playlist)}
        </td>
      </tr>
    );
  }

}
