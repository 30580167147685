import React from 'react';

import Errors from './Errors';

export default class Value extends React.PureComponent {

  constructor() {
    super();
    this.change = this.change.bind(this);
  }

  change(event) {
    if(!this.props.onChange) {
      return ;
    }

    this.props.onChange(event.target.value);
  }

  render() {
    let value = undefined;
    if(this.props.value !== undefined && this.props.value !== null) {
      value = this.props.value;
    } else if(!(this.props.input || {}).hasOwnProperty('defaultValue')) {
      value = '';
    }

    return (
      <Errors errors={this.props.errors}>
        <div className="form-control-static">
          {value || this.props.empty}
        </div>
      </Errors>
    );
  }

}
