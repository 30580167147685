import React from 'react';

import I18n from 'services/I18n';
import Query from 'services/Query';

import Cinema from './Cinema';
import Vod from './Vod';
import Utilities from '../Utilities';

export default class Playlists extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      cinema: {},
      webfoyer: {},
      vod: {},
    };

    this.t = I18n.namespace('film');
  }

  render() {
    let contentKinds = Utilities.getKinds().split(',');
    let playlists = this.props.playlists.filter((playlist) => {
      if (playlist.content_kind_cd === undefined || playlist.content_kind_cd === null) { return true }
      return contentKinds.indexOf(playlist.content_kind_cd.toString()) != -1;
    });
    let cinema = Utilities.preparePlaylists(playlists, 'cinema');
    let webfoyer = Utilities.preparePlaylists(playlists, 'webfoyer');
    let vod = Utilities.preparePlaylists(playlists, 'vod');

    let currentTab = this.getCurrentTab(cinema, webfoyer, vod);

    return (
      <section className="section-moviedetail-container">
        <section className="section-moviedetail-tabs">
          <nav className="navbar navbar-full navbar-light">
            <div className="container container-wide">
              <div className="row">
                <div className="col-xs-12">
                  {this.renderNavbar(currentTab, cinema, webfoyer, vod)}
                </div>
              </div>
            </div>
          </nav>
        </section>

        {this.renderFilter()}

        <div className="tab-content">
          {
            this.isTheatricalAvailable() &&
              currentTab == 'cinema' &&
              this.renderCinemaTab(cinema)
          }

          {
            currentTab == 'webfoyer' &&
              this.isTheatricalAvailable() &&
              this.renderWebfoyerTab(webfoyer)
          }

          {
            currentTab == 'vod' &&
              this.props.user_can_see_homevideo &&
              this.renderVodTab(vod)
          }
        </div>
      </section>
    );
  }

  getCurrentTab(cinema, webfoyer, vod) {
    let currentTab = Query.get('tab');

    if(currentTab) {
      return currentTab;
    }

    if(this.isTheatricalAvailable() && Object.keys(cinema).length > 0) {
      return 'cinema';
    }

    if(this.isTheatricalAvailable() && Object.keys(webfoyer).length > 0) {
      return 'webfoyer';
    }

    if(Object.keys(vod).length > 0) {
      return 'vod';
    }

    return null;
  }

  renderNavbar(currentTab, cinema, webfoyer, vod) {
    return (
      <ul className="nav navbar-nav" style={{minHeight: '48px'}}>
        {
          this.isTheatricalAvailable() &&
            Object.keys(cinema).length > 0 && (
              <li className="nav-item">
                <a
                  href="#"
                  onClick={Query.set.bind(Query, 'tab', 'cinema')}
                  className={
                    'nav-link ' + ('cinema' == currentTab && 'active')
                  }
                >
                  Theatrical
                </a>
              </li>
            )
        }

        {
          this.isTheatricalAvailable() &&
            Object.keys(webfoyer).length > 0 && (
              <li className="nav-item">
                <a
                  href="#"
                  onClick={Query.set.bind(Query, 'tab', 'webfoyer')}
                  className={'nav-link ' +
                    ('webfoyer' == currentTab && 'active')}
                >
                  Foyer
                </a>
              </li>
            )
        }

        {this.props.user_can_see_homevideo && Object.keys(vod).length > 0 && (
          <li className="nav-item">
            <a
              href="#"
              onClick={Query.set.bind(Query, 'tab', 'vod')}
              className={'nav-link ' + ('vod' == currentTab && 'active')}
            >
              Homevideo
            </a>
          </li>
        )}
      </ul>
    );
  }

  renderFilter() {
    if(this.props.currentUser.role == 'cinema_manager') {
      return null
    }

    return (
      <section
        className="section-filterbar filterbar container container-wide"
      >
        <div className="row">
          <div className="col-xs-12">
            <form action="" className="form-inline">
              {Utilities.contentKindCheckboxes()}
            </form>
          </div>
        </div>
      </section>
    );
  }

  renderTab(kind, callback) {
    return (
      <div
        role="tabpanel"
        className="tab-pane fade in active"
        id={kind}
      >
        <section className="
          section-moviedetail-assets
          container
          container-wide
        ">
          <div className="table-stack">
            {callback()}
          </div>
        </section>
      </div>
    );
  }

  renderCinemaTab(groups) {
    if(Object.keys(groups).length === 0) {
      return ;
    }

    return this.renderTab('cinema', () => {
      return (
        <Cinema
          groups={groups}
          api={this.props.api}
          cinemas={this.props.cinemas}
          kind="cinema"
          user_can_deliver={this.props.user_can_deliver}
          user_can_order={this.props.user_can_order}
          user_can_see_transfers={this.props.currentUser.access.transfers}
          user_can_manage={this.props.currentUser.access.manage}
          user_can_manage_screeners={this.props.user_can_manage_screeners}
        />
      )
    });
  }

  renderWebfoyerTab(groups) {
    if(Object.keys(groups).length === 0) {
      return ;
    }

    return this.renderTab('webfoyer', () => {
      return (
        <Cinema
          groups={groups}
          api={this.props.api}
          cinemas={this.props.cinemas}
          kind="webfoyer"
          user_can_deliver={this.props.user_can_deliver}
          user_can_order={this.props.user_can_order}
          user_can_see_transfers={this.props.currentUser.access.transfers}
          user_can_manage={this.props.currentUser.access.manage}
          user_can_manage_screeners={this.props.user_can_manage_screeners}
        />
      );
    });
  }

  renderVodTab(groups) {
    if(Object.keys(groups).length === 0) {
      return ;
    }

    return this.renderTab('vod', () => {
      return (
        <Vod
          api={this.props.api}
          cinemas={this.props.cinemas}
          currentUser={this.props.currentUser}
          groups={groups}
          targets={this.props.targets}
          user_can_convert={this.props.user_can_convert}
          user_can_delete_conversion={this.props.user_can_delete_conversion}
        />
      );
    });
  }

  isTheatricalAvailable() {
    return this.props.currentUser.role !== 'distributor_homevideo_responsible';
  }

}
