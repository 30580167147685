import React from 'react';

import Container from 'components/Forms/Container';
import Errors from 'components/Forms/Errors';
import FormComponent from 'components/Forms/Form';
import Row from 'components/Forms/Row';

import I18n from 'services/I18n';

import Region from './Form/Region';
import Playlists from './Form/Playlists';

export default class Form extends FormComponent {

  static key = 'film';
  static url = '/films';

  constructor(props) {
    super(props);

    this.film = {};
  }

  componentWillReceiveProps(props) {
    this.setState(props);
    if(!this.film.id) {
      this.film = {...props.film};
    }
  }

  save(event) {
    event.preventDefault();

    return this.props.save({
      film: this.state.film,
      playlists: this.state.playlists,
    });
  }

  render() {
    let region =
      this.state.film &&
        this.state.film.regions &&
        this.state.film.regions.find((region) => {
          return region.title;
        });

    let isUserCanEditPlaylists =
      ['admin', 'distributor_supervisor'].includes(
        this.props.currentUser.role,
      );

    return (
      <div className="film-form">
        <Container title={region ? region.title: 'New film'}>
          {this.renderForm()}
        </Container>

        {
          isUserCanEditPlaylists ?
            (
              <Playlists
                film={this.film}
                playlists={this.state.playlists}
                disabled={this.state.loading}
                user={this.props.currentUser}
                errors={this.state.errors.playlists || {}}
                onChange={(playlists) => {
                  this.setState({
                    playlists: playlists,
                    errors: {...this.state.errors, playlists: []},
                  });
                }}
              />
            ) :
            null
        }
      </div>
    );
  }

  renderForm() {
    return (
      <form onSubmit={this.save}>
        {this.renderInputs()}
        {this.renderDropdowns()}
        {this.renderRestrictions()}
        {this.renderInfo()}

        {this.form.fields.checkbox(
          'film.is_procinema_parsing_disabled',
          'Ignore Procinema Data',
        )}

        {this.renderRegions()}

        <hr />

        <Row>
          <Errors errors={this.state.errors.fatal} />

          {this.state.film.id && this.props.currentUser.role == 'admin' && (
            <div className="form-group row">
              <div className="col-sm-offset-4 col-sm-8">
                <a
                  href={'/films/' + this.state.film.id + '/notification'}
                  className="btn btn-primary"
                  disabled={this.state.loading}
                >Bulletin...</a>
              </div>
            </div>
          )}

          <button
            type="submit"
            className="btn btn-primary"
            disabled={this.state.loading}
          >Save</button>

          &nbsp;

          <a
            href={'/films/' + this.state.film.id}
            className="btn btn-danger"
            disabled={this.state.loading}
            data-state={JSON.stringify({film: this.state.film})}
          >Cancel</a>
        </Row>
      </form>
    );
  }

  renderInputs() {
    return (
      <div>
        {this.form.fields.textarea(
          'film.notes',
          'Notes',
          {element: {rows: 5}},
        )}

        {this.renderAdminFilms()}

        {this.form.fields.input('film.suisa_number', 'Suisa Number')}
        {this.form.fields.input('film.imdb_id', 'IMDB Number')}

        {this.form.fields.select(
          'film.year',
          'Year',
          {valuesArray: this.getYears(), blank: 'None'},
        )}
      </div>
    );
  }

  renderAdminFilms() {
    if(this.props.currentUser.role != 'admin') {
      return ;
    }

    return (
      <div>
        {this.form.fields.select(
          'film.organization_id',
          'Distributor',
          {valuesObjects: this.state.distributors, blank: 'None'},
        )}

        {this.form.fields.select(
          'film.notification_distributor_id',
          'Always send notifications by',
          {valuesObjects: this.state.distributors, blank: 'None'}
        )}

        {this.form.fields.dropdown(
          'film.organization_ids',
          'Additional distributors',
          {valuesObjects: this.state.distributors}
        )}

        {this.form.fields.select(
          'film.invoice_distributor_id',
          'Invoice distributor',
          {valuesObjects: this.state.distributors, blank: 'Default'}
        )}
      </div>
    );
  }

  renderDropdowns() {
    return (
      <div>
        {this.form.fields.dropdown('film.country_ids', 'Countries', {
          valuesObjects: this.state.countries,
          titleField: I18n.getLocale(),
        })}

        {this.form.fields.dropdown('film.film_genres', 'Genres', {
          valuesArray: this.state.genres,
          valueCallback: ([value]) => {
            let genres = this.state.film.film_genres;

            if(!genres) {
              genres = [];
            }

            let genreObject = genres.find((genre) => {
              return genre.genre == value;
            });

            if(genreObject) {
              return [genreObject, genreObject.genre];
            }

            return [{genre: value}, value];
          },
        })}

        {this.form.fields.input('film.keyword_list', 'Keywords')}

        {
          this.props.currentUser.role == 'admin' &&
            this.form.fields.checkbox('film.activated', 'Activated')
        }
      </div>
    );
  }

  renderRestrictions() {
    if(!this.state.film.id) {
      return ;
    }

    return (
      <div>
        {this.form.fields.input(
          'film.allowed_age',
          'Allowed Age',
          {input: {disabled: !this.state.film.is_procinema_parsing_disabled}},
        )}

        {this.form.fields.input(
          'film.recommended_age',
          'Recommended Age',
          {input: {disabled: !this.state.film.is_procinema_parsing_disabled}},
        )}

        {this.form.fields.input(
          'film.zurich_canton_age',
          'Zurich Canton Age',
          {input: {disabled: !this.state.film.is_procinema_parsing_disabled}},
        )}

      </div>
    );
  }

  renderInfo() {
    if(!this.state.film.id) {
      return ;
    }

    return (
      <div>
        {this.form.fields.input(
          'film.director',
          'Director',
          {input: {disabled: !this.state.film.is_procinema_parsing_disabled}}
        )}

        {this.form.fields.input(
          'film.producer',
          'Producer',
          {input: {disabled: !this.state.film.is_procinema_parsing_disabled}}
        )}

        {this.form.fields.input(
          'film.screenwriter',
          'Screenwriter',
          {input: {disabled: !this.state.film.is_procinema_parsing_disabled}}
        )}

        {this.form.fields.input(
          'film.music',
          'Music',
          {input: {disabled: !this.state.film.is_procinema_parsing_disabled}}
        )}

        {this.form.fields.input(
          'film.actor',
          'Actor',
          {input: {disabled: !this.state.film.is_procinema_parsing_disabled}}
        )}

        {this.form.fields.input(
          'film.duration',
          'Duration',
          {input: {disabled: !this.state.film.is_procinema_parsing_disabled}}
        )}

        {this.form.fields.input(
          'film.pictures',
          'Pictures',
          {input: {disabled: !this.state.film.is_procinema_parsing_disabled}}
        )}
      </div>
    );
  }

  renderRegions() {
    if(!this.state.film.regions) {
      return null;
    }

    return (
      <div>
        {this.state.film.regions.map((region, index) => {
          if(region.locale != 'en') {
            return this.renderRegion(region, index);
          }
        })}

        {this.state.film.regions.map((region, index) => {
          if(region.locale == 'en') {
            return this.renderRegion(region, index);
          }
        })}
      </div>
    );
  }

  renderRegion(region, index) {
    return (
      <Region
        key={index}
        is_procinema_parsing_disabled={
          this.state.film.is_procinema_parsing_disabled
        }
        region={region}
        ready={this.state.ready}
        errors={this.form.get(`errors.regions.${index}`)}
        change={this.form.setter(`film.regions.${index}`)}
        loading={this.state.loading}
      />
    );
  }

  getYears() {
    let current = (new Date()).getFullYear();
    let years = [];
    for(let year = 1900; year <= current + 20; year++) {
      years.push(year.toString());
    }

    return years;
  }

}
