import React from 'react';

export default class Container extends React.PureComponent {

  render() {
    return (
      <section className="section section-moviedetail">
        <div className="section-moviedetail-header">
          <div className="section-moviedetail-content">
            <div className="section-moviedetail-title">
              <div className="container">
                <h1 className="section-moviedetail-heading">
                  {this.props.title}
                </h1>
              </div>
            </div>

            <div className="section-moviedetail-description">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 ">
                    <div className="jumbotron section-moviedetail-edit">
                      {this.props.children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

}