import moment from 'moment/moment';

require(
  'eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js',
);

export default class DateTimePicker {

  static binder(callback, options) {
    return (element) => {
      this.bind(element, options, callback);
    };
  }

  static bind(element, options, callback) {
    if(!element) {
      return ;
    }

    if(element.dataset.dateTimePickerBinded) {
      return ;
    }

    element.dataset.dateTimePickerBinded = true;

    options = {
      sideBySide: true,
      format: 'DD.MM.YYYY HH:mm',
      ...options,
    };

    window
      .jQuery(element)
      .datetimepicker(options)
      .show()
      .on('dp.change', (event) => {
        let date = moment(event.date, options.format).utc();
        let result = null;
        if(date.isValid()) {
          result = date.format('YYYY-MM-DDTHH:mm:ssZ');
        }

        callback(result, element);
      });
  }

}