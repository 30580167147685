import React from 'react';
import Modal from 'react-modal';

import Errors from 'components/Forms/Errors';
import Input from 'components/Forms/Input';
import Row from 'components/Forms/Row';

import DateTimePicker from 'services/DateTimePicker';
import FormService from 'services/Form';
import I18n from 'services/I18n';
import ModalService from 'services/Modal';
import Query from 'services/Query';

require(
  'eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min.js',
);

export default class EditTransfer extends React.Component {

  constructor(props) {
    super(props);

    this.form = new FormService(this);

    this.state = {
      transferId: props.transferId,
      errors: {},
    };

    this.close = this.close.bind(this);
    this.save = this.save.bind(this);
    this.createDiskTransfer = this.createDiskTransfer.bind(this);
    this.downloadDiskTransferPdf = this.downloadDiskTransferPdf.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentDidMount() {
    this.loadTransfer();
  }

  componentWillReceiveProps(props) {
    if(this.state.transferId != props.transferId) {
      this.setState({transferId: props.transferId}, this.loadTransfer);
    }
  }

  loadTransfer() {
    this.form.api.receive('/transfers/' + this.state.transferId);
  }

  close() {
    Query.set('editTransfer', null);
  }

  save(event) {
    event.preventDefault();
    let url = '/transfers/' + this.state.transferId;

    let data = {
      ...this.state.transfer,
      validate_disk_id: true,
    };

    if(data.prepared_date) {
      data.status = 'prepared';
    }

    if(data.in_progress_date) {
      data.status = 'in_progress';
    }

    if(data.delivered_date) {
      data.status = 'delivered';
    }

    if(data.tracking_id && !data.tracking_url) {
      data.tracking_url =
        'https://service.post.ch/EasyTrack/submitParcelData.do?' +
          'formattedParcelCodes=' + data.tracking_id.replace(/\./g, '');
    }

    return this.form.api.post(url, data).then(() => {
      if(this.props.onChange) {
        this.props.onChange(data);
      }

      this.props.reload();
    });
  }

  createDiskTransfer() {
    this
      .form
      .api
      .post(
        '/transfers/' + this.state.transferId + '/create_disk_transfers',
        this.state.transfer,
      )
      .then(() => {
        this.props.reload();
        this.close();
      });
  }

  downloadDiskTransferPdf() {
    this
      .form
      .api
      .get(
        `/transfers/send_disk/${this.state.transferId}.pdf`,
        {}, { axios: { responseType: 'arraybuffer' }}
      )
      .then(({data}) => {
        let file = new Blob([data], { type: 'application/pdf' });
        let url = URL.createObjectURL(file);
        let disk = this.state.disks.find(disk => disk.id === this.state.transfer.disk_id);

        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `disk_${disk.name.toLowerCase()}`);

        document.body.appendChild(link);

        link.click();
        link.parentNode.removeChild(link);

        this.props.reload();
      });
  }

  cancel() {
    if(!confirm('Are you sure you want to cancel this transfer?')) {
      return ;
    }

    this
      .form
      .api
      .post('/transfers/' + this.state.transferId + '/cancel')
      .then(() => {
        this.props.reload();
        this.close();
      });
  }

  render() {
    let transfer = this.state.transfer || {};

    return (
      <Modal
        appElement={document.getElementById('app')}
        isOpen={true}
        style={{
          overlay: ModalService.STYLE.overlay,
          content: {
            ...ModalService.STYLE.content,
            backgroundColor: '#f2f2f2',
            padding: 0,
            overflow: 'visible',
          },
        }}
        onRequestClose={this.close}
        contentLabel="Transfer"
      >
        <button
          className="close"
          onClick={this.close}
          style={{marginTop:'12px', marginRight:'12px'}}
        >
          <span aria-hidden="true">×</span>
        </button>

        <div className="jumbotron" style={{margin: 0}}>
          <form onSubmit={this.save}>
            {transfer.type == 'SendDisk' && this.form.fields.select(
              'transfer.disk_id',
              'Disk',
              {
                valuesObjects:
                  this
                    .state
                    .disks
                    .map((disk) => {
                      return {
                        name: disk.name,
                        id: disk.id,
                      };
                    }),
                blank: 'No disk assigned',
              }
            )}

            <Row title="Ordered">
              {transfer.id && (
                <Input
                  errors={this.state.errors.prepared_date}
                  input={{
                    ref: DateTimePicker.binder(
                      this.form.setter('transfer.prepared_date'),
                    ),
                    defaultValue: I18n.datetime(transfer.prepared_date),
                  }}
                />
              )}
            </Row>

            <Row title="Started">
              {transfer.id && (
                <Input
                  errors={this.state.errors.in_progress_date}
                  input={{
                    ref: DateTimePicker.binder(
                      this.form.setter('transfer.in_progress_date'),
                    ),
                    defaultValue: I18n.datetime(transfer.in_progress_date),
                  }}
                />
              )}
            </Row>

            <Row title="Due Date">
              {transfer.id && (
                <Input
                  errors={this.state.errors.due_date}
                  input={{
                    ref: DateTimePicker.binder(
                      this.form.setter('transfer.due_date'),
                    ),
                    defaultValue: I18n.datetime(transfer.due_date),
                  }}
                />
              )}
            </Row>

            {transfer.type == 'SendDisk' && this.form.fields.textarea(
              'transfer.tracking_url',
              'Tracking link',
              {element: {rows: 5}},
            )}

            {transfer.type == 'SendDisk' && this.form.fields.input(
              'transfer.tracking_id',
              'Tracking ID',
            )}

            <Row title="Delivered">
              {transfer.id && (
                <Input
                  errors={this.state.errors.delivered_date}
                  input={{
                    ref: DateTimePicker.binder(
                      this.form.setter('transfer.delivered_date'),
                    ),
                    defaultValue: I18n.datetime(transfer.delivered_date),
                  }}
                />
              )}
            </Row>

            {transfer.cancelled_date && (
              <Row title="Canceled">
                <Input
                  errors={this.state.errors.cancelled_date}
                  input={{
                    ref: DateTimePicker.binder(
                      this.form.setter('transfer.cancelled_date'),
                    ),
                    defaultValue: I18n.datetime(transfer.cancelled_date),
                    disabled:
                      ['cancelled', 'delivered', 'dead'].includes(
                        transfer.status,
                      ),
                  }}
                />
              </Row>
            )}

            {this.form.fields.select(
              'transfer.distributor_id',
              'Invoice Distributor',
              {
                valuesObjects: this.state.distributors,
                blank: 'No invoice',
              }
            )}

            {this.form.fields.select(
              'transfer.notification_distributor_id',
              'Notification Distributor',
              {
                valuesObjects: this.state.distributors,
                blank: 'No notification',
              }
            )}

            <Errors errors={this.state.errors.fatal} />

            <button
              type="button"
              className="btn btn-danger"
              onClick={this.cancel}
              tabIndex="1"
              disabled={['in_progress', 'cancelled', 'delivered', 'dead'].includes(
                transfer.status,
              )}
            >Cancel Transfer</button>

            &nbsp;

            {transfer.type != 'SendDisk' && (
              <a
                className="btn btn-primary"
                onClick={this.createDiskTransfer}
                tabIndex="1"
              >Create Disk Transfer</a>
            )}


            {transfer.type === 'SendDisk' && transfer.disk_id && (
              <a
                className="btn btn-primary"
                onClick={this.downloadDiskTransferPdf}
                tabIndex="2"
                download
              >Delivery Slip</a>
            )}

            <div className="pull-right">
              <button className="btn btn-success" type="submit">Save</button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }

}
