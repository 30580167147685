import React from 'react';

import Container from 'components/Forms/List';
import Row from 'components/Forms/Row';

import FormService from 'services/Form';

export default class Targets extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      organization: {},
      targets: [],
    };

    this.form = new FormService(this);
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  load() {
    let url = '/organizations/' + this.props.organizationId + '/targets';
    return this.props.api.get(url).then((response) => {
      this.setState({...response.data, ready: true});
    });
  }

  save(event) {
    event.preventDefault();
    let url = '/organizations/' + this.state.organization.id;
    let data = {target_ids: this.state.organization.target_ids};
    return this.form.api.post(url, data).then(() => {
      this.props.history.push('/organizations')
    });
  }

  toggle(event) {
    let targetId = parseInt(event.target.value);
    let targetsIds = this.state.organization.target_ids.concat([targetId]);
    if(!event.target.checked) {
      targetsIds = targetsIds.filter((innerTargetId) => {
        return innerTargetId != targetId;
      });
    }

    this.setState({
      organization: {
        ...this.state.organization,
        target_ids: [...new Set(targetsIds)],
      },
    });
  }

  render() {
    return (
      this.state.ready &&
        <Container>
          <h1>Conversion targets for "{this.state.organization.name}"</h1>
          <form onSubmit={this.save}>
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Transfer Method</th>
                  <th>Parameters</th>
                  <th>Enable</th>
                </tr>
              </thead>
              <tbody>
                {this.renderTargets(this.state.targets)}
              </tbody>
            </table>

            <Row>
              <a href="/organizations"className="btn btn-default">Back</a>
              &nbsp;
              <button
                type="submit"
                className="btn btn-primary"
              >Save</button>
            </Row>
          </form>
        </Container>
    );
  }

  renderTargets(targets) {
    return targets.map((target) => {
      return (
        <tr key={target.id} className="organization-targets__item">
          <td className="organization-targets__name">
            {target.name}
          </td>
          <td className="organization-targets__transfer-method">
            {target.transfer_method}
          </td>
          <td className="organization-targets__conversion-params">
            {target.conversion_params}
          </td>
          <td className="organization-targets__enable">
            <input
              type="checkbox"
              value={target.id}
              checked={this.state.organization.target_ids.includes(target.id)}
              onChange={this.toggle}
            />
          </td>
        </tr>
      );
    });
  }

}
