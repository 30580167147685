import React from 'react';

import Container from 'components/Forms/List';
import Organization from 'components/Disks/Organization';

import Form from 'services/Form';
import Query from 'services/Query';

import Locations from './List/Locations';

export default class List extends React.Component {

  constructor(props) {
    super(props);

    this.form = new Form(this);

    this.state = {
      ready: false,
      organization_id: Query.get('organization_id'),
      disks: [],
    };

    this.showOrganizationDisks = this.showOrganizationDisks.bind(this);
    this.hideOrganizationDisks = this.hideOrganizationDisks.bind(this);
  }

  componentDidMount() {
    this.load();

    this.unlisten = this.props.history.listen(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  load() {
    this.props.api.get('/disks').then((response) => {
      this.setState({
        ...response.data,
        ready: true,
      });
    });
  }

  delete(diskId) {
    if(!(this.props.confirm || confirm)('Are you sure?')) {
      return ;
    }

    this.props.api.delete('/disks/' + diskId).then(() => {
      this.setState({
        disks: this.state.disks.filter((disk) => {
          return disk.id != diskId;
        }),
      });
    });
  }

  showOrganizationDisks(event) {
    Query.set('organization_id', event.target.dataset.id);
    this.setState({organization_id: event.target.dataset.id});
  }

  hideOrganizationDisks() {
    Query.set('organization_id', null);
    this.setState({organization_id: null, organization: null});
  }

  returnDisk = async (event) => {
    await this.props.api.post('/disks/' + event.target.dataset.disk + '/return');
    this.load();
  }

  render() {
    return (
      <React.Fragment>
        {Query.get('diskTransfers') && (
          <Locations
            api={this.props.api}
            diskId={Query.get('diskTransfers')}
            onHide={() => {
              Query.set('diskTransfers', null);
              this.setState((state) => ({ ...state }));
            }}
          />
        )}

        <Container>
          <div className="row">
            <div className="col-sm-6"><h1>Disks</h1></div>
            <div className="col-sm-6">
              <a href="/disks/create" className="btn btn-primary pull-right">
                Create
              </a>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Disk</th>
                <th>Current Place</th>
                <th>Type</th>
                <th>Number Of Transfers</th>
                <th>Size</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.renderDisks()}
            </tbody>
          </table>

          <Organization
            api={this.props.api}
            organization_id={this.state.organization_id}
            onHide={this.hideOrganizationDisks}
          />
        </Container>
      </React.Fragment>
    );
  }

  renderDisks() {
    return this.state.disks.map((disk) => {
      return (
        <tr key={disk.id} className="disks__item">
          <td className="disks__id">
            {disk.name}
          </td>
          <td className="disks__location">
            <a
              href="#"
              onClick={this.showOrganizationDisks}
              data-id={disk.organization && disk.organization.id}
            >
              {disk.organization && disk.organization.name}
            </a>
          </td>
          <td className="disks__disk-type">
            {disk.disk_type}
          </td>
          <td className="disks__transfers-count">
            <a
              href={'?diskTransfers=' + disk.id}
              data-disk={disk.id}
            >
              {disk.transfers_count}
            </a>
          </td>
          <td className="disks__size">
            {disk.size}GB
          </td>
          <td className="disks__actions">
            <a
              href={'/disks/' + disk.id}
              className="disks__edit btn btn-sm btn-primary"
              title="Edit Disk"
            >
              <span className="fa fa-pencil"></span>
            </a>
            {' '}
            <a
              href="#"
              onClick={this.returnDisk}
              className="disks__edit btn btn-sm btn-primary"
              title="Return disk to Diagonal"
              data-disk={disk.id}
            >
              <span data-disk={disk.id} className="fa fa-undo"></span>
            </a>
          </td>
        </tr>
      );
    });
  }

}
