import React from 'react';

import Container from 'components/Forms/Container';
import Errors from 'components/Forms/Errors';
import Row from 'components/Forms/Row';

import Form from 'services/Form';
import Query from 'services/Query';

export default class CreateUser extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      user: {},
      errors: {},
    };

    this.form = new Form(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.form.api.receive(
      '/screeners/users/new',
      {playlist_id: this.props.playlistId},
    );
  }

  generatePassword() {
    var text = '';
    var possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < 12; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  save(event) {
    event.preventDefault();

    this
      .form
      .api
      .post('/screeners/users?playlist_id=' + this.props.playlistId, {
        ...this.state.user,
        password: this.generatePassword(),
      })
      .then((response) => {
        Query.redirect(
          '/films/' + this.props.filmId + '/playlists/' +
            this.props.playlistId + '/screeners/new?user_id=' +
            response.data.user_id,
        );
      });
  }

  render() {
    return (
      <Container title="Create user">
        <form onSubmit={this.save}>
          {this.form.fields.input('user.name', 'Name')}
          {this.form.fields.input('user.company', 'Company')}
          {this.form.fields.input('user.email', 'Email')}

          {this.form.fields.select(
            'user.region_cd',
            'Region',
            {values: this.state.regions},
          )}

          <hr />

          <Errors errors={this.state.errors.fatal} />

          <Row>
            <button type="submit" className="btn btn-primary">Save</button>
            &nbsp;
            <a
              href={Query.getRedirectUrl('/users')}
              className="btn btn-danger"
            >Cancel</a>
          </Row>
        </form>
      </Container>
    );
  }

}
