import React from 'react';

import App from 'components/App';

import Errors from 'services/Errors';
import I18n from 'services/I18n';

export default class Targets extends React.Component {

  constructor(props) {
    super(props);

    let conversions =
      this.props.state.conversions &&
        this.props.state.conversions[props.playlist.id] ||
        [];

    this.state = {
      conversions: conversions,
    };

    this.t = I18n.namespace('film');
    this.delete = this.delete.bind(this);
    this.reorder = this.reorder.bind(this);
    this.toggleTargetId = this.toggleTargetId.bind(this);
    this.downloadVideo = this.downloadVideo.bind(this);
    this.downloadSubtitles = this.downloadSubtitles.bind(this);
  }

  componentDidMount() {
    this.load()
    App.instance.socketio.on('conversion', (message) => {
      this.updateConversion(message);
    });
  }

  updateConversion(message) {
    let allConversions = this.props.state.conversions || [];
    let allPlaylistConversions = allConversions[this.props.playlist.id] || [];
    let conversions = [...allPlaylistConversions];

    let conversionIndex = conversions.findIndex((conversion) => {
      return conversion.id == message.id;
    });

    if(conversionIndex === null) {
      return ;
    }

    conversions[conversionIndex] = {
      ...conversions[conversionIndex],
      ...message,
    };

    this.props.setState({
      conversions: {
        ...this.props.state.conversions,
        [this.props.playlist.id]: conversions,
      },
    });
  }

  componentWillUnmount() {
    App.instance.socketio.removeAllListeners('conversion');
  }

  componentWillReceiveProps(props) {
    if(props.state.conversions && props.state.conversions[props.playlist.id]) {
      this.setState({conversions: props.state.conversions[props.playlist.id]});
    }

    if(!props.state.conversions) {
      this.load();
    }
  }

  load() {
    let url = '/playlists/' + this.props.playlist.id + '/conversions';
    return this.props.api.get(url).then((response) => {
      this.props.setState({
        conversions: {
          ...this.props.state.conversions,
          [this.props.playlist.id]: response.data.conversions,
        },
      });
    });
  }

  delete(event) {
    let target = event.target;
    if(target.nodeName != 'A') {
      target = target.parentNode;
    }

    if(!confirm('Are you sure you want to delete conversion?')) {
      return ;
    }

    let conversionId = parseInt(target.dataset.conversion);

    let conversion = this.getConversions().find((localConversion) => {
      return localConversion.id == conversionId;
    });

    if(conversion.removed || !conversion.filename) {
      this.props.api.delete('/conversions/' + conversionId);

      this.setConversions(
        this.getConversions().filter((conversion) => {
          return conversion.id != conversionId;
        }),
      );
    } else {
      this.props.api.delete('/conversions/' + conversionId + '/delete_file');

      this.setConversions(
        this.getConversions().map((conversion) => {
          if(conversion.id != conversionId) {
            return conversion;
          }

          return {
            ...conversion,
            filename: null,
            status: 'pending',
            removed: true,
          };
        }),
      );
    }
  }

  reorder(event) {
    let target = event.target;
    if(target.nodeName != 'A') {
      target = target.parentNode;
    }

    let conversionId = parseInt(target.dataset.conversion);
    this.props.api.post('/conversions/' + conversionId + '/reorder');

    this.setConversions(
      this.getConversions().map((conversion) => {
        if(conversion.id != conversionId) {
          return conversion;
        }

        return {
          ...conversion,
          filename: null,
          status: 'pending',
          removed: false,
        };
      }),
    );
  }

  setConversions(conversions) {
    this.props.setState({
      conversions: {
        ...this.props.state.conversions,
        [this.props.playlist.id]: conversions,
      },
    })
  }

  getTargetsIds() {
    return this.props.state.playlistsTargets[this.props.playlist.id] || [];
  }

  toggleTargetId(event) {
    let targetId = parseInt(event.target.dataset.target);
    let newTargetIds = this.getTargetsIds().filter((localTargetId) => {
      return localTargetId != targetId;
    });

    if(event.target.checked) {
      newTargetIds.push(targetId);
    }

    this.props.setState({
      playlistsTargets: {
        ...this.props.state.playlistsTargets,
        [this.props.playlist.id]: newTargetIds,
      },
    });
  }

  getConversions() {
    if(!this.props.state.conversions) {
      return this.state.conversions;
    }

    if(!this.props.state.conversions[this.props.playlist.id]) {
      return this.state.conversions;
    }

    return this.props.state.conversions[this.props.playlist.id];
  }

  render() {
    let result = this.props.targets.map((target) => {
      let conversion = this.getConversions().find((localConversion) => {
        return localConversion.target_id == target.id;
      });


      let ignore = (
        target.content_kind &&
          target.content_kind[this.props.playlist.content_kind] != '1'
      );

      if(ignore) {
        return null;
      }

      return (
        <tr key={target.id} className="table-row--asset-detail">
          <td className="table-cell--collapse"></td>
          <td className="table-cell--checkbox">
            {this.props.user_can_convert && !conversion && (
              <label className="
                custom-control
                custom-checkbox
                custom-checkbox-compact
              ">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={this.getTargetsIds().includes(target.id)}
                  onChange={this.toggleTargetId}
                  data-target={target.id}
                />

                <span className="custom-control-indicator">
                  <i className="icon fa fa-check"></i>
                </span>
              </label>
            )}
          </td>

          <td className="table-cell--asset-detail" colSpan="10">
            <span className="
              table-cell__content
              table-cell__content--lg
              table-cell__content--border
              films__target
            ">
              {target.name}
            </span>

            {this.renderConversionActions(target, conversion)}
          </td>
        </tr>
      );
    });

    return (
      <tbody className="table-body--asset-details">
        {result}
      </tbody>
    );
  }

  renderConversionActions(target, conversion) {
    if(!conversion) {
      return null;
    }

    return (
      <span>
        <span className="
          table-cell__content
          table-cell__content--lg
          status
        ">
          {this.renderConversionBody(target, conversion)}
        </span>

        {
          this.props.user_can_delete_conversion && (
            <span className="
              table-cell__content
              table-cell__content--xs
            ">
              <a
                href="#"
                className="soft-gray"
                onClick={this.delete}
                data-conversion={conversion.id}
              >
                <i className="icon fa fa-times fa-fw"></i>
              </a>
            </span>
          )
        }

        <span className="
          table-cell__content
          table-cell__content--md
        ">
          {I18n.datetime(conversion.created_at)}
        </span>

        <span className="
          table-cell__content
          table-cell__content--md
          film__conversion-user
        ">
          {conversion.user && conversion.user.email}
        </span>
      </span>
    );
  }

  downloadVideo(event) {
    event.stopPropagation();
    event.preventDefault();

    this
      .props
      .api
      .get('/conversions/' + event.target.dataset.conversion + '/url')
      .then((response) => {
        window.location.href = response.data.url;
      })
      .catch(Errors.alertHandler('download failed'));
  }

  downloadSubtitles(event) {
    event.stopPropagation();
    event.preventDefault();

    this
      .props
      .api
      .get('/conversions/' + event.target.dataset.conversion + '/subtitles')
      .then((response) => {
        window.location.href = response.data.url;
      })
      .catch(Errors.alertHandler('download failed'));
  }

  renderConversionBody(target, conversion) {
    let result = [];


    let title = this.t('video');
    if(target.conversion_params.includes('--sound-only')) {
      title = this.t('audio');
    }

    if(conversion.removed) {
      result.push(
        <span
          key="title"
          className="btn btn-link film__converion-removed"
          title="conversion file has been deleted"
        >{title}</span>
      );
    } else {
      if(conversion.filename && conversion.status == 'uploaded') {
        result.push(
          <a
            href={
              '/api/v2/conversions/' + conversion.id + '/download?token=' +
                conversion.token
            }
            key="download"
            className="btn btn-link"
            data-conversion={conversion.id}
            onClick={this.downloadVideo}
          >{title}</a>
        );
      } else {
        result.push(conversion.status.split('_').join(' '));
        if(conversion.status == 'processing') {
          result.push(' ' + conversion.progress + '%');
        }
      }
    }

    let isSubtitlesAvailable = (
      target.stl &&
        conversion.status == 'uploaded' &&
        conversion.subtitles_exist
    );

    if(isSubtitlesAvailable) {
      result.push(
        <span key="subtitles">
          &nbsp;
          <a
            href="#"
            key="subtitles"
            className="subtitles btn btn-link"
            data-conversion={conversion.id}
            onClick={this.downloadSubtitles}
          >
            {this.t('subtitles')}
          </a>
        </span>
      );
    }

    if(conversion.status == 'failed' || conversion.removed) {
      result.push(
        <span key="reorder">
          &nbsp;
          <a
            href="#"
            title={conversion.info}
            data-conversion={conversion.id}
            onClick={this.reorder}
          >reorder</a>
        </span>
      );
    }

    return result;
  }

}
