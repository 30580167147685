import React from 'react';

import App from 'components/App';

import I18n from 'services/I18n';
import Form from 'services/Form';

import Header from './Show/Header';
import Playlists from './Show/Playlists';

export default class Show extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      cinemas: [],
      film: this.props.film || {},
      playlists: [],
      query: {},
      targets: [],
    };

    this.t = I18n.namespace('film');
    this.form = new Form(this);
  }

  componentDidMount() {
    this.load();
    this.unlisten = this.props.history.listen(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  load() {
    this.form.api.receive('/films/' + this.props.id).then((response) => {
      App.setTitle(
        Header.getFilmRegionValue(response.data.film.regions, (region) => {
          return region.title;
        }),
      );

      document.getElementById('app').scrollTop = 0;
      document.getElementById('app').focus();
    });
  }

  render() {
    if(!this.state.film.id || !this.props.currentUser) {
      return null;
    }

    return (
      <div>
        <Header
          film={this.state.film}
          user_can_edit_film={this.state.user_can_edit_film}
        />

        <Playlists
          cinemas={this.state.cinemas}
          targets={this.state.targets}
          playlists={this.state.playlists}
          currentUser={this.props.currentUser}
          user_can_see_homevideo={this.state.user_can_see_homevideo}
          user_can_deliver={this.state.user_can_deliver}
          user_can_order={this.state.user_can_order}
          user_can_convert={this.state.user_can_convert}
          user_can_delete_conversion={this.state.user_can_delete_conversion}
          user_can_manage_screeners={this.state.user_can_manage_screeners}
          history={this.props.history}
          api={this.props.api}
        />
      </div>
    );
  }

}
