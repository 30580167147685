import React from 'react';
import Waypoint from 'react-waypoint';

import FilmListItem from './ListItem';

export default class Search extends React.Component {

  constructor(props) {
    super(props);

    this.loading = false;
    this.state = {
      films: null,
      more: false,
      page: 0,
    };

    this.more = this.more.bind(this);
  }

  componentDidMount() {
    this.load();
    this.unlisten = this.props.history.listen(() => {
      this.load();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  load(append = false) {
    if(this.loading) {
      return ;
    }

    this.setState({loading: true});

    let request = {
      query: JSON.stringify({
        query: (new URLSearchParams((new URL(location).search))).get('query'),
      }),
      page: this.state.page,
    };

    this.props.api.get('/films/list', request).then((response) => {
      let films = response.data.films;
      if(append) {
        films = this.state.films.concat(films);
      }

      this.loading = false;
      this.setState({...response.data, films: films});
    });
  }

  more() {
    if(!this.state.more) {
      return ;
    }

    this.setState({page: this.state.page + 1});
    this.load(true);
  }

  render() {
    return (
      <div>
        <section className="section-movielist-grid container-fluid">
          {this.state.films && (
            <div className="movielist-grid row">
              {this.state.films.length == 0 &&
                <div style={{marginBottom: '36px'}}>Nothing found...</div>
              }
              {this.state.films.map((film) => {
                return (
                  <FilmListItem
                    key={film.id}
                    film={film}
                    url={
                      this.props.currentUser.role == 'journalist' ?
                        '/screeners' :
                        '/films'
                    }
                  />
                );
              })}
            </div>
          )}
          {
            this.state.more &&
              <Waypoint
                scrollableAncestor={
                  document.getElementsByClassName('content')[0]
                }
                onEnter={this.more}
              />
          }
        </section>
      </div>
    );
  }

}
