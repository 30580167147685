import React from 'react';

import Errors from './Errors';

export default class File extends React.PureComponent {

  constructor() {
    super();
    this.click = this.click.bind(this);
    this.clear = this.clear.bind(this);
    this.state = {};
  }

  click() {
    let input = document.createElement('input');
    input.type = 'file';

    input.addEventListener('change', (event) => {
      let reader  = new FileReader();
      let file = event.target.files[0];

      reader.addEventListener(
        'load',
        () => {
          this.props.onChange(reader.result, file.name);
          if(this.props.onFile) {
            this.props.onFile(file);
          }

          this.setState({name: file.name});
        },
        false,
      );

      reader.readAsDataURL(file);
    });

    input.click();
  }

  clear(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onChange(null);
  }

  render() {
    return (
      <Errors
        errors={this.props.errors}
        className="form-control-static form-control-light form__file-container"
      >
        {
          this.props.value &&
            this.props.value.url &&
            this.props.children
        }

        {
          typeof this.props.value == 'string' && (
            <div>
              <span>{this.state.name || 'File selected'}</span>
              &nbsp;
              <sup><a href="#" onClick={this.clear}>x</a></sup>
            </div>
          )
        }

        <div>
          <button
            type="button"
            className='btn btn-primary btn-sm btn-attach-file'
            disabled={this.props.disabled}
            onClick={this.click}
          >
            <span className="fa fa-upload"></span>
            &nbsp;
            Attach File
          </button>
        </div>
      </Errors>
    );
  }

}
