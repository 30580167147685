import React from 'react';
import Waypoint from 'react-waypoint';

import moment from 'moment/moment';

import Container from 'components/Forms/List';

export default class List extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      ready: false,
      search_histories: [],
      total: 0,
    };

    this.more = this.more.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  load(append = false) {
    if(this.state.loading) {
      return ;
    }

    this.setState({loading: true});

    let query = {page: this.state.page};
    this.props.api.get('/search_histories', query).then((response) => {
      let histories = response.data.search_histories;
      if(append) {
        histories = this.state.search_histories.concat(histories);
      }

      this.setState({
        ...response.data,
        ready: true,
        loading: false,
        search_histories: histories,
      });
    });
  }

  more() {
    if(this.state.page >= this.state.total - 1) {
      return ;
    }

    this.setState({page: this.state.page + 1});
    this.load(true);
  }

  render() {
    return (
      this.state.ready &&
        <Container>
          <h1>Search Histories</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Date & Time</th>
                <th>Query</th>
                <th>User</th>
                <th>Ip</th>
              </tr>
            </thead>
            <tbody>
              {this.renderSearches(this.state.search_histories)}
              <tr>
                <td>
                  <Waypoint
                    scrollableAncestor={
                      document.getElementsByClassName('content')[0]
                    }
                    onEnter={this.more}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Container>
    );
  }

  renderSearches(search_histories) {
    return search_histories.map((history) => {
      return (
        <tr key={history.id} className="
          table-row--users
          search-histories__item
        ">
          <td className="search-histories__created-at">
            {moment(history.created_at).format('D.M.YYYY HH:mm')}
          </td>
          <td className="search-histories__query">
            {history.query}
          </td>
          <td className="search-histories__user-name">
            {history.user && history.user.name}
          </td>
          <td className="search-histories__ip">
            <a
              href={'http://ipinfo.io/' + history.ip}
              rel="nohistory"
              target="_blank"
            >
              {history.ip}
            </a>
          </td>
        </tr>
      );
    });
  }

}
