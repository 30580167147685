import React from 'react';
import moment from 'moment/moment';

import Cache from 'services/Cache';
import DateTimePicker from 'services/DateTimePicker';
import I18n from 'services/I18n';
import Query from 'services/Query';

import Errors from 'components/Forms/Errors';
import Form from 'components/Forms/Form';
import Row from 'components/Forms/Row';
import Header from 'components/Films/Show/Header';

export default class Create extends Form {

  static key = 'screener';

  static saveButtonLabel = (
    <span>
      <span className="fa fa-paper-plane"></span> Send
    </span>
  );

  static locales = {
    'english': 'en',
    'german': 'de',
    'french': 'fr',
    'italian': 'it',
  };

  constructor(props) {
    super(props);

    if(Query.get('user_id')) {
      this.state.screener.recipients = [parseInt(Query.get('user_id'))];
    } else {
      this.state.users = Cache.get('screeners.users');
    }

    this.state.screener.start_at = moment().format();
    this.state.screener.end_at = moment().add(7, 'days').format();
  }

  componentDidMount() {
    this
      .form
      .api
      .receive('/screeners/new', {
        playlist_id: this.props.playlistId,
      })
      .then(() => {
        this
          .props
          .api
          .get('/screeners/new_users', {
            playlist_id: this.props.playlistId,
          })
          .then((response) => {
            this.setState(response.data);
            Cache.set('screeners.users', response.data.users)
          });
      });
  }

  save(event) {
    event.preventDefault();

    let data = {
      ...this.state.screener,
      playlist_id: this.props.playlistId,
    };

    this.form.api.post('/screeners', data).then(() => {
      this.props.history.push(`/films/${this.props.filmId}`);
    });
  }

  render() {
    if(!this.state.users) {
      return null;
    }

    return (
      <section className="section-screeners">
        <div className="container">
          <form onSubmit={this.save}>
            {this.renderBody()}

            <hr />

            <Row>
              <Errors errors={this.state.errors.fatal} />

              <button
                type="submit"
                className="btn btn-primary"
                disabled={this.state.loading}
              >
                {this.constructor.saveButtonLabel}
              </button>

              &nbsp;

              <a
                href={'/films/' + this.props.filmId}
                className="btn btn-danger"
                disabled={this.state.loading}
              >Cancel</a>
            </Row>
          </form>
        </div>
      </section>
    );
  }

  renderBody() {
    return (
      <div>
        <div className="form-group">
          <label className="form-labels-mini">
            {I18n.t('screener.recipients', {locale: this.getLocale()})}
          </label>

          {this.form.fields.dropdown(
            'screener.recipients',
            null,
            {
              values: this.state.users,
              valueCallback: (user) => {
                let name = user.name;

                if(user.company) {
                  if(name) {
                    name += ' - ';
                  }

                  name += user.company;
                }

                if(user.email) {
                  if(name) {
                    name += ' <' + user.email + '>';
                  } else {
                    name = user.email;
                  }
                }

                return [
                  user.id,
                  name,
                ];
              },
              element: {
                className: `
                  custom-select
                  select2
                  filterbar-select
                  filterbar-select-light
                  hidden
                `,
                'data-placeholder': I18n.t(
                  'screener.add_recipients',
                  {locale: this.getLocale()},
                ),
                disabled: this.state.users === undefined || this.state.loading,
              },
            },
          )}

          <a
            className="btn btn-link"
            href={`${this.playlistUrl()}/screeners/users/new`}
          >
            <i className="icon fa fa-plus fa-fw"></i>
            {I18n.t('screener.register', {locale: this.getLocale()})}
          </a>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <p>
              {I18n.t('screener.greeting', {locale: this.getLocale()})}
            </p>

            <p>
              {
                this.state.film && this.state.distributor &&
                  I18n.t('screener.screener', {
                    distributor: this.state.distributor.name,
                    film: Header.findRegionByLocale(
                      this.state.film.regions,
                      I18n.getLocale(),
                    ).title,
                    locale: this.getLocale(),
                  })
              }
            </p>
          </div>
        </div>

        <div className="form-group">
          <label className="form-labels-mini">
            {I18n.t('screener.remark', {locale: this.getLocale()})}
          </label>

          {this.form.fields.textarea('screener.message', null, {
            element: {
              rows: 15,
              className: 'form-control',
            },
          })}

          <div className="row">
            <div className="col-xs-12">
              <p>
                {I18n.t('screener.start', {locale: this.getLocale()})}
              </p>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-xs-12 col-md-6 col-lg-3">
              <label className="form-labels-mini">
                {I18n.t('screener.active_from', {locale: this.getLocale()})}
              </label>

              {this.form.fields.input('screener.start_at', null, {
                input: {
                  className: 'form-control',
                  value: undefined,
                  defaultValue:
                    moment(this.state.screener.start_at)
                      .format('DD.MM.YYYY HH:mm'),
                  ref: DateTimePicker.binder(
                    (value) => {
                      this.form.set('screener.start_at', value);
                    },
                    {format: 'DD.MM.YYYY HH:mm'},
                  ),
                },
              })}
            </div>
          </div>

          <div className="form-group row">
            <div className="col-xs-12 col-md-6 col-lg-3">
              <label className="form-labels-mini">
                {I18n.t('screener.active_to', {locale: this.getLocale()})}
              </label>

              {this.form.fields.input('screener.end_at', null, {
                input: {
                  className: 'form-control',
                  value: undefined,
                  defaultValue:
                    moment(this.state.screener.end_at)
                      .format('DD.MM.YYYY HH:mm'),
                  ref: DateTimePicker.binder(
                    (value) => {
                      this.form.set('screener.end_at', value);
                    },
                    {format: 'DD.MM.YYYY HH:mm'},
                  ),
                },
              })}
            </div>
          </div>

          {this.state.distributor && (
            <div className="row ng-scope">
              <div className="col-xs-12">
                <p>
                  {I18n.t('screener.contact', {
                    distributor: this.state.distributor.name,
                    locale: this.getLocale(),
                  })}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  playlistUrl() {
    return `/films/${this.props.filmId}/playlists/${this.props.playlistId}`;
  }

  getLocale() {
    let recipients = this.state.screener.recipients;
    if(recipients && recipients.length > 0) {
      let recipient = this.state.users.find((user) => {
        return user.id == recipients[0];
      });

      if(!recipient) {
        return 'en';
      }

      if(!this.constructor.locales[recipient.region]) {
        return 'en';
      }

      return this.constructor.locales[recipient.region];
    }

    return 'en';
  }
}
