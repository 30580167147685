import React from 'react';

import Form from 'services/Form';
import I18n from 'services/I18n';

const t = I18n.namespace('deliver');

export default class Locations extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      playlists: [],
      kdms: [],
    };

    this.form = new Form(this, {
      onSetState: (state) => {
        this.props.setState(state);
      },
    });
  }

  render() {
    if(!this.props.playlist) {
      return null;
    }

    return (
      <section className="section-filterbar filterbar container-fluid">
        <div className="jumbotron jumbotron--compact">
          <div className="row">
            <div className="col-xs-12">
              <div className="table-stack">
                <table className="table-stack--cpl table">
                  {this.renderBody()}
                  {this.renderConnectedDcps()}
                  {this.renderRequiredDcps()}
                  {this.renderAdditionalDcps()}
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderBody() {
    return (
      <tbody>
        <tr>
          <td className="table-cell--title" colSpan="2">CPL</td>
        </tr>

        <tr>
          <td
            className="table-cell--cplname table-cell--hidden-md-up"
            colSpan="2"
          >
            {this.renderDcpSwitcher()}
          </td>
        </tr>

        <tr>
          <td
            className="table-cell--cplcheckbox table-cell--hidden-md-up"
            colSpan="2"
          >
            {this.renderCheckboxes(this.props.playlist)}
          </td>

          <td className="table-cell--cplcheckbox table-cell--hidden-md-down">
            {this.renderCheckboxes(this.props.playlist)}
          </td>

          <td className="table-cell--cplname table-cell--hidden-md-down">
            {this.renderDcpSwitcher()}
          </td>
        </tr>
      </tbody>
    );
  }

  renderDcpSwitcher() {
    return (
      <div className="
        filterbar-dropdown
        form-group
        filterbar-dropdown--compact
        custom-checkbox-light
      ">
        {this.form.fields.select(null, null, {
          value: this.props.playlist.id,
          values: this.props.playlists,
          valueCallback: (playlist) => {
            return [playlist.id, (playlist.title || playlist.filename)];
          },
          blank: false,
          element: {
            className:
              'custom-select filterbar-select filterbar-select--light',
            onChange: this.form.callback('dcp', (event) => {
              if(!event.target.value) {
                return ;
              }

              this.props.reset();

              this.props.history.push(
                '/films/' +
                  this.props.filmId +
                  '/playlists/' +
                  event.target.value.replace(/["]/g, "") +
                  '/deliver',
              );
            }),
          },
        })}
      </div>
    );
  }

  renderCheckboxes(playlist) {
    return (
      <div>
        {/*<% if current_user.can_deliver_feature? %>*/}
        <label className="
          custom-control
          custom-checkbox
          custom-checkbox-compact
          custom-checkbox-light
        ">
          <input
            type="checkbox"
            className="custom-control-input"
            checked={this.state.playlists.find(p => p.id == playlist.id)}
            onChange={this.form.callback(
              'playlist-' + playlist.id,
              (event) => {
                let playlists = {
                  [this.props.playlist.id]: this.props.playlist,
                };

                this.props.playlists.forEach((localPlaylist) => {
                  playlists[localPlaylist.id] = localPlaylist;
                });

                let details = this.state.playlists;

                if(event.target.checked) {
                  this.props.playlists.forEach((localPlaylist) => {
                    if(localPlaylist.filename == playlist.filename) {
                      details.push(localPlaylist);
                    }
                  });
                } else {
                  details = details.filter((detail) => {
                    if (playlist.id == detail.id) {
                      return false;
                    }

                    if (playlist.id == playlists[detail.id].version_file_id) {
                      return false;
                    }

                    if (playlist.filename == playlists[detail.id].filename) {
                      return false;
                    }
                    return true;
                  });
                }

                details.forEach((detail) => {
                  if (playlists[detail.id] && playlists[detail.id].version_file_id) {
                    details.push(playlists[detail.version_file_id])
                  }
                });

                this.form.set('playlists', [...new Set(details)]);
              },
            )}
          />

          <span className="custom-control-indicator">
            <i className="icon fa fa-check fa-fw"></i>
          </span>

          <span className="custom-control-description">DCP</span>
        </label>

        <label className="
          custom-control
          custom-checkbox
          custom-checkbox-compact
          custom-checkbox-light
        ">
          <input
            type="checkbox"
            className="custom-control-input"
            disabled={!(playlist.dkdm && playlist.dkdm.url && playlist.source != 'movietransit') }
            checked={this.state.kdms.includes(playlist.id)}
            onChange={this.form.callback(
              'kdm-' + playlist.id,
              (event) => {
                this.form.toggle('kdms', playlist.id, event.target.checked);
              },
            )}
          />

          <span className="custom-control-indicator">
            <i className="icon fa fa-check fa-fw"></i>
          </span>

          <span className="custom-control-description">KDM</span>
        </label>
      </div>
    );
  }

  renderConnectedDcps() {
    let playlists = this.props.playlists.filter((playlist) => {
      if(playlist.id === this.props.playlist.id) {
        return false;
      }

      if(playlist.filename !== this.props.playlist.filename) {
        return false;
      }

      return true;
    });

    if(playlists.length === 0) {
      return ;
    }

    return (
      <tbody>
        <tr>
          <td className="table-cell--title" colSpan="2">
            {t('connected_cpls')}
          </td>
        </tr>

        {playlists.map((playlist) => {
          return this.renderDcpSelecter(playlist);
        })}
      </tbody>
    );
  }

  renderRequiredDcps() {
    if(!this.props.playlist.version_file) {
      return ;
    }

    return (
      <tbody>
        <tr>
          <td className="table-cell--title" colSpan="2">
            {t('required_cpls')}
          </td>
        </tr>

        {this.renderDcpSelecter(this.props.playlist.version_file)}
      </tbody>
    );
  }

  renderDcpSelecter(playlist) {
    return [
      <tr key="title">
        <td className="table-cell--cplname table-cell--hidden-md-up" colSpan="2">
          {playlist.title}
        </td>
      </tr>,
      <tr key="content">
        <td
          className="table-cell--cplcheckbox table-cell--hidden-md-up"
          colSpan="2"
        >
          {this.renderCheckboxes(playlist)}
        </td>

        <td className="table-cell--cplcheckbox table-cell--hidden-md-down">
          {this.renderCheckboxes(playlist)}
        </td>

        <td className="table-cell--cplname table-cell--hidden-md-down">
          {playlist.title || playlist.filename}
        </td>
      </tr>,
    ];
  }

  renderAdditionalDcps() {
    let playlists = this.props.playlists.filter((playlist) => {
      if(playlist.id === this.props.playlist.id) {
        return false;
      }

      if(playlist.filename === this.props.playlist.filename) {
        return false;
      }

      return true;
    });

    if(playlists.length == 0) {
      return ;
    }

    return [
      <tbody key="header">
        <tr>
          <td className="table-cell--title" colSpan="2">
            {t('also_book_for')}
          </td>
        </tr>

        <tr>
          <td colSpan="2">
            <a
              href="#"
              className={
                'btn btn-link table-stack--cpl__show ' +
                  (!this.state.showAdditionalPlaylists && 'collapsed')
              }
              onClick={this.form.callback('showAdditionalPlaylists', () => {
                this.setState({
                  showAdditionalPlaylists: !this.state.showAdditionalPlaylists,
                });
              })}
            >{t('show_items')}</a>
          </td>
        </tr>
      </tbody>,
      <tbody
        key="body"
        className={
          'table-rowgroup-collapse collapse ' +
            (this.state.showAdditionalPlaylists && 'in')
        }
      >
        {playlists
          .filter((playlist) => {
            if(playlist.filename == this.props.playlist.filename) {
              return false;
            }

            let versionFile = this.props.playlist.version_file;
            if(versionFile && playlist.filename == versionFile.filename) {
              return false;
            }

            return true;
          })
          .map((playlist) => {
            return this.renderDcpSelecter(playlist);
          })}
      </tbody>,
    ];
  }

}
