import React from 'react'

import 'styles/Spinner.scss'

export default class Spinner extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (!this.props.visible) {
      return <div></div>
    } else {
      return (
        <div className={ `spinner ${this.props.className}` }>
          <div className="spinner__box card">
            <i className={ `fa fa-spin fa-spinner spinner__box__icon spinner__box__icon--${this.props.size || 'small'}` }></i>
          </div>
        </div>
      )
    }
  }
}
