import React from 'react';

export default class Errors extends React.Component {

  static errors(errors) {
    if(!errors) {
      errors = [];
    }

    if(!(errors instanceof Array)) {
      errors = [errors];
    }

    return errors;
  }

  render() {
    let errors = this.constructor.errors(this.props.errors);

    let element = null;

    if(errors && errors.length > 0) {
      element = (
        <div className="form-control-feedback">
          {errors.join('; ')}
        </div>
      );
    }

    if(!this.props.children) {
      return (
        <div className="form__error-fatal has-danger">{element}</div>
      );
    }

    return (
      <div className={
        (this.props.className || '') + ' ' +
          (errors.length > 0 && 'has-danger')
      }>
        {this.props.children}
        {element}
      </div>
    );
  }

}
