import React from 'react';

import Menu from './Menu';
import Languages from './Languages';
import Navigation from './Navigation';

const $ = require('jquery');

export default class Header extends React.Component {

  componentDidMount() {

    let fixOffset = () => {
      if(typeof $('header').data('offset') === 'object') {
        $('header').data('offset').offset = $('.navbar-meta').height();
      }
    };

    fixOffset();
    $(window).resize(fixOffset);

    $('body').on('click', function(event) {
      let ignore = (
        $(event.target).hasClass('navbar-toggler') ||
          $(event.target.parentNode).hasClass('navbar-toggler')
      );

      if(ignore) {
        return ;
      }

      $('.navbar-toggler').attr('aria-expanded', 'false');
      $('.nav-main').removeClass('in');
    })

    $(function() {
      $('.navbar-toggler').on('touchend', function() {
        $(this).click();
      });
    });

    $(function() {
      if(/mobile/i.test(navigator.userAgent)) {
        $('body').addClass('mobile');
      }
    });

    $(document).on('touchmove', function() {
      if (document.body.clientWidth / window.innerWidth > 1) {
        $('.header').addClass('header--zoomed');
      } else {
        $('.header').removeClass('header--zoomed');
      }
    });
  }

  render() {
    return (
      <header className="header" data-offset="5" data-tolerance="10">

        <nav className="navbar navbar-full navbar-meta">
          <div className="container-fluid">
            <div className="row">
              <div className="
                navbar-intro
                col-xs-5
                col-sm-6
                col-md-8
                col-lg-6
              ">
                <div className="navbar-customer hidden-sm-down">
                  <a
                    href={Navigation.getFilmsUrl('/', this.props.user)}
                    className="customer-name"
                  >
                    {
                      (
                        this.props.user.primary_organization &&
                          this.props.user.primary_organization.name
                      ) ||
                        this.props.user.name ||
                        'Diagonal'
                    }
                  </a>
                </div>
                <a href="/" className="navbar-brand">filmservice.net</a>
              </div>

              <div className="
                navbar-user
                col-xs-7
                col-sm-6
                col-md-4
                col-lg-6
              ">
                <ul className="nav navbar-nav pull-xs-right">
                  <li className="nav-item">
                    <Menu user={this.props.user} api={this.props.api}/>
                  </li>

                  <li className="nav-item">
                    <Languages />
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </nav>

        <Navigation
          api={this.props.api}
          history={this.props.history}
          user={this.props.user}
        />
      </header>
    );
  }

}
