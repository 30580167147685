import React from 'react';

import FormService from 'services/Form';

import Row from './Row';
import Errors from './Errors';

export default class Form extends React.PureComponent {

  static saveButtonLabel = 'Save';

  constructor(props) {
    super(props);
    this.state = {
      [this.constructor.key]: {},
      errors: {},
    }

    this.form = new FormService(this);
    this.save = this.save.bind(this);
  }

  save(event) {
    event.preventDefault();
    return this.props.save(this.state[this.constructor.key]);
  }

  componentWillReceiveProps(props) {
    this.setState(props);
  }

  render() {
    return (
      <form onSubmit={this.save}>
        {this.renderBody()}

        <hr />

        <Row>
          <Errors errors={this.state.errors.fatal} />

          <button
            type="submit"
            className="btn btn-primary"
            disabled={this.state.loading}
          >
            {this.constructor.saveButtonLabel}
          </button>

          &nbsp;

          {this.constructor.url && (
            <a
              href={this.constructor.url}
              className="btn btn-danger"
              disabled={this.state.loading}
            >Cancel</a>
          )}
        </Row>
      </form>
    );
  }

  renderBody() {
    throw new Error('not implemented');
  }

}
