import React from 'react';
import Modal from 'react-modal';

import Query from 'services/Query';
import ModalService from 'services/Modal';

const videojs = require('video.js/dist/video.js');

export default class Play extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      display: true,
    };

    this.player = null;

    this.close = this.close.bind(this);
    this.setup = this.setup.bind(this);
    this.closeByContent = this.closeByContent.bind(this);
  }

  getAllCinemasIds() {
    return this.props.cinemas.map((cinema) => {
      return cinema.id;
    });
  }

  close() {
    this.setState({display: false});
    Query.set('play', null);
  }

  closeByContent(event) {
    if(event.target.className == 'film__player-container') {
      this.close();
      event.stopPropagation();
    }
  }

  render() {
    return (
      <Modal
        appElement={document.getElementById('app')}
        isOpen={this.state.display}
        style={{
          ...ModalService.STYLE,
          content: {
            ...ModalService.STYLE.content,
            border: 'none',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: null,
            background: 'transparent',
            padding: '0',
          },
        }}
        onRequestClose={this.close}
        contentLabel="Video Player"
      >
        <div
          className="film__player-container"
          style={{
            width: '100%',
            padding: '20px',
            background: 'transparent',
          }}
          onClick={this.closeByContent}
        >
          <div className="player_box">
            {this.renderVideo()}
          </div>
        </div>
      </Modal>
    );
  }

  preventDefaultEvent(event) {
    event.preventDefault();
  }

  setup(element) {
    if(!element) {
      if(this.player) {
        this.player.dispose();
        this.player = null;
      }

      return ;
    }

    this.player = videojs(element);
    this.player.ready(() => {
      this.player.play();
    });
  }

  renderVideo() {
    return (
      <video
        className="video-js vjs-default-skin vjs-big-play-centered"
        controls
        preload="metadata"
        data-setup='{"fluid": true}'
        style={{width: '100% !important'}}
        onContextMenu={this.preventDefaultEvent}
        ref={this.setup}
      >
        <source type="video/mp4" src={this.url()} />

        <p className="vjs-no-js">
          To view this video please enable JavaScript, and consider
          upgrading to a web browser that
          <a
            href="http://videojs.com/html5-video-support/"
            target="_blank"
          >supports HTML5 video</a>
        </p>
      </video>
    );
  }

  url() {
    let result =
      '/api/v2/playlists/' +
        this.props.playlist.id +
        '/preview' +
        '?X-User-Token=' +
        this.props.api.getToken();

    return result;
  }

}
