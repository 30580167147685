import axios from 'axios';
import I18n from 'services/I18n';
import Cache from 'services/Cache';

export default class Api {

  constructor() {
    this.token = Cache.get('token') ||
      sessionStorage.getItem('token');
  }

  getToken() {
    return this.token;
  }

  setToken(token, remember = false) {
    if(remember) {
      Cache.set('token', token);
    } else {
      sessionStorage.setItem('token', token);
    }

    this.token = token;
  }

  start() {
    let promise =
      axios
        .get('/api/v2/session', {params: {token: this.token}})
        .then((response) => {
          this.token = response.data.token;
          return response;
        });

    return promise;
  }

  get(endpoint, params, config) {
    let promise = axios.get(
      '/api/v2' + endpoint,
      {
        ...config,
        params: params,
        headers: this.createHeaders(),
      },
    );

    return this.process(promise);
  }

  post(endpoint, data, config) {
    if(typeof data !== 'string') {
      data = JSON.stringify(data);
    }

    let promise = axios.post('/api/v2' + endpoint, data, {
      ...config,
      headers: this.createHeaders(),
    });

    return this.process(promise);
  }

  delete(endpoint, params, config) {
    let promise = axios.delete('/api/v2' + endpoint, {
      ...config,
      params: params,
      headers: this.createHeaders(),
    });

    return this.process(promise);
  }

  process(result) {
    result = result.catch((error) => {
      let isUnauthorized =
        error.response &&
          error.response.status === 403 &&
          error.response.data &&
          error.response.data.error ===
            'Authorization::AuthorizationRequired';

      if(isUnauthorized) {
        window.location.href = '/users/sign_in';
      }

      throw error;
    });

    return result;
  }

  createHeaders() {
    let result = {
      'Content-type': 'application/json',
       'X-Locale': I18n.getLocale(),
    };

    if(this.token) {
      result['X-User-Token'] = this.token;
    }

    return result;
  }

}
