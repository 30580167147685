import React from 'react';

export default class Alert extends React.Component {
  constructor(props) {
    super(props);

    this.state = { visible: true };
  }

  componentDidMount() {
    setTimeout(() => this.setState({ visible: false }), 6000);
  }

  render () {
    if (this.state.visible) {
      return (
        <div className={ `alert alert-${this.props.mode} alert-dismissible` } role="alert">
          <span dangerouslySetInnerHTML={ {__html: this.props.message } }></span>

          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )
    } else {
      return <div></div>
    }
  }
}
