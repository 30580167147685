import React from 'react';
import moment from 'moment/moment';

import I18n from 'services/I18n';

import Utilities from '../Utilities';

export default class PlaylistInfo {

  constructor(playlists, kind, setPlaylists, isExpandAvailable = true) {
    this.playlists = playlists;
    this.setPlaylists = setPlaylists;
    this.kind = kind;
    this.toggle = this.toggle.bind(this);
    this.isExpandAvailable = isExpandAvailable;
  }

  toggle(event) {
    if(!this.isExpandAvailable) {
      return ;
    }

    let target = event.target;
    if(target.nodeName != 'A') {
      target = target.parentNode;
    }

    if(target.classList.contains('empty')) {
      return ;
    }

    let result = [...this.playlists];
    let index = result.indexOf(target.dataset.playlist);

    if(index !== -1) {
      result.splice(index, 1);
    } else {
      result.push(target.dataset.playlist);
    }

    if(!result[0]) {
      result.shift(); // remove first empty element
    }

    this.setPlaylists(result);
  }

  render(lowestPlaylistId, playlist, index, hasTransfers) {
    return [
      ...this.renderInfo(lowestPlaylistId, playlist, index, hasTransfers),
      this.renderInfoXs(playlist),
    ];
  }

  renderInfo(lowestPlaylistId, playlist, index, hasTransfers) {
    let isCollapsed = !this.playlists.includes(lowestPlaylistId.toString());
    let transferStatus = playlist.transfer_status_cd;
    if(transferStatus === undefined || transferStatus === null) {
      if(this.kind == 'webfoyer' && playlist.foyer) {
        if(playlist.has_foyer_transfer) {
          transferStatus = 3;
        }
      } else if(hasTransfers) {
        transferStatus = 0;
      }
    }

    let result = [
      <td key="collapse" className="table-cell--collapse" rowSpan="2">
        {index == 0 && (
          <a
            href={this.isExpandAvailable ? '#' : undefined}
            onClick={this.toggle}
            data-playlist={lowestPlaylistId}
            className={
              (isCollapsed && 'collapsed') + ' ' +
                (this.kind == 'vod' ? 'targets-arrow' : 'transfers-arrow') +
                ' ' +
                (!this.isExpandAvailable && 'empty')
            }
          >
            <i
              className="icon fa fa-chevron-right fa-fw asset-status-collapse"
            ></i>
          </a>
        )}
      </td>,
      <td key="icon" className="
        table-cell--icon
        table-cell--icon-column
        film__playlist-transfer-icon
      ">
        {this.kind != 'vod' && Utilities.getTransferIcon(transferStatus)}
      </td>,
      <td
        key="spoken_language"
        className="table-cell--asset-info film__spoken-language"
        title="Spoken Language"
      >
        {playlist.spoken_language}
      </td>,
      <td
        key="subtitle_languages"
        className="table-cell--asset-info film__subtitle-languages"
        title="Subtitle Languages"
      >
        {this.getPlaylistSubtitles(playlist)}
      </td>,
    ];

    if(this.kind !== 'webfoyer') {
      result = result.concat([
        <td
          key="dimension"
          className="table-cell--asset-info film__dimension"
          title="Dimension"
        >
          {playlist.dimension}
        </td>,
        <td
          key="resolution"
          className="table-cell--asset-info film__resolution"
          title="Resolution"
        >
          {playlist.resolution}
        </td>,
      ]);
    }

    result = result.concat([
      <td
        key="aspect_ratio"
        className="table-cell--asset-info film__aspect-ratio"
        title="Aspect Ratio"
      >
        {playlist.aspect_ratio}
      </td>,
      <td
        key="audio_type"
        className="table-cell--asset-info film__audio-type"
        title="Audio Type"
      >
        {playlist.audio_type}
      </td>,
      <td
        key="fps"
        className="table-cell--asset-info film__fps"
        title="FPS"
      >
        {playlist.fps}
      </td>,
      <td
        key="duration"
        className="table-cell--asset-info film__duration"
        title="Duration"
      >
        {playlist.duration}
      </td>,
      <td
        key="mastering_date"
        className="table-cell--asset-info film__end-credit"
        title="End Credit"
      >
        {['feature', 'short'].includes(playlist.content_kind) ?
          playlist.end_credit || I18n.t('film.end_credit') :
          ''}
      </td>,
    ]);

    return result;
  }

  renderInfoXs(playlist) {
    return (
      <td key="xs" className="table-cell--asset-info--xs" colSpan="9">
        <ul className={
          'value-box ' +
            (this.kind == 'webfoyer' && 'value-box--foyer')
        }>
          <li className="value-box__item value-box__item--language">
            {playlist.spoken_language}
          </li>

          <li className="value-box__item value-box__item--subtitles">
            {this.getPlaylistSubtitles(playlist)}
          </li>

          {this.kind != 'webfoyer' && (
            <li className="value-box__item value-box__item--format">
              {playlist.dimension}
            </li>
          )}

          {this.kind != 'webfoyer' && (
            <li className="value-box__item value-box__item--resolution">
              {playlist.resolution}
            </li>
          )}

          <li className="value-box__item value-box__item--aspect">
            {playlist.aspect_ratio}
          </li>

          <li className="value-box__item value-box__item--audio">
            {playlist.audio_type}
          </li>

          <li className="value-box__item value-box__item--frames">
            {playlist.fps}
          </li>

          <li className="value-box__item value-box__item--time">
            {playlist.frames && playlist.fps && (
              moment
                .utc(playlist.frames / (playlist.fps || 24) * 1000)
                .format('HH:mm:ss')
            )}
          </li>

          <li className="value-box__item value-box__item--date">
            {playlist.end_credit || I18n.t('film.end_credit')}
          </li>
        </ul>
      </td>
    );
  }

  getPlaylistSubtitles(playlist) {
    let subtitles =
      [
        playlist.subtitle_language1,
        playlist.subtitle_language2,
        playlist.subtitle_language3,
      ]
        .filter((language) => {
          return !!language;
        })
        .join('-');

    if(!subtitles) {
      return 'XX';
    }

    return subtitles;
  }

}