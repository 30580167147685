export default class Image {

  static load(url) {
    let promise = new Promise((resolve) => {
      let loader = new window.Image();
      loader.addEventListener('load', resolve);
      loader.src = url;
    });

    return promise;
  }

  static loadTo(url, image) {
    return this.load(url).then((result) => {
      image.src = url;
      return result;
    });
  }

}
