import React from 'react';
import Modal from 'react-modal';

import Container from 'components/Forms/List';

import Form from 'services/Form';
import Query from 'services/Query';

export default class List extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      disk_id: Query.get('disk_id'),
      archive_disks: [],
    };

    this.form = new Form(this);
    this.showDcps = this.showDcps.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  load() {
    this.props.api.get('/archive_disks').then((response) => {
      this.setState({
        ...response.data,
        ready: true,
      });
    });
  }

  delete(diskId) {
    if(!(this.props.confirm || confirm)('Are you sure?')) {
      return ;
    }

    this.props.api.delete('/archive_disks/' + diskId).then(() => {
      this.setState({
        archive_disks: this.state.archive_disks.filter((disk) => {
          return disk.id != diskId;
        }),
      });
    });
  }

  showDcps(event) {
    Query.set('archive_disk_id', event.target.dataset.id);
    this.setState({archive_disk_id: event.target.dataset.id});
    this.form.api.receive(
      '/archive_disks/' + event.target.dataset.id + '/playlists',
    );
  }

  render() {
    return (
      <Container>
        {this.renderModal()}

        <div className="row">
          <div className="col-sm-6"><h1>Archive Disks</h1></div>
          <div className="col-sm-6">
            <a
              href="/archive_disks/create"
              className="btn btn-primary pull-right"
            >
              Create
            </a>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>UUID</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.renderDisks()}
          </tbody>
        </table>
      </Container>
    );
  }

  renderDisks() {
    return this.state.archive_disks.map((disk) => {
      return (
        <tr key={disk.id} className="archive_disks__item">
          <td className="archive_disks__id">
            {disk.id}
          </td>
          <td className="archive_disks__location">
            <a
              href="#"
              onClick={this.showDcps}
              data-id={disk.id}
            >{disk.name}</a>
          </td>
          <td className="archive_disks__disk-type">
            {disk.uuid}
          </td>
          <td className="archive_disks__actions">
            <a
              href={'/archive_disks/' + disk.id}
              className="archive_disks__edit btn btn-sm btn-primary"
              title="Edit Disk"
            >
              <span className="fa fa-pencil"></span>
            </a>

            &nbsp;

            <button
              className="archive_disks__delete btn btn-sm btn-danger"
              title="Delete Disk"
              onClick={() => this.delete(disk.id)}
            >
              <span className="fa fa-trash"></span>
            </button>
          </td>
        </tr>
      );
    });
  }

  hide() {
    Query.set('archive_disk_id', undefined);
    this.setState({archive_disk_id: undefined, playlists: []});
  }

  renderModal() {
    let content = 'loading...';
    if(this.state.playlists && this.state.playlists.length > 0) {
      content = (
        <div>
          <p>
            Playlists on disk "{this.state.archive_disk.name}":
          </p>

          <ul>
            {this.state.playlists.map((playlist) => {
              return (
                <li>{playlist.filename}</li>
              );
            })}
          </ul>
        </div>
      );
    }

    return (
      <Modal
        appElement={document.getElementById('app')}
         isOpen={!!this.state.archive_disk_id}
         style={{
           overlay: {
             backgroundColor: 'rgba(0, 0, 0, 0.75)',
           },
         }}
         contentLabel="Dcp On Disk"
         onRequestClose={this.hide}
      >
        <button
          className="close"
          onClick={this.hide}
        >
          <span aria-hidden="true">×</span>
        </button>

        {content}
      </Modal>
    );
  }
}
