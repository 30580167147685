import React from 'react';

import Container from 'components/Forms/List';

export default class List extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      targets: [],
    };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    this.props.api.get('/targets').then((response) => {
      this.setState({...response.data});
    });
  }

  delete(targetId) {
    if(!(this.props.confirm || confirm)('Are you sure?')) {
      return ;
    }

    this.props.api.delete('/targets/' + targetId).then(() => {
      this.setState({
        targets: this.state.targets.filter((target) => {
          return target.id != targetId;
        }),
      });
    });
  }

  render() {
    return (
      <Container>
        <div className="row">
          <div className="col-sm-6"><h1>Targets</h1></div>
          <div className="col-sm-6">
            <a href="/targets/create" className="btn btn-primary pull-right">
              Create
            </a>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Method</th>
              <th>Parameters</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTargets(this.state.targets)}
          </tbody>
        </table>
      </Container>
    );
  }

  renderTargets(targets) {
    return targets.map((target) => {
      return (
        <tr key={target.id} className="targets__item">
          <td className="targets__name">
            {target.name}
          </td>
          <td className="targets__transfer-method">
            {target.transfer_method}
          </td>
          <td className="targets__conversion-params">
            {target.conversion_params}
          </td>
          <td className="targets__actions">
            <a
              href={'/targets/' + target.id}
              className="targets__edit btn btn-sm btn-primary"
              title="Edit Target"
              data-state={JSON.stringify({target: target})}
            >
              <span className="fa fa-pencil"></span>
            </a>
            &nbsp;
            <button
              className="targets__delete btn btn-sm btn-danger"
              title="Delete Target"
              onClick={this.delete.bind(this, target.id)}
            >
              <span className="fa fa-trash"></span>
            </button>
          </td>
        </tr>
      );
    });
  }

}
