import React from 'react';
import Query from 'services/Query';

export default class SortLink extends React.Component {

  constructor(props) {
    super(props);
    this.sort = this.sort.bind(this);
  }

  sort() {
    this.props.sort(this.props.order, this.getNewDirection());
  }

  getDirection() {
    return this.props.direction || this.props.defaultDirection || 'asc';
  }

  getNewDirection() {
    let defaultDirection = this.props.defaultDirection || 'asc';

    if(this.props.current != this.props.order) {
      return defaultDirection;
    }

    if(this.getDirection() == 'asc') {
      return 'desc';
    }

    return 'asc';
  }

  render() {
    let arrow = null;

    if(this.props.order == this.props.current) {
      if(this.getDirection() == 'asc') {
        arrow = (<span>&darr;</span>);
      } else if(this.getDirection() == 'desc') {
        arrow = (<span>&uarr;</span>);
      }
    }

    return (
      <a
        href={'?' + Query.encode({...
          Query.get(),
          order: this.props.order,
          direction: this.getNewDirection(),
        })}
        onClick={this.sort}
        {...this.props.element}
      >
        {this.props.children}
        &nbsp;
        {arrow}
      </a>
    );
  }

}
