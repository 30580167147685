import React from 'react';

import App from 'components/App';
import Navigation from 'components/App/Navigation';
import Footer from './Footer';

import I18n from 'services/I18n';
import Form from 'services/Form';
import Query from 'services/Query';

export default class Login extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hideEmail: !!this.props.id || Query.get('email'),
      user: {email: Query.get('email'), remember: true},
      errors: {},
    };

    this.loggedIn = false;
    this.form = new Form(this);
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    if(this.props.id) {
      this.form.api.receive('/users/' + this.props.id + '/login');
    }

    if(this.props.currentUser && !this.loggedIn) {
      Query.redirect('/');
      App.instance.forceUpdate();
    }
  }

  login(event) {
    event.preventDefault();

    this.form.api.post('/session', this.state.user).then((response) => {
      this.loggedIn = true;
      this.props.api.setToken(response.data.token, this.state.user.remember);

      App.instance.setState({user: response.data.user}, () => {
        Query.redirect(
          Navigation.getFilmsUrl('/', response.data.user),
        );
      });
    });
  }

  render() {
    if(this.props.currentUser) {
      return null;
    }

    return (
      <div id="container" className="login">
        <section className="login__mask">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <div className="login__mask__logo">
                  <h1 className="display-5">filmservice.net</h1>
                  <p className="lead" dangerouslySetInnerHTML={{
                    __html: I18n.t('signin.lead'),
                  }}></p>
                </div>
              </div>

              <div className="col-xs-12 col-md-6">
                <form className="login__mask__form" onSubmit={this.login}>
                  <p className="lead">
                    {this.state.errors.fatal}
                  </p>

                  {this.state.hideEmail ? null : (
                    <fieldset className="form-group">
                      <label className="form-labels-mini">Email</label>

                      {this.form.fields.input('user.email', null, {
                        element: {
                          type: 'email',
                          autoFocus: true,
                          placeholder: 'Email',
                          className: 'form-control',
                        },
                      })}
                    </fieldset>
                  )}

                  <fieldset className="form-group">
                    <label className="form-labels-mini">Password</label>

                    {this.form.fields.input('user.password', null, {
                      element: {
                        type: 'password',
                        placeholder: 'Password',
                        className: 'form-control',
                      },
                    })}

                    <small
                      className="form-text"
                      dangerouslySetInnerHTML={{
                        __html: I18n.t('signin.restore'),
                      }}
                    ></small>
                  </fieldset>

                  <div className="form-group">
                    <label className="custom-control custom-checkbox">
                      {this.form.fields.checkbox('user.remember', null, {
                        className: 'custom-control custom-checkbox',
                        description: I18n.t('signin.remember'),
                        iconStyle: {
                          position: 'relative',
                          left: '-2px',
                        },
                      })}
                    </label>
                  </div>

                  <button type="submit" className="btn btn-primary">
                    {I18n.t('signin.login')}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>

        <Footer api={this.props.api}/>
      </div>
    );
  }

}
