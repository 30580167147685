import React from 'react';

import ScreenersList from 'components/Screeners/List';
import FilmsList from 'components/Films/List';

export default class Index extends React.Component {

  render() {
    if(!this.props.currentUser) {
      return null;
    }

    if(this.props.currentUser.role == 'journalist') {
      return <ScreenersList {...this.props} />;
    }

    return <FilmsList {...this.props} / > ;
  }

}
