import React from 'react';

import FormComponent from 'components/Forms/Form';

export default class Form extends FormComponent {

  static key = 'archive_disk';
  static url = '/archive_disks';

  renderBody() {
    return (
      <div>
        {this.form.fields.input('archive_disk.name', 'Name')}
        {this.form.fields.input('archive_disk.uuid', 'UUID')}
      </div>
    );
  }

}
