import React from 'react';

import Errors from './Errors';

export default class Select extends React.PureComponent {

  static values(props) {
    let values = props.values;

    if(!values && props.valuesArray) {
      values = props.valuesArray.map((value) => {
        return [value, value];
      });
    }

    if(!values && props.valuesObjects) {
      values = props.valuesObjects.map((value) => {
        return [value.id, value[props.titleField || 'name']];
      });
    }

    if(!values) {
      values = []
    }

    if(props.valueCallback) {
      values = values.map((value) => {
        return props.valueCallback(value);
      });
    }

    return values;
  }

  constructor() {
    super();
    this.change = this.change.bind(this);
  }

  change(event) {
    let value = null;
    if(event.target.value) {
      value = JSON.parse(event.target.value);
    }

    this.props.onChange(value);
  }

  render() {
    let values = this.constructor.values(this.props);

    let component = (
      <select
        className={
          'custom-select filterbar-select filterbar-select-light ' +
            (Errors.errors(this.props.errors).length > 0 && 'form-control-danger')
        }
        value={JSON.stringify(
          this.props.value !== undefined ? this.props.value : '',
        )}
        onChange={this.change}
        disabled={this.props.disabled}
        {...this.props.element}
      >
        {this.props.blank !== false && (
          <option key={-1} value={'null'}>
            {this.props.disabled ? '' : this.props.blank}
          </option>
        )}

        {this.props.disabled ? [] : values.map(([value, title], index) => {
          return (
            <option key={index} value={JSON.stringify(value)}>
              {title}
            </option>
          );
        })}
      </select>
    );

    if(this.props.noErrors) {
      return component;
    }

    return (
      <Errors errors={this.props.errors}>
        {component}
      </Errors>
    );
  }

}

// class Options extends React.Component {

//   render() {
//     return (

//     );
//   }

// }

