export default class Cache {

  static set(key, value) {
    localStorage.setItem(JSON.stringify(key), JSON.stringify(value));
  }

  static get(key, defaultValue = undefined) {
    let value = localStorage.getItem(JSON.stringify(key));
    if(!value) {
      return defaultValue;
    }

    try {
      value = JSON.parse(value);
    } catch(error) {
      // ignore parse error
      return defaultValue;
    }

    return value;
  }

}
