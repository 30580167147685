import React from 'react';

import App from 'components/App';

import FilmsList from '../Films/List';
import FilmListItem from '../Films/ListItem';

export default class List extends FilmsList {

  static url = '/screeners';

  componentDidMount() {
    App.setTitle('Screeners');
    super.componentDidMount();
  }

  renderFilter() {
    return (
      <section className="
        section-filterbar
        filterbar
        container-fluid
        screeners__filter
      ">
        <form>
          <div className="form-inline">
            {this.renderFilterDistributors()}
            {this.renderFilterGenres()}
            {this.renderFilterImages()}
          </div>
        </form>
      </section>
    );
  }

  renderList() {
    return (
      <div className="movielist-grid row">
        {this.state.films.map((film) => {
          return (
            <FilmListItem
              url={this.constructor.url}
              key={film.id}
              film={{...film, release_at: null}}
            />
          );
        })}
      </div>
    );
  }

}
