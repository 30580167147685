import React from 'react';
import moment from 'moment/moment';

import App from 'components/App';

import I18n from 'services/I18n';
import Form from 'services/Form';
import Query from 'services/Query';

import Header from '../Films/Show/Header';
import Play from './Play';

const compact = require('lodash/compact');

export default class List extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      film: this.props.film || {},
      screeners: [],
    };

    this.showPlayerDialog = this.showPlayerDialog.bind(this);
    this.form = new Form(this);
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      this.forceUpdate();
    })

    this.form.api.receive('/screeners/' + this.props.filmId).then(() => {
      App.instance.setState({footer: true});
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    if(!this.state.film.id) {
      return null;
    }

    return (
      <div>
        <Header
          film={this.state.film}
        />
        {this.renderScreeners()}
      </div>
    );
  }

  showPlayerDialog(screenerId, event) {
    if(event) {
      event.stopPropagation();
      event.preventDefault();
    }

    Query.set('play', screenerId);
  }

  renderPlayDialog(screener) {
    if(Query.get('play') != screener.id.toString()) {
      return ;
    }

    return (
      <Play api={this.props.api} screener={screener} />
    );
  }

  renderScreeners() {
    return (
      <div className="section-moviedetail-container">
        <br />
        <div className="row">
          <div className="col-md-12">
            {this.state.screeners.map((screener) => {
              let isTimeValid =
                moment(screener.start_at).isBefore(moment()) &&
                  moment(screener.end_at).isAfter(moment());

              return (
                <div
                  key={screener.id}
                  className="row-even"
                  style={{paddingLeft: '20px'}}
                >
                  {this.renderPlayDialog(screener)}

                  <a
                    href={isTimeValid ? '#' : undefined}
                    key="player"
                    onClick={
                      isTimeValid ?
                        this.showPlayerDialog.bind(
                          this,
                          screener.id,
                        ) :
                        undefined
                    }
                    className={'btn btn-sm small ' + (
                      isTimeValid ?
                        'btn-primary' :
                        'btn-secondary btn-disabled'
                    )}
                  >
                    PLAY
                    &nbsp;
                    {screener.playlist.spoken_language}
                    &nbsp;
                    {
                      compact([
                        screener.playlist.subtitle_language1,
                        screener.playlist.subtitle_language2,
                      ]).join('-').toLowerCase()
                    }
                  </a>

                  &nbsp;

                  <span className="gray screeners__show-available-text">
                    Available for you &nbsp;
                      {I18n.datetime(screener.start_at)}&nbsp;&mdash;&nbsp;
                      {I18n.datetime(screener.end_at)}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

}

