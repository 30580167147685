export default class Errors {

  static alertHandler(message) {
    return ((error) => {
      let data = error.response && error.response.data || {};
      if(data.error == 'Confirmation::Require') {
        throw error;
      }

      this.alert(message, error);
      throw error;
    });
  }

  static alert(message, error) {
    let text = `${message}; ` + this.getErrorText(error);
    alert(text)
  }

  static getErrorText(error) {
    if(!error.response) {
      return `error: ${error.toString()}`;
    }

    let prefix = 'try again or contact the support';
    let data = error.response.data;
    if(data) {
      if(data.error && data.error == 'PublicError') {
        return `${data.message}`;
      }

      if(data.message && data.error) {
        return `${prefix}; ${data.message} (${data.error})`;
      }

      return `${prefix}; ${JSON.stringify(data)}`;
    }

    if(error.response.body) {
      return `${prefix}; ${error.response.body}`;
    }

    return `${prefix}; ${error.response.status} ${error.response.statusText}`;
  }

}
