import React from 'react';

import Errors from 'components/Forms/Errors';
import FormComponent from 'components/Forms/Form';
import Row from 'components/Forms/Row';

import I18n from 'services/I18n';
import DateTimePicker from 'services/DateTimePicker';

export default class Form extends FormComponent {

  static key = 'screen';

  renderBody() {
    return (
      <div>
        {this.form.fields.input('screen.name', 'Name')}
        {this.form.fields.file('screen.certificate', 'Certificate')}

        {this.renderCertificates()}

        {this.form.fields.input('screen.server_make', 'Server Make')}
        {this.form.fields.input('screen.server_model', 'Server Model')}
        {this.form.fields.input('screen.server_serial', 'Server Serial')}

        {this.form.fields.input(
          'screen.server_software_version',
          'Server Software Version',
        )}

        {this.form.fields.input('screen.projector_make', 'Projector Make')}
        {this.form.fields.input('screen.projector_model', 'Projector Model')}
        {this.form.fields.input('screen.projector_serial', 'Projector Serial')}

        {this.form.fields.input(
          'screen.projector_software_version',
          'Projector Software Version',
        )}

        {this.form.fields.input('screen.kdm_modem_number', 'Kdm Modem Number')}
        {this.form.fields.input('screen.kdm_delivery', 'Kdm Delivery')}
        {this.form.fields.input('screen.type3d', 'Type3D')}
        {this.form.fields.checkbox('screen.backup35mm', 'Backup35mm')}

        {this.form.fields.input(
          'screen.screen_mask',
          'Adjustable Screen Mask',
        )}

        {this.form.fields.input(
          'screen.screen_aspect_ratio',
          'Screen Aspect Ratio',
        )}

        {this.form.fields.input('screen.integrator', 'Integrator')}

        {this.form.fields.input(
          'screen.installation_date',
          'Installation Date',
          {
            value: I18n.datetime(this.state.screen.installation_date),
            input: {
              ref: DateTimePicker.binder(
                this.form.setter('screen.installation_date'),
                {format: 'DD.MM.YYYY HH:mm'},
              ),
            },
          },
        )}

        {this.form.fields.input('screen.code_relation_to', 'Code Relation To')}
        {this.form.fields.checkbox('screen.enabled', 'Enabled')}
        {this.form.fields.checkbox('screen.dkdm', 'DKDM')}
      </div>
    );
  }

  deleteCertificate(index) {
    this.form.remove(`screen.certificates.${index}`);
  }

  createCertificate() {
    let input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;

    input.addEventListener('change', (event) => {
      for(let file of event.target.files) {
        let reader  = new FileReader();

        reader.addEventListener(
          'load',
          () => {
            this.form.append('screen.certificates', {
              attachment: reader.result,
              attachment_filename: file.name,
            });
          },
          false,
        );

        reader.readAsDataURL(file);
      }
    });

    input.click();
  }

  renderCertificates() {
    return (
      <Row title="Authorized Devices" className="screen__field-certificates">
        <Errors
          errors={this.state.errors.certificates}
          className="form-control-static form-control-light"
        >
          {
            this.state.screen.certificates &&
              this.state.screen.certificates.length > 0 &&
              this.state.screen.certificates.map((certificate, index) => {
                let child = null;

                if(certificate.attachment.url) {
                  child = (
                    <a href={certificate.attachment.url}>
                      {certificate.attachment.url.split('/').pop()}
                    </a>
                  );
                }

                if(certificate.attachment_filename) {
                  child = certificate.attachment_filename;
                }

                return (
                  <div key={index}>
                    {child}
                    &nbsp;
                    <sup>

                      <a href="#" onClick={() => {
                        this.deleteCertificate(index);
                      }}>x</a>
                    </sup>
                  </div>
                );
              })
          }

          <div>
            <button
              type="button"
              className='btn btn-primary btn-sm btn-attach-file'
              onClick={this.createCertificate.bind(this)}
            >
              <span className="fa fa-upload"></span>
              &nbsp;
              Attach File
            </button>
          </div>
        </Errors>
      </Row>
    );
  }

}
