import React from 'react';

export default class List extends React.PureComponent {

  render() {
    return (
      <section className="section-organizations container-fluid">
        <div className="row">
          <div className="col-xs-12">
            <div className="table-stack">
              {this.props.children}
            </div>
          </div>
        </div>
      </section>
    );
  }

}