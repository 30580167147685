import React from 'react';
import createHistory from 'history/createBrowserHistory';

import Footer from 'components/App/Footer';
import Header from 'components/App/Header';
import Modal from 'components/App/Modal';
import Router from 'components/Router';

import Api from 'services/Api';
import I18n from 'services/I18n';
import Script from 'services/Script';

const $ = window.jQuery = require('jquery');
require('services/jquery.backstretch.js');

require('normalize.css/normalize.css');
require('styles/App.scss');

require('font-awesome/scss/font-awesome.scss');
require('./App/Ui');

require('parallax.js/parallax.js');
require('jQuery.dotdotdot/src/jquery.dotdotdot.js');
require('url-search-params-polyfill');
require('flickity/dist/flickity.pkgd');

export default class App extends React.Component {

  static history = createHistory();
  static instance = null;
  static postfix = 'filmservice.net';

  static setTitle(title) {
    if(!title) {
      document.title = App.postfix;
      return ;
    }

    document.title = title + ' - ' + App.postfix;
  }

  constructor() {
    super();
    this.state = {
      ready: false,
      user: null,
      error: null,
      message: null,
    };

    this.started = false;
    this.socketio = null;

    this.api = new Api();
    this.click = this.click.bind(this);
    I18n.setApp(this);

    App.instance = this;
  }

  componentDidMount() {
    this
      .api
      .start()
      .then((response) => {
        this.started = true;
        this.setState({
          ready: this.started && !!this.socketio,
          user: response.data.user,
        });

        this.startZopim(response.data.user);
      })
      .catch((error) => {
        if(error.response && error.response.status === 403) {
          this.started = true;
          this.setState({ready: this.started && !!this.socketio});

          if(!window.location.pathname.match(/\/users(\/\d+)?\/sign_in/)) {
            let url = '/users/sign_in';
            if(window.location.pathname != '/') {
              url += '?redirect=' + encodeURIComponent(
                window.location.pathname + window.location.search,
              );
            }

            App.history.push(url);
          }

          return ;
        }

        throw error;
      });

    Script.load('/static/js/socketio.js').then(() => {
      this.socketio = window.io.connect(
        '//'+window.location.hostname,
        {resource: 'area51/socket.io'},
      );

      this.setState({ready: this.started && !!this.socketio});
    });
  }

  startZopim(user) {
    if(window.$zopim) {
      window.$zopim(function () {
        window.$zopim.livechat.setName(user.name);
        window.$zopim.livechat.setEmail(user.email);
      });
    }

    window._mfq = window._mfq || [];

    window._mfq.push(['setVariable', 'name', user.name]);
    window._mfq.push(['setVariable', 'email', user.email]);

    window._mfq.push([
      'setVariable',
      'primary_organization_id',
      user.primary_organization_id,
    ]);

    window._mfq.push(['setVariable', 'user_id', user.id]);

    user.organizations.forEach((organization) => {
      window._mfq.push([
        'setVariable',
        'organization_' + organization.id,
        organization.id,
      ]);
    });
  }

  click(event) {
    let link = event.target;
    while(link && link.nodeName !== 'A') {
      link = link.parentNode
    }

    let ignore = (
      !link ||
        !link.href ||
        link.href == window.location.href ||
        link.href == window.location.href + '#' ||
        link.rel.includes('nohistory') ||
        link.pathname.match(/^\/data\//) ||
        link.pathname.match(/\.\w+$/) ||
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    )

    if(ignore) {
      return ;
    }

    event.preventDefault();
    App.history.push(link.pathname + link.search, link.dataset.state);
  }

  setFooterVisibility(visible) {
    this.setState({showFooter: visible});
  }

  scrollTop() {
    $('.content').animate({scrollTop : 0}, 800);
  }

  bindScroll(element) {
    if(!element) {
      return ;
    }

    $(element).scroll(function() {
      if($(this).scrollTop() > 100) {
        $('.scrollToTop').fadeIn();
      } else {
        $('.scrollToTop').fadeOut();
      }
    });
  }

  render() {
    if(!this.state.ready) {
      return <div />;
    }

    let router = (
      <Router
        api={this.api}
        currentUser={this.state.user}
        history={App.history}
        key={I18n.getLocale()}
        location={App.history.location.pathname}
      />
    );

    if(!this.state.user) {
      return this.renderSignIn(router);
    }

    document.body.className = 'site';

    let content = document.getElementsByClassName('content')[0]
    if(content) {
      content.style.background = '';
    }

    let backstretch = document.getElementsByClassName('backstretch')[0];
    if(backstretch) {
      backstretch.parentNode.removeChild(backstretch);
    }

    return (
      <main
        className="content"
        onClick={this.click}
        ref={this.bindScroll}
      >
        <Header
          api={this.api}
          user={this.state.user}
          history={App.history}
        />

        {this.state.message && (
          <div className="app__message-container">
            {this.state.message}
          </div>
        )}

        {router}

        <a href="#" className="scrollToTop" onClick={this.scrollTop}>
          <i className="icon fa fa-chevron-up fw"></i>
        </a>

        <div id="preview_video"></div>
        <Modal appElement={document.getElementById('app')} />

        {this.state.footer && (
          <Footer />
        )}
      </main>
    );
  }

  renderSignIn(router) {
    document.body.className = 'light body body--login';

    return (
      <main
        className="content content-full content--login"
        tabIndex="0"
        onClick={this.click}
        ref={(element) => {
          window.jQuery(element).backstretch(
            [
              '/files/2016-03-30-12-16-25-(A,Radius50,Smoothing10).jpg',
              '/files/_DSC2123.jpg',
              '/files/_DSC1861.jpg',
              '/files/_DSC2159.jpg',
              '/files/_DSC2245.jpg',
              '/files/_DSC2301.jpg',
              '/files/_DSC2078.jpg',
              '/files/_DSC2214.jpg',
              '/files/_DSC1692.jpg',
              '/files/_DSC1675.jpg',
            ],
            {
              duration: 4000,
              fade: 2000,
            },
          );
        }}
      >
        {router}
      </main>
    );
  }

}
