import React from 'react';

import I18n from 'services/I18n';

export default class Playlists {

  static group(playlists, attribute) {
    let group = {};

    playlists.forEach((playlist) => {
      let key = playlist[attribute];
      if(!group[key]) {
        group[key] = [];
      }

      group[key].push(playlist);
    });

    let result = Object.keys(group).map((key) => {
      return [key, group[key]];
    });

    return result;
  }

  static renderTitle(kind, playlist) {
    let title = playlist.title || playlist.filename || '(link)';
    if(kind == 'webfoyer' && playlist.foyer) {
      title = playlist.foyer.filename.split('/').pop();
    }

    let disks = '';
    if(playlist.archive_disks && playlist.archive_disks.length > 0) {
      disks =
        playlist
          .archive_disks
          .map((disk) => {
            return disk.name;
          })
          .join(', ');
    }

    if (playlist.source && playlist.source !== 'filmservice') {
      if (playlist.source == "movietransit") {
        return(
          <span title={playlist.filename}>
            {title} MT
          </span>
        )
      }

      if (playlist.source == "both") {
        return (
          <span title={playlist.filename}>
            {title} FS MT
          </span>
        )
      }
    } else {
      if (!playlist.on_dalco) {
        if (disks) {
          disks = ` (${disks})`;
        }

        if (playlist.on_glacier || disks !== '') {
          return (
            <span>
              <i title={this.appendFilename(
                playlist,
                I18n.t('films.archived') + disks,
              )}>
                {title} FS
              </i>
            </span>
          );
        }

        return (
          <span>
            <strike title={this.appendFilename(
              playlist,
              I18n.t('films.offline') + disks,
            )}>
              {title} FS
            </strike>
          </span>
        );
      }
    }

    return (
      <span title={playlist.filename}>
        {title} FS
      </span>
    );
  }

  static appendFilename(playlist, alt) {
    if(!playlist.filename) {
      return alt;
    }

    return alt + '\n' + playlist.filename;
  }

}
