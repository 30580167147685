import React from 'react';

import Errors from './Errors';

export default class Input extends React.PureComponent {

  constructor() {
    super();
    this.change = this.change.bind(this);
  }

  change(event) {
    if(!this.props.onChange) {
      return ;
    }

    this.props.onChange(event.target.value, event);
  }

  render() {
    let value = undefined;
    if(this.props.value !== undefined && this.props.value !== null) {
      value = this.props.value;
    } else if(!(this.props.input || {}).hasOwnProperty('defaultValue')) {
      value = '';
    }

    return (
      <Errors errors={this.props.errors}>
        <input
          type={this.props.type || 'text'}
          className={
            'form-control form-control-light ' +
              (Errors.errors(this.props.errors).length > 0 &&
                'form-control-danger')
          }
          value={value}
          disabled={this.props.disabled}
          onChange={this.change}
          {...this.props.input}
          {...this.props.element}
        />
      </Errors>
    );
  }

}
