import FilmsPlay from '../Films/Show/Play';

export default class Play extends FilmsPlay {

  url() {
    let result =
      '/api/v2/screeners/' +
        this.props.screener.id +
        '/preview' +
        '?X-User-Token=' +
        this.props.api.getToken();

    return result;
  }

}
