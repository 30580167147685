import React from 'react';

import Select from './Select';
import Errors from './Errors';

export default class Checkboxes extends React.PureComponent {

  constructor() {
    super();
    this.change = this.change.bind(this);
  }

  change(event) {
    let currentValue = JSON.parse(event.target.value) || [];
    let resultValue = [currentValue];

    if(this.props.value) {
      resultValue = this.props.value.concat([currentValue]);
    }

    if(!event.target.checked) {
      resultValue = this.props.value.filter((innerValue) => {
        return innerValue != currentValue;
      });
    }

    this.props.onChange([...new Set(resultValue)]);
  }

  render() {
    let values = Select.values(this.props);

    return (
      <Errors className="form__checkboxes" errors={this.props.errors}>
        {values.map(([value, title]) => {
          return (
            <label key={value} className="form__checkboxes-label">
              <input
                type="checkbox"
                value={JSON.stringify(value)}
                checked={(this.props.value || []).includes(value)}
                disabled={this.props.disabled}
                onChange={this.change}
                {...this.props.element}
              /> {title}
            </label>
          )
        })}
      </Errors>
    );
  }

}
