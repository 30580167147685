import React from 'react';

import CreateComponent from 'components/Forms/Create';
import Container from 'components/Forms/Container';

import Form from './Form';

export default class Create extends CreateComponent {

  static key = 'playlist';
  static url = '/playlists';

  constructor(props) {
    super(props);

    this.redirect = this.redirect.bind(this);
  }

  redirect() {
    this.props.history.push('/films/' + this.state.playlist.film_id + '/edit');
  }

  load() {
    this
      .form
      .api
      .receive(this.constructor.url + '/new', {film_id: this.props.filmId})
      .then((response) => {
        let filename = null;

        if(
          response.data.playlists.length > 0 &&
            response.data.playlists[0].filename
        ) {
          filename = response.data.playlists[0].filename.split('_')[0] + '_';
        }

        this.setState({
          original_filename: filename,
          playlist: {
            ...this.state.playlist,
            cinema: true,
            film_id: this.props.filmId,
            filename: filename,
          },
        });
      });
  }

  render() {
    return (
      <Container title="Create Playlist">
        <Form
          {...this.state}
          {...this.props}
          save={this.save}
        />
      </Container>
    );
  }

}
