import React from 'react';
import moment from 'moment/moment';

export default class PlaylistsHeader extends React.Component {

  constructor() {
    super();
    this.toggleGroup = this.toggleGroup.bind(this);
  }

  toggleGroup(event) {
    let target = event.target;
    if(target.nodeName != 'A') {
      target = target.parentNode;
    }

    let groupId
    if (/[0-9a-f\-]/.test(target.dataset.group)) {
      groupId = target.dataset.group
    } else {
      groupId = parseInt(target.dataset.group)
    }

    let result = [...this.props.groups];
    let index = result.indexOf(groupId);

    if(index !== -1) {
      result.splice(index, 1);
    }

    if(target.classList.contains('collapsed')) {
      result.push(parseInt(groupId));
    }

    this.props.setGroups(result);
  }

  render() {
    let playlists = this.props.playlists;

    return (
      <tbody
        key={'header-' + playlists[0].id}
        className="table-body--asset-heading"
      >
        <tr>
          <td className="table-cell--collapse">
            <a
              href="#"
              className={
                'toggle-playlist ' +
                  (this.props.hidden && 'collapsed')
              }
              onClick={this.toggleGroup}
              data-group={this.props.group}
            >
              <i className="icon fa fa-chevron-right fa-fw"></i>
            </a>
          </td>

          <td colSpan="12">
            <h4 className="assets-heading">
              {this.props.title || '[no title]'}
            </h4>

            {playlists.updatedAt && (
              <span className="films__show-last-update text-xs-right">
                Updated {moment(playlists.updatedAt).format('D.M.YYYY')}
              </span>
            )}
          </td>
        </tr>
      </tbody>
    );
  }
}
