import React from 'react';

import Errors from './Errors';

export default class Textarea extends React.PureComponent {

  constructor() {
    super();
    this.change = this.change.bind(this);
  }

  change(event) {
    this.props.onChange(event.target.value);
  }

  render() {
    return (
      <Errors errors={this.props.errors}>
        <textarea
          className={
            'form-control form-control-light ' +
              (Errors.errors(this.props.errors).length > 0 && 'form-control-danger')
          }
          value={
            this.props.value !== undefined && this.props.value !== null ?
              this.props.value :
              ''
          }
          disabled={this.props.disabled}
          onChange={this.change}
          {...this.props.element}
        />
      </Errors>
    );
  }

}
