import React from 'react';

import Errors from './Errors';
import Spinner from '../Spinner';

export default class Checkbox extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.change = this.change.bind(this);
  }

  async change(event) {
    this.setState({ loading: true });

    if('trueValue' in this.props && event.target.checked) {
      await this.props.onChange(this.props.trueValue);
    } else if ('falseValue' in this.props && !event.target.checked) {
      await this.props.onChange(this.props.falseValue);
    } else {
      await this.props.onChange(event.target.checked);
    }

    this.setState({ loading: false });
  }

  render() {
    let isChecked = false;

    if(this.props.checked !== undefined) {
      isChecked = this.props.checked;
    } else if(this.props.value) {
      isChecked = this.props.value === (this.props.trueValue || true);
    }

    return (
      <div className='p-relative'>
        <Errors errors={this.props.errors}>
          <Spinner visible={ this.state.loading } className='custom-checkbox__spinner' />

          <label className={
            this.props.className || (
              'custom-control custom-checkbox custom-checkbox-compact ' +
                (!this.props.dark && ' custom-checkbox-light')
            )
          }>
            <input
              type="checkbox"
              className="custom-control-input"
              checked={isChecked}
              disabled={this.props.disabled}
              onChange={this.change}
            />

            {
              this.props.label &&
                <span style={{
                  left: '8px',
                  top: '8px',
                  fontSize: '16px',
                  position: 'relative',
                }}>{this.props.label}</span>
            }

            <span className="custom-control-indicator">
              <i
                className="icon fa fa-check fa-fw"
                style={this.props.iconStyle}
              ></i>
            </span>

            {this.props.description && (
              <span className="custom-control-description">
                {this.props.description}
              </span>
            )}
          </label>
        </Errors>
      </div>
    );
  }

}
