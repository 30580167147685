import React from 'react';

import Form from 'services/Form';
import I18n from 'services/I18n';
import Query from 'services/Query';
import Cache from 'services/Cache';

import Row from 'components/Forms/Row';

const t = I18n.namespace('profile');

export default class Profile extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      profile: this.props.currentUser,
      errors: {},
    };

    this.form = new Form(this);
    this.changeCallbacks = {};
    this.save = this.save.bind(this);
    this.renewToken = this.renewToken.bind(this);
  }

  componentDidMount() {
    this.load();
  }

  load() {
    this.form.api.receive('/profile');
  }

  save(event) {
    event.preventDefault();

    let profile = this.state.profile;

    if(!profile.password) {
      delete profile.password;
    } else if(profile.password !== profile.confirmation) {
      this.setState({errors: {confirmation: ['confirmation is invalid']}});
      return Promise.reject('confirmation is invalid');
    }

    return this.form.api.post('/profile', profile).then((response) => {
      this.props.api.setToken(response.data.token, !!Cache.get('token'));
      Query.redirect('/');
    });
  }

  renewToken(event) {
    event.preventDefault();
    if(!confirm(t('confirm_renew_api_token'))) {
      return ;
    }

    this.form.api.post('/profile', {access_token: true}).then((response) => {
      this.form.set(
        'profile.access_token',
        response.data.profile.access_token,
      );
    });
  }

  render() {
    return (
      <section className="section">
        <form onSubmit={this.save} className="profile">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 section-title">
                <h1>{t('title')}</h1>
              </div>
            </div>

            <div className="jumbotron">
              {this.form.fields.input('profile.name', t('name'))}
              {this.form.fields.input('profile.email', t('email'))}

              {this.form.fields.input('profile.password', t('password'), {
                type: 'password',
              })}

              {this.form.fields.input(
                'profile.confirmation',
                t('confirmation'),
                {
                  type: 'password',
                },
              )}

              {this.state.profile.access_token && (
                <Row
                  title={t('api_token')}
                  className="profile__field-access-token"
                >
                  <div className="input-group">
                    <label className="
                      form-control
                      form-control-light
                    ">{this.state.profile.access_token}</label>
                    <span className="input-group-btn">
                      <a
                        href="#"
                        className="btn btn-default"
                        onClick={this.renewToken}
                        style={{margin: 0}}
                      >
                        {t('renew_api_token')}
                      </a>
                    </span>
                  </div>
                </Row>
              )}
            </div>
          </div>

          {this.renderOrganizations()}

          <div className="container">
            <div className="form-group row">
              <div className="col-xs-12">
                <button type="submit" className="btn btn-primary">
                  {t('profile.save')}
                </button>

                &nbsp;

                <a href="/" className="btn btn-danger">
                  {t('profile.cancel')}
                </a>
              </div>
            </div>
          </div>
        </form>
      </section>
    );

  }

  renderOrganizations() {
    if(!this.props.currentUser.access.profile_organizations) {
      return ;
    }

    let organizations = this.state.profile.organizations;
    if(!organizations) {
      organizations = [];
    }

    return organizations.map((organization, index) => {
      let ftp = {}
      let credentials = organization.ftp_pull_credentials;
      if(credentials && credentials[0]) {
        ftp = credentials[0];
      }

      return (
        <div key={organization.id} className="container">
          <div className="row profile__field-organization-name">
            <div className="col-xs-12 section-subtitle">
              <h2>{organization.name}</h2>
            </div>
          </div>

          <div className="jumbotron">
            {ftp.login && (
              <div>
                <Row
                  title={t('ftp_user')}
                  className="profile__field-organization-ftp-login"
                >
                  <p className="form-control form-control--text">
                    {ftp.login}
                  </p>
                </Row>

                <Row
                  title={t('ftp_password')}
                  className="profile__field-organization-ftp-password"
                >
                  <p className="form-control form-control--text">
                    {ftp.password}
                  </p>
                </Row>

                <Row
                  title={t('ftp_host_label')}
                  className="profile__field-organization-ftp-host"
                >
                  <p className="form-control form-control--text">
                    {t('ftp_host_value')}
                  </p>
                </Row>
              </div>
            )}

            {this.form.fields.input(
              `profile.organizations.${index}.address`,
              t('address'),
            )}

            {this.form.fields.input(
              `profile.organizations.${index}.postal_code`,
              t('postal_code'),
            )}

            {this.form.fields.input(
              `profile.organizations.${index}.place`,
              t('place'),
            )}

            {this.form.fields.input(
              `profile.organizations.${index}.phone`,
              t('phone'),
            )}
          </div>
        </div>
      );
    });
  }

}
