import React from 'react';

import Cache from 'services/Cache';
import I18n from 'services/I18n';

import Search from './Search';

export default class Navigation extends React.Component {

  static getDefaultDistributorId(user) {
    let distributorsRoles = [
      'distributor_supervisor',
      'distributor_booker',
      'distributor_homevideo_responsible',
      'marketing_agent',
    ];

    if(distributorsRoles.includes(user.role)) {
      return user.primary_organization_id;
    }

    return null;
  }

  static getDefaultQuery(user, query) {
    let result = {...query};

    delete result.distributor_id;
    delete result.kinds;

    let filmsQuery = Cache.get('/films.query');
    if(filmsQuery && filmsQuery.distributor_id !== undefined) {
      result.distributor_id = filmsQuery.distributor_id || undefined;
    } else if(!result.distributor_id) {
      let distributorId = this.getDefaultDistributorId(user);
      if(distributorId) {
        result.distributor_id = distributorId;
      }
    }

    if(filmsQuery && filmsQuery.kinds) {
      result.kinds = filmsQuery.kinds;
    }

    return result;
  }

  static getFilmsUrl(prefix, user) {
    return this.addDefaulQuery(
      user,
      prefix,
      Cache.get(prefix + '.query', {}),
    );
  }

  static addDefaulQuery(user, url, query) {
    let resultQuery = this.getDefaultQuery(user, query);
    let result = [];

    for (let key in resultQuery) {
      if(resultQuery[key] === undefined) {
        continue;
      }

      result.push(
        encodeURIComponent(key) + '=' + encodeURIComponent(resultQuery[key]),
      );
    }

    if(result.length > 0) {
      return url + '?' + result.join('&');
    }

    return url;
  }

  constructor(props) {
    super(props);

    this.unlisten = this.props.history.listen(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  getTransfersUrl() {
    return this.constructor.addDefaulQuery(
      this.props.user,
      '/transfers',
      Cache.get('/transfers.query', {}),
    );
  }

  render() {
    const t = I18n.namespace('navigation');
    let path = location.pathname.split('?')[0];

    return (
      <nav
        className="navbar navbar-full navbar-main"
        data-toggle="sticky-onscroll"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-3 col-sm-6 col-md-8 hidden-md-up">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#nav-main"
              >
                <i className="icon fa fa-bars fa-fw"></i>
              </button>
            </div>

            <div
              id="nav-main"
              className="
                nav-main
                col-xs-12
                col-md-8
                collapse
                navbar-toggleable-sm
              "
            >
              <ul className="nav navbar-nav">
                {
                  this.props.user.access.films &&
                    <li className={
                      'nav-item ' + (
                        (path == '/' || path.startsWith('/films')) &&
                        'active'
                      )
                    }>
                      <a
                        href={this.constructor.getFilmsUrl(
                          '/films',
                          this.props.user,
                        )}
                        className="nav-link"
                      >{t('films')}</a>
                    </li>
                }

                {
                  this.props.user.access.screeners &&
                    <li className={
                      'nav-item ' +
                        (
                          (path.startsWith('/screeners') || (
                            this.props.user.role == 'journalist' &&
                            path == '/'
                          )) && 'active'
                        )
                    }>
                      <a
                        href={this.constructor.getFilmsUrl(
                          '/screeners',
                          this.props.user,
                        )}
                        className="nav-link"
                      >{t('screeners')}</a>
                    </li>
                }

                {
                  this.props.user.access.map &&
                    <li className={
                      'nav-item ' +
                        (path.startsWith('/organizations/map') && 'active')
                    }>
                      <a
                        href="/organizations/map"
                        className="nav-link"
                      >{t('map')}</a>
                    </li>
                }

                {
                  this.props.user.access.transfers &&
                    <li className={
                      'nav-item nav-item-transfers ' +
                        (path.startsWith('/transfers') && 'active')
                    }>
                      <a
                        href={this.getTransfersUrl()}
                        className="nav-link"
                      >{t('transfers')}</a>
                    </li>
                }

                {
                  this.props.user.access.add_film && (
                    <li className="nav-item pull-xs-right">
                      <a href="/films/create" className="nav-link">
                        <i className="icon fa fa-plus fa-fw"></i>
                      </a>
                    </li>
                  )
                }
              </ul>
            </div>

            <Search
              api={this.props.api}
              history={this.props.history}
              user={this.props.user}
            />
          </div>
        </div>
      </nav>
    );
  }

}
