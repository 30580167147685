import React from 'react';

import FormService from 'services/Form';
import Query from 'services/Query';

import Container from 'components/Forms/Container';

export default class Create extends React.Component {

  constructor(props) {
    super(props);

    this.form = new FormService(this);
    this.save = this.save.bind(this);

    if(!this.redirect) {
      this.redirect =
        Query.getRedirectCallback(this.constructor.url).bind(this);
    }

    this.state = {
      errors: {},
      [this.constructor.key]: {},
    };
  }

  componentDidMount() {
    this.load();
  }

  load() {
    this.form.api.receive(this.constructor.url + '/new');
  }

  save(data) {
    this.setState({[this.constructor.key]: data});
    let url = this.constructor.url;
    let promise = this.form.api.post(url, data).then(this.redirect);
    return promise;
  }

  render() {
    return (
      <Container title={'Create ' + this.constructor.key.replace(/_/g, ' ')}>
        {this.renderBody()}
      </Container>
    );
  }

  renderBody() {
    throw new Error('not implemented');
  }

}
