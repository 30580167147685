export default class Modal {

  static STYLE = {
     overlay: {
       backgroundColor: 'rgba(0, 0, 0, 0.75)',
     },
     content: {
       top: '5%',
       left: '50%',
       right: 'auto',
       bottom: 'auto',
       marginRight: '-50%',
       transform: 'translate(-50%, 0%)',
       maxWidth: '600px',
       width: '100%',
       overflow: 'auto',
       maxHeight: '90%',
     },
   }

}
