import 'core-js/fn/object/assign';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

import bugsnag from 'bugsnag-js'
import createBugsnagPlugin from 'bugsnag-react'

let app = <App />;

if(process.env.NODE_ENV != 'development') {
  const bugsnagClient = bugsnag('07d89187b071934f73392bef7f5396f3');
  const BugsnagErrorsContainer = bugsnagClient.use(createBugsnagPlugin(React));

  app = (
    <BugsnagErrorsContainer>
      <App />
    </BugsnagErrorsContainer>
  );
}

ReactDOM.render(app, document.getElementById('app'));
