import React from 'react';
import I18n from 'services/I18n';

class Languages extends React.Component {

  constructor(props) {
    super(props)
    this.setLocale = this.setLocale.bind(this);
  }

  setLocale(event) {
    I18n.setLocale(event.target.dataset.locale);
  }

  render() {
    return (
      <div className="dropdown">
        <button
          className="btn btn-dark dropdown-toggle"
          type="button"
          data-toggle="dropdown"
        >
          <i className="icon fa fa-globe fa-fw hidden-xs-down"></i>
          <span>{
            I18n.getLocale() == 'en' ?
              'All' :
              I18n.getLocale().toUpperCase().substr(0, 1) + '-CH'
          }</span>
        </button>

        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuButton"
        >

          {I18n.getLocale() != 'de' && (
            <a
              href="#"
              className="dropdown-item"
              data-locale="de"
              onClick={this.setLocale}
            >D-CH</a>
          )}

          {I18n.getLocale() != 'fr' && (
            <a
              href="#"
              className="dropdown-item"
              data-locale="fr"
              onClick={this.setLocale}
            >F-CH</a>
          )}

          {I18n.getLocale() != 'it' && (
            <a
              href="#"
              className="dropdown-item"
              data-locale="it"
              onClick={this.setLocale}
            >I-CH</a>
          )}

          {I18n.getLocale() != 'en' && (
            <a
              href="#"
              className="dropdown-item"
              data-locale="en"
              onClick={this.setLocale}
            >All</a>
          )}

        </div>
      </div>
    );
  }

}

export default Languages;
