import App from 'components/App';
import queryString from 'query-string';

export default class Query {

  static history = null;

  static encode(object) {
    return queryString.stringify(object)
  }

  static decode(search) {
    return queryString.parse(search);
  }

  static get(key = undefined) {
    if(key !== undefined) {
      return this.get()[key];
    }

    return this.decode(document.location.search);
  }

  static set(params, value = undefined) {
    if(typeof params === 'string') {
      return this.set({...this.get(), [params]: value});
    }

    let url =
      (new URL(document.location)).pathname + '?' + Query.encode(params);

    App.history.push(url);
  }

  static getEncodedUrl() {
    return encodeURIComponent(
      window.location.pathname + window.location.search,
    );
  }

  static redirect(defaultUrl) {
    App.history.push(this.getRedirectUrl(defaultUrl));
  }

  static go(url, query) {
    if(query) {
      url += '?' + this.encode(query);
    }

    App.history.push(url);
  }

  static getRedirectUrl(defaultUrl) {
    let redirect = this.get('redirect') || this.get('redirect_to');
    if(redirect) {
      return redirect.replace(/^https?:\/\/[^\/]+/, '');
    }

    return defaultUrl;
  }

  static getRedirectCallback(defaultUrl) {
    return this.redirect.bind(this, defaultUrl);
  }

}
