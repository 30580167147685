export default class Script  {

  static load(src) {
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;

      script.onload = () => {
        resolve();
      }

      script.onerror = (error) => {
        reject(error);
      }

      document.getElementsByTagName('head')[0].appendChild(script);
    });

  }

}
