import React from 'react';

import I18n from 'services/I18n';
import Errors from 'services/Errors';

import Playlist from './Playlist';
import PlaylistsHeader from './PlaylistsHeader';
import PlaylistTitle from './PlaylistTitle';
import Targets from './Targets';
import Utilities from '../Utilities';

export default class Vod extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      playlistsTargets: {},
      conversions: {},
      groups: Utilities.getVisibleIds(this.props.groups),
      playlists: [],
     };

    this.convert = this.convert.bind(this);
    this.setState = this.setState.bind(this);
    this.setGroups = this.setGroups.bind(this);
    this.setPlaylists = this.setPlaylists.bind(this);
    this.restartConversionEngine = this.restartConversionEngine.bind(this);
  }

  convert() {
    let url = '/conversions/create_by_playlists';
    let data = {
      playlists_targets: this.state.playlistsTargets,
    };

    this.setState({loading: true});

    this
      .props
      .api
      .post(url, data)
      .then(() => {
        //  reload conversions
        this.setState({
          conversions: null,
          loading: false,
          playlistsTargets: {},
        });
      })
      .catch((error) => {
        this.setState({loading: false});
        Errors.alert('failed to create conversions', error);
      });
  }

  restartConversionEngine() {
    let isConfirmed = confirm(
      'Are you sure? All current conversions will be cancelled and ' +
        'conversion progress will be lost.',
    );

    if(!isConfirmed) {
      return ;
    }

    this.setState({loading: true});

    this
      .props
      .api
      .post('/conversions/restart')
      .then(() => {
        this.setState({loading: false});
        alert('Conversion engine is restarted');
      })
      .catch((error) => {
        this.setState({loading: false});
        Errors.alert('Failed to restart conversion engine', error);
      });
  }

  render() {
    return (
      <table className="table">
        {this.renderContent()}
        <tbody key="actions">
          <tr>
            <td colSpan="12">
              {this.props.user_can_convert && (
                <button
                  onClick={this.convert}
                  className="btn btn-success"
                  disabled={this.state.loading}
                  type="button"
                >{I18n.t('film.order_conversion')}</button>
              )}

              {this.props.currentUser.role == 'admin' && (
                <span>
                  &nbsp;

                  <button
                    className="btn btn-danger"
                    onClick={this.restartConversionEngine}
                    disabled={this.state.loading}
                  >Restart Conversion Engine</button>
                </span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  setGroups(value) {
    this.setState({groups: value});
  }

  renderContent() {
    return Object.keys(this.props.groups).map((title) => {
      let playlists = this.props.groups[title];
      let lowestId = Utilities.getLowestId(playlists);
      let groups = this.state.groups;
      let isHidden = Utilities.isGroupHidden(groups, playlists, lowestId);

      let result = [
        <PlaylistsHeader
          key={'header-' + lowestId}
          group={lowestId}
          groups={groups}
          setGroups={this.setGroups}
          hidden={isHidden}
          kind="vod"
          playlists={playlists}
          title={title}
        />,
      ];

      if(!isHidden) {
        playlists.map((playlist) => {
          result.push(this.renderPlaylist(playlist));
          if(this.props.user_can_convert) {
            result.push(this.renderTargets(playlist));
          }
        });
      }

      return result;
    });
  }

  setPlaylists(playlists) {
    this.setState({playlists: playlists});
  }

  renderPlaylist(playlist) {
    return (
      <tbody
        key={'content-' + playlist.id}
        className="table-body--asset"
      >
        <Playlist
          api={this.props.api}
          cinemas={this.props.cinemas}
          index="0"
          kind="vod"
          lowestPlaylistId={playlist.id.toString()}
          groups={this.state.groups}
          playlists={this.state.playlists}
          setPlaylists={this.setPlaylists}
          playlist={playlist}
          user_can_convert={this.props.user_can_convert}
        />
        <PlaylistTitle playlist={playlist} hideEncrypted={true} />
      </tbody>
    );
  }

  renderTargets(playlist) {
    let expanded = this.state.playlists.includes(playlist.id.toString());
    if(!expanded) {
      return ;
    }

    return (
      <Targets
        key={'targets-' + playlist.id}
        api={this.props.api}
        playlist={playlist}
        targets={this.props.targets}
        state={this.state}
        user_can_convert={this.props.user_can_convert}
        user_can_delete_conversion={this.props.user_can_delete_conversion}
        setState={this.setState}
      />
    );
  }

}
